'use strict';

angular.module('study.import')
.component('importLoadCSVStart', {
    templateUrl: 'modules/import/components/load-csv/start.html',
    controller: 'ImportLoadCSVStartController',
    bindings: {
        importMode: '@',
    },
});
