angular.module('sharecrf.utils').directive('disablePaste', function() {
    return {
        link: function(scope, element) {
            element.on('paste', function(event) {
                // console.info('Paste!', event.clipboardData.getData('text'));
                event.preventDefault();
            });
        },
    };
});
