/* eslint-disable no-unused-vars */
angular.module('app.entity.menu.plugin-pending-changes', [
    'app.entity.service',
]).factory('EntityMenuPluginPendingChanges', [function() {
    function set(metadataItem) {
        var changed = !metadataItem.modified;
        metadataItem.modified = true;

        return changed;
    }
    function reset(metadataItem) {
        var changed = !!metadataItem.modified;
        metadataItem.modified = false;

        return changed;
    }

    function update(metadataItem) {
        var currentValue = metadataItem.modified;
        var newValue = metadataItem.children.some(function(child) {
            return !!child.modified;
        });

        metadataItem.modified = newValue;

        return currentValue !== newValue;
    }

    return {
        initFormFlags: function(itemMetadata) {
            return reset(itemMetadata);
        },
        initSectionFlags: function(itemMetadata) {
            return reset(itemMetadata);
        },
        initListFlags: function(itemMetadata) {
            return reset(itemMetadata);
        },
        initListItemFlags: function(itemMetadata) {
            return reset(itemMetadata);
        },

        reloadFlags: function(itemMetadata) {
            return reset(itemMetadata);
        },
        reloadItemFlags: function(itemMetadata) {
            return reset(itemMetadata);
        },

        onEntityFormDirtyFlagUpdated: function(listArguments) {
            var itemMetadata = listArguments[0];

            return set(itemMetadata);
        },
        // Actualización manual del valor de un campo
        onUpdatedRecordFieldValue: function(argumentsList) {
            var itemMetadata = argumentsList[0];

            return set(itemMetadata);
        },
        // Actualización automática del valor de un campo
        onSetValueChanged: function(argumentsList) {
            var itemMetadata = argumentsList[0];

            return set(itemMetadata);
        },

        // Eliminar un elemento de lista
        onListItemCreated: function(argumentsList) {
            var itemMetadata = argumentsList[0];

            return set(itemMetadata);
        },
        // Eliminar un elemento de lista
        onListItemDeleted: function(argumentsList) {
            var itemMetadata = argumentsList[0];

            return set(itemMetadata);
        },

        // Propagación en el padre
        propagate: function(itemMetadata) {
            return update(itemMetadata);
        },
    };
}]);
