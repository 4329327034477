'use strict';

angular.module('study.error')
    .config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('error', {
            component: 'errorPage',
            params: {
                type: {
                    type: 'string',
                    value: 'Error',
                },
                code: {
                    type: 'string',
                    value: 'generic',
                },
                info: {
                    type: 'any',
                    value: {},
                },
            },
            resolve: {
                type: ['$transition$', function($transition$) {
                    return $transition$.params().type;
                }],
                code: ['$transition$', function($transition$) {
                    return $transition$.params().code;
                }],
                info: ['$transition$', function($transition$) {
                    return $transition$.params().info;
                }],
            },
        });

        $stateProvider.state('not-found', {
            name: 'not-found',
            url: '*path',
            redirectTo: function(transition) {
                return {
                    state: 'error',
                    params: {
                        type: 'RouterError',
                        code: 'not-found',
                        info: transition.params(),
                    },
                };
            },
        });
    }]);
