angular.module('ecrd.notifications').factory('NotificationsService', [
    'ShareCrfSDK',
    'RecordService',
    function(ShareCrfSDK, RecordService) {
        return {
            getRecordNotifications: function(params) {
                params = params || {};

                params.recordId = RecordService.getId();

                return ShareCrfSDK.ecrd.getRecordNotifications(params);
            },
        };
    },
]);
