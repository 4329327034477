angular.module('app.entity.field').component('fieldEncrypted', {
    templateUrl: 'modules/ecrd/components/field/field-encrypted/content.html',
    controller: 'FieldEncryptedController',
    transclude: true,
    bindings: {
        fieldId: '@',
        fieldValue: '<',
        fieldLabel: '@',
    },
});
