angular.module('ecrd')
    .factory('RecordMetadataService', ['CoreRecordMetadata', function RecordMetadataService(CoreRecordMetadata) {
        var publicAPI = {};
        // 1. Custom functions

        // 2. Inherited public
        Object.getOwnPropertyNames(Object.getPrototypeOf(CoreRecordMetadata)).filter(function(key) {
            if (typeof CoreRecordMetadata[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                if (typeof publicAPI[key] != 'undefined') {
                    throw new Error("Function "+key+" is present in Core object");
                }
                publicAPI[key] = CoreRecordMetadata[key].bind(CoreRecordMetadata);
            }
        });

        publicAPI.events = CoreRecordMetadata.events;

        return publicAPI;
    }]);
