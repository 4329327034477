angular.module('study.dashboard')
    .component('inclusionRatioReportByDate', {
        templateUrl: 'modules/dashboard/components/inclusion-ratio-report-by-date/template.html',
        controller: [
            '$window',
            'gettextCatalog',
            'ShareCrfSDK',
            '$q',
            '$log',
            function($window, gettextCatalog, ShareCrfSDK, $q, $log) {
                var vm = this;
                var moment = $window.moment;
                var c3 = $window.c3;
                var d3 = $window.d3;
                var chartData = [];

                vm.loading = false;
                vm.error = false;

                // Configuración por defecto de los filtros
                vm.filters = {
                    interval: 'week',
                    startDate: moment().subtract(6, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                };

                // El objeto de gráfico generado
                var chart = null;

                vm.$onInit = function() {
                    createChart();
                    reloadChart();
                };

                vm.reload = function(form) {
                    if (form.$valid) {
                        reloadChart();
                    }
                };

                function createChart() {
                    var chartConfig = {
                        bindto: '#inclusion-ratio-by-date',
                        data: {
                            json: [],
                            type: 'bar',
                            types: {
                                accumulated: 'line',
                            },
                            xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                            names: {
                                records: gettextCatalog.getString('Registros'),
                                accumulated: gettextCatalog.getString('Acumulado'),
                            },
                            empty: {
                                label: {
                                    text: gettextCatalog.getString('No hay datos'),
                                },
                            },
                            axes: {
                                records: 'y',
                                accumulated: 'y2',
                            },
                        },
                        axis: {
                            x: { // eslint-disable-line id-length
                                type: 'category',
                            },
                            y: { // eslint-disable-line id-length
                                min: 0,
                                padding: {
                                    bottom: 0,
                                },
                                tick: {
                                    format: d3.format('d'),
                                },
                            },
                            y2: { // eslint-disable-line id-length
                                show: true,
                                min: 0,
                                padding: {
                                    bottom: 0,
                                },
                                tick: {
                                    format: d3.format('d'),
                                },
                            },
                        },
                    };

                    chart = c3.generate(chartConfig);
                }

                function reloadChart() {
                    if (!chart) {
                        return;
                    }

                    vm.loading = true;
                    vm.error = false;

                    return $q.resolve()
                        .then(function() {
                            return getData();
                        })
                        .then(function(response) {
                            chartData = fillGaps(response.data);

                            chart.load({
                                unload: true,
                                json: chartData,
                                keys: {
                                    x: vm.filters.interval, // eslint-disable-line id-length
                                    value: ['records', 'accumulated'],
                                },
                                colors: {
                                    records: '#455b73',
                                    accumulated: '#e67259',
                                },
                            });
                        })
                        .catch(function(error) {
                            $log.error(error);
                            vm.error = true;
                        })
                        .finally(function() {
                            vm.loading = false;
                        });
                }

                function getData() {
                    var parameters = {};
                    if (vm.filters.startDate) {
                        parameters.firstDate = vm.filters.startDate + 'T00:00:00';
                    }
                    if (vm.filters.endDate) {
                        parameters.lastDate = vm.filters.endDate + 'T23:59:59';
                    }
                    if (vm.filters.interval) {
                        parameters.interval = vm.filters.interval;
                    }

                    return ShareCrfSDK.dashboard.inclusionRateByDate(parameters);
                }

                function formatChartDate(value, interval) {
                    switch (interval) {
                        case 'day':
                            return moment(value).format('DD/MM/YY');
                        case 'week':
                            // W identifica a la semana ISO, y G al año ISO. Es decir, la semana que comienza el
                            // 31/12/2018 se representaría como 1-2019
                            return moment(value).format('W-GGGG');
                        case 'month':
                            return moment(value).format('MM-YY');
                        case 'year':
                            return moment(value).format('YYYY');
                    }

                    return value;
                }

                function getRowDate(row) {
                    var momentDate = moment().year(row.year);

                    if ('month' in row) {
                        momentDate.set('month', row.month - 1);
                    }

                    if ('week' in row) {
                        momentDate.set('isoWeek', row.week);
                    }

                    if ('day' in row) {
                        momentDate.set('date', row.day);
                    }

                    return momentDate.toDate();
                }

                function fillGaps(data) {
                    if (!data) {
                        return [];
                    }

                    var initialDate = vm.filters.startDate;
                    var finalDate = vm.filters.endDate;

                    var momentInterval = vm.filters.interval === 'week' ? 'isoWeek' : vm.filters.interval;
                    var momentFrom = moment(initialDate).startOf(momentInterval);
                    var momentTo = moment(finalDate).startOf(momentInterval);

                    var fullData = [];
                    var currentMoment = momentFrom;
                    var currentRow;
                    var accumulated = 0;


                    while (currentMoment <= momentTo) {
                        currentRow = null;

                        if (data.length > 0) {
                            var currentRowDate = getRowDate(data[0]);

                            if (formatChartDate(currentMoment.toDate(), vm.filters.interval) === formatChartDate(currentRowDate, vm.filters.interval)) {
                                currentRow = data.shift();
                                accumulated += currentRow.records;
                                currentRow.accumulated = accumulated;
                            }
                        }

                        if (currentRow === null) {
                            currentRow = {
                                records: 0,
                                accumulated: accumulated,
                            };
                        }

                        currentRow[vm.filters.interval] = formatChartDate(currentMoment.toDate(), vm.filters.interval);
                        fullData.push(currentRow);

                        currentMoment.add(1, vm.filters.interval + 's');
                    }

                    return fullData;
                }

                vm.downloading = false;
                vm.downloadExcel = function() {
                    if (!vm.error && !vm.loading) {
                        generateExcel(chartData);
                    }
                };

                function getIntervalFormattedText(interval) {
                    switch (interval) {
                        case 'year':
                            return gettextCatalog.getString('Año');
                        case 'month':
                            return gettextCatalog.getString('Mes');
                        case 'week':
                            return gettextCatalog.getString('Semana');
                        case 'day':
                            return gettextCatalog.getString('Día');

                        default: return interval;
                    }
                }

                function generateExcel(response) {
                    var title = gettextCatalog.getString('Inclusion por fechas');
                    var interval = vm.filters.interval;
                    var columns = [
                        {
                            id: 'interval',
                            header: getIntervalFormattedText(interval),
                            formatter: function(row) {
                                return row[interval];
                            },
                        },
                        {
                            id: 'records',
                            header: gettextCatalog.getString('Registros'),
                        },
                        {
                            id: 'accumulated',
                            header: gettextCatalog.getString('Acumulado'),
                        },
                    ];

                    var XLSX = $window.XLSX;
                    var workbook = XLSX.utils.book_new();

                    var columnWidth = [];
                    var headers = columns.map(function(column, index) {
                        columnWidth[index] = Math.max(columnWidth[index] || 0, column.header.length);

                        return column.header;
                    });

                    var worksheet = XLSX.utils.aoa_to_sheet([headers]);

                    angular.forEach(response, function(row) {
                        var data = [];
                        angular.forEach(columns, function(column, index) {
                            var value = getRowData(row, column.id);
                            if (angular.isFunction(column.formatter)) {
                                value = column.formatter(row);
                            }
                            data.push(value);
                            var length = value ? String(value).length : 1;
                            columnWidth[index] = Math.max(columnWidth[index] || 0, length);
                        });
                        XLSX.utils.sheet_add_aoa(worksheet, [data], { origin: -1 });
                    });

                    worksheet['!cols'] = columnWidth.map(function(width) {
                        return { wch: Math.min(width ? width : 0, 40) };
                    });

                    XLSX.utils.book_append_sheet(workbook, worksheet, title);
                    XLSX.writeFile(workbook, _slugify(title) + '.xlsx');
                }

                function _slugify(input) {
                    // make lower case and trim
                    var slug = input.toLowerCase().trim();
                    // replace invalid chars with spaces
                    slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
                    // replace multiple spaces or hyphens with a single hyphen
                    slug = slug.replace(/[\s-]+/g, '-');

                    return slug;
                }

                function getRowData(data, attribute) {
                    return angular.isArray(attribute)
                        ? attribute.map(function(attr) { // el valor de la celda se construye a partir de más de un dato
                            return $window._.get(data, attr);
                        })
                        : $window._.get(data, attribute);
                }
            },
        ],
    });