angular.module('app.entity.menu').provider('EntityMenuConfig', function() {
    this.structure = {};
    this.pluginNames = [
        'EntityMenuPluginCompletion',
        'EntityMenuPluginExpand',
        'EntityMenuPluginListItems',
        'EntityMenuPluginPendingChanges',
        'EntityMenuPluginValidation',
        'EntityMenuPluginVisibility',
    ];
    this.plugins = [];

    this.$get = ['$injector', function($injector) {
        for (var index in this.pluginNames) {
            this.plugins.push($injector.get(this.pluginNames[index]));
        }

        return this;
    }];
});
