!(function() {
    if (navigator && navigator.userAgent && navigator.userAgent.match(/android/i)) {
        var oldAndroid = false;
        var match;

        var chromeVersion, webkitVersion;

        match = navigator.userAgent.match(/chrome\/(\d+)/i);
        if (match) {
            chromeVersion = parseInt(match[1]);
        }

        match = navigator.userAgent.match(/AppleWebkit\/(\d+)/i);
        if (match) {
            webkitVersion = parseInt(match[1]);
        }

        oldAndroid = (chromeVersion && chromeVersion < 60) || (webkitVersion && webkitVersion < 537);
        if (oldAndroid) {
            document.body.className += 'old-android';
        }
    }
})();
