angular.module('app.study-context').service('StudyContext', [ // eslint-disable-line angular/no-service-method
    '$injector',
    'AccountManager',
    'RecordService',
    function(
        $injector,
        AccountManager,
        RecordService
    ) {
        var StudyContext = {};

        StudyContext.hasRecordScope = RecordService.hasScope;

        /**
         * Determina si se puede realizar la acción especificada sobre de este mismo registro
         *
         * @param  {string} action 'read', 'update', 'delete', 'store'
         *
         * @return {boolean}        TRUE si el usuario tiene permiso
         */
        StudyContext.canWithRecord = function(action) {
            return AccountManager.hasPermission(action + ' records');
        };

        /**
         * Determina si se puede realizar la acción especificada sobre las queries de este registro
         *
         * @param  {string} action 'read', 'create', 'reply', 'close'
         *
         * @return {boolean}        TRUE si el usuario tiene permiso
         */
        StudyContext.canWithQueries = function(action) {
            return AccountManager.hasPermission(action + ' records queries');
        };

        /**
         * Determina si se puede realizar la acción especificada sobre la aleatorización de este registro
         *
         * @param  {string} action 'read', 'randomize', 'unblind'
         *
         * @return {boolean}        TRUE si el usuario tiene permiso
         */
        StudyContext.canWithRandomization = function canWithRandomization(action) {
            var permissions = [];

            switch (action) {
                case 'read':
                    permissions = ['code', 'label', 'masked'].map(function(field) {
                        return 'read randomization ' + field;
                    });
                    break;

                case 'randomize':
                case 'unblind':
                case 'read unblind':
                    permissions = [action + ' records'];
                    break;

                default:
                    permissions = ['unknown action permission'];
            }

            return AccountManager.hasAtLeastOnePermission(permissions);
        };

        /**
         * Determina si se puede realizar la acción de metadata especificada sobre este registro
         *
         * @param  {String} action 'lock', 's1', 's2', 's3', 's4', 's5'
         *
         * @return {Boolean}        TRUE si el usuario tiene permiso
         */
        StudyContext.canWithRecordStatus = function(action) {
            return AccountManager.hasPermission(action + ' records');
        };

        /**
         * Determina si se puede realizar la acción de metadata especificada sobre de formularios de este registro
         *
         * @param  {string} action 'lock', 's1', 's2', 's3', 's4', 's5'
         *
         * @return {boolean}        TRUE si el usuario tiene permiso
         */
        StudyContext.canWithFormStatus = function(action) {
            return AccountManager.hasPermission(action + ' records forms');
        };

        /**
         * Can update entity
         *
         * @return {Boolean} TRUE if user has permission to update record
         */
        StudyContext.canUpdateEntity = function() {
            return StudyContext.canWithRecord('update');
        };

        /**
         * Is update available
         *
         * @return {Boolean} TRUE if user can update entity
         */
        StudyContext.isUpdateEntityAvailable = function() {
            var states = $injector.has('RecordStatesService')
                ? $injector.get('RecordStatesService').getAvailableStates()
                : {};

            return !StudyContext.isEntityLocked() && Object.keys(states).every(function(stateId) {
                return !$injector.get('RecordStatesService').isRecordInState(stateId) || states[stateId].allowEdition;
            });
        };

        /**
         * Can create entity
         *
         * @return {Boolean} TRUE if user can create entities
         */
        StudyContext.canCreateEntity = function() {
            return AccountManager.hasPermission('create records');
        };

        /**
         * Can access entity queries
         *
         * @return {Boolean} TRUE if user can read entity queries
         */
        StudyContext.canAccessEntityQueries = function() {
            return StudyContext.canWithQueries('read');
        };

        /**
         * Can access entity audit
         *
         * @return {Boolean} TRUE if user can read audit
         */
        StudyContext.canAccessEntityAudit = function() {
            return AccountManager.hasPermission('read audit trail');
        };

        /**
         * Is entity blocked
         *
         * @return {Boolean} TRUE if entity is blocked
         */
        StudyContext.isEntityLocked = function isEntityLocked() {
            return $injector.has('RecordStatesService') ? $injector.get('RecordStatesService').isLocked() : false;
        };

        return StudyContext;
    },
]);
