'use strict';

angular.module('study.placeholders')
    .directive('entitySectionLink', function(){
        return {
            restrict: 'A',
            replace: true,
            transclude: true,
            templateUrl: 'modules/placeholders/entity-section-link/template.html',
            controller: 'PlaceholderEntitySectionLinkController',
            controllerAs: 'placeholder',
            scope: {
                sectionName: '@entitySectionLink',
                stateParams: '=entitySectionLinkParams'
            },
        };
    });
