'use strict';

angular.module('error')
    .component('errorPage', {
        templateUrl: 'modules/error/components/error-page/template.html',
        bindings: {
            type: '<',
            code: '<',
            info: '<',
        },
    });
