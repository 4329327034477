angular.module('ecrd.record.bulk').controller('RecordBulkController', [
    'RecordsManagerService',
    'ShareCrfApiErrorCodes',
    'CrdConfigurationService',
    'gettextCatalog',
    'AccountManager',
    function (RecordsManagerService, ShareCrfApiErrorCodes, CrdConfigurationService, gettextCatalog, AccountManager) {
        var vm = this;

        // Parámetros del listado. Si están a NULL quiere decir que no se ha accedido por la sección de listado y no hay
        // nada que hacer aquí
        vm.sdkParameters = null;

        // Relación de registros obtenidos sobre los que se aplican las operaciones en bloque
        vm.records = [];

        // Estado de carga: ocioso -> cargando -> éxito/error
        vm.status = 'idle';

        // Si el resultado de la petición ha sido un error, el mensaje de error
        vm.error = null;

        // Título de la parte de resumen
        vm.entityLabel = CrdConfigurationService.getLabelPlural();

        // Operación seleccionada sobre los elementos: status/certcopy
        vm.operation = null;

        // Cuando se marca la operación mediante el botón
        vm.selected = false;

        // Lista de operaciones disponibles
        vm.availableOptions = [];

        vm.$onInit = function() {
            if (CrdConfigurationService.hasStates()) {
                if (AccountManager.hasPermission('record bulk update state')) {
                    vm.availableOptions.push('status');
                }
                if (AccountManager.hasPermission('record bulk download certcopies')) {
                    vm.availableOptions.push('certcopy');
                }
            }
    
            if (vm.availableOptions.length === 0) {
                vm.status = 'error';
                vm.error = gettextCatalog.getString('No hay operaciones disponibles');
            } else if (angular.isUndefined(vm.sdkParameters) || vm.sdkParameters === null) {
                vm.status = 'error';
                vm.error = gettextCatalog.getString('Debe volver a comenzar desde el listado de {{ label }}', {
                    label: vm.entityLabel,
                });
            } else {
                loadRecords().then(function() {
                    vm.operation = 'status';
                });
            }
        };

        function loadRecords() {
            vm.status = 'loading';

            return RecordsManagerService.bulkList(vm.sdkParameters)
                .then(function(response) {
                    vm.records = response.data;
                    vm.error = false;

                    vm.status = 'ready';
                })
                .catch(function(error) {
                    vm.records = [];
                    vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                    vm.status = 'error';
                });
        }
    },
]);
