'use strict';

angular.module('table-settings')
    .component('tableItemsSelector', {
        templateUrl: 'modules/table-settings/components/table-items-selector/template.html',
        controller: 'TableItemsSelectorController',
        bindings: {
            id: '@',
            limit: '=',
        },
    });
