/* eslint-disable angular/component-limit */
'use strict';

angular.module('sharecrf.slider', ['rzModule'])
    .controller('FieldVasController', ['$scope', '$timeout', function($scope, $timeout) {
        var resize = false;
        function resizeSlider() {
            resize = true;
            $timeout(function() {
                if (resize) {
                    $scope.$broadcast('reCalcViewDimensions');
                    resize = false;
                }
            });
        }

        $scope.$on('iconQueriesUpdated', resizeSlider);
        $scope.$on('iconAuditUpdated', resizeSlider);
        $scope.$on('iconTasksUpdated', resizeSlider);
    }])
    .run(['$templateCache', function($templateCache) {
        $templateCache.put('templates/slider.html',
            '<div class="rzslider">' +
                // 0 (left) Highlight outside two handles
                '<span class="rz-bar-wrapper rz-left-out-selection">' +
                    '<span class=rz-bar></span>' +
                '</span>' +
                // 1 (right) Highlight outside two handles
                '<span class="rz-bar-wrapper rz-right-out-selection">' +
                    '<span class=rz-bar></span>' +
                '</span>' +
                // 2 The slider bar
                '<span class="rz-bar-wrapper">' +
                    '<span class="rz-bar"></span>' +
                '</span>' +
                // 3 Highlight between two handles
                '<span class="rz-bar-wrapper">' +
                    '<span class="rz-bar rz-selection"></span>' +
                '</span>' +
                // 4 Left slider handle
                '<span class="rz-pointer rz-pointer-min"></span>' +
                // 5 Right slider handle
                '<span class="rz-pointer rz-pointer-max"></span>' +
                // 6 Floor label
                '<span class="rz-bubble rz-limit rz-floor"></span>' +
                // 7 Ceiling label
                '<span class="rz-bubble rz-limit rz-ceil"></span>' +
                // 8 Label above left slider handle
                '<span class="rz-bubble rz-model-value"></span>' +
                // 9 Label above right slider handle
                '<span class="rz-bubble rz-model-high"></span>' +
                // 10 Range label when the slider handles are close ex. 15 - 17
                '<span class="rz-bubble"></span>' +
                // 11 The ticks
                '<ul ng-show="showTicks" class="rz-ticks">' +
                    '<li ng-repeat="t in ticks track by $index" class="rz-tick" ng-class="{\'rz-selected\': t.selected}">' +
                        '<span ng-if="t.value != null" class="rz-tick-value">{{ t.value }}</span>' +
                        '<span ng-if="t.legend != null" class="rz-tick-legend">{{ t.legend }}</span>' +
                        '<span ng-if="t.tooltip" class="tooltip">{{ t.tooltip }}</span>' +
                    '</li>' +
                '</ul>' +
            '</div>'
        );
    }]);
