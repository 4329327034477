'use strict';

angular.module('table-settings')
    .factory('TableItems', ['$cookies', function($cookies) {
        var TableItems = {};

        TableItems.getDefaultOptions = function() {
            return [25, 50, 100, 250];
        };

        TableItems.isValid = function(option) {
            return option && TableItems.getDefaultOptions().indexOf(option) > -1;
        };

        TableItems.getSelectedOption = function(tableId) {
            var tableSettings = $cookies.getObject('tableSettings') || {};

            var selectedOption = TableItems.getDefaultOptions()[0];

            if (tableSettings[tableId] && tableSettings[tableId].limit && TableItems.isValid(tableSettings[tableId].limit)) {
                selectedOption = tableSettings[tableId].limit;
            }

            return selectedOption;
        };

        TableItems.setSelectedOption = function(tableId, option) {
            var tableSettings = $cookies.getObject('tableSettings') || {};

            if (TableItems.isValid(option)) {
                tableSettings[tableId] = tableSettings[tableId] || {};
                tableSettings[tableId].limit = option;

                $cookies.putObject('tableSettings', tableSettings);
            }
        };

        return TableItems;
    }]);
