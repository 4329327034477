'use strict';

angular.module('route')
    .factory('RouteError', function() {
        var RouteError = function(message, context) {
            this.name = 'RouteError';
            this.message = message;

            this.type = 'RouteError';
            this.code = message;
            this.context = context || {};

            this.stack = (new Error()).stack;
        };
        RouteError.prototype = new Error;

        return RouteError;
    });
