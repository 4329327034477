/* eslint-disable angular/no-run-logic */
/* eslint-disable angular/component-limit */
angular.module('error')
    // @see https://docs.angularjs.org/api/ng/service/$exceptionHandler
    .factory('$exceptionHandler', ['$log', function($log) {
        function isModalDismissed(error) {
            return error === '$dismissed';
        }

        function isSDKClientError(error) {
            return error && error.name === 'ShareCrfSdkError';
        }

        function isAbortedXHR(error) {
            return /"xhrStatus":\s*"abort"/.test(typeof error === 'string' ? error : (error.message || ''));
        }

        return function ShareCrfExceptionHandler(exception, cause) {
            // Tratamiento de errores .-
            //
            // Los errores en código síncrono y las promesas rechazadas, siempre dentro de código AngularJS, provocan
            // excepciones que son tratadas en este manejador. Los errores lanzados fuera de componentes, controladores,
            // servicios, etc. de AngularJS son atrapados directamente por el handler global de FrontErrors
            //
            // Log de errores .-
            //
            // De momento se excluyen tanto los provocados por promesas rechazadas al cerrar modales, como los errores
            // por respuesta sin éxito en llamadas a la API con el SDK. Notas:
            //
            // TODO: Los errores 5xx se deberían registrar con $log.error, para ser enviados con FrontErrors. En teoría
            // una respuesta 500 siempre es logada en el errorHandler del servidor, pero no está de más hacer más ruido
            // desde aquí en casos así-
            //
            // TODO: Las respuestas 4xx se deberían registrar con $log.warn, para avisarnos de alguna manera, ya que
            // pueden significar que algo falla en la aplicación (más allá de casos en los que el usuario no tenga
            // permisos para completar una acción, o cosas así). De momento FrontErrors no sirve para registrar warnings
            if (!isModalDismissed(exception) && !isSDKClientError(exception) && !isAbortedXHR(exception)) {
                $log.error(exception, cause);
            }
        };
    }])
    .run(['$transitions', '$state', '$log', 'InetsysMessages', 'gettext', 'gettextCatalog', function($transitions, $state, $log, InetsysMessages, gettext, gettextCatalog) {
        $state.defaultErrorHandler(function(error) {
            var type;

            // @see https://github.com/ui-router/core/blob/24da33201e55361e6e040b73306dd878e6f06b31/src/transition/rejectFactory.ts#L10
            switch (error.type) {
                case 2:
                    type = 'SUPERSEDED';
                    break;
                case 3:
                    type = 'ABORTED';
                    break;
                case 4:
                    type = 'INVALID';
                    break;
                case 5:
                    type = 'IGNORED';
                    break;
                case 6:
                    type = 'ERROR';
                    break;
            }

            $log.info('Transaction ' + type, error);
        });

        $transitions.onError({}, function transitionOnError(transition) {
            if (transition.error().type === 6) { // ERROR
                var error = transition.error().detail;

                var errorCode = error.code || error.message;
                var currentState, stateParams, currentStateParts, parentState;

                if (error.type && error.code) {
                    var uniqueCode = error.type + '::' + error.code;
                    switch (uniqueCode) {
                        case 'RouteError::authentication-required':
                            $state.go('login', {
                                warning: 'authentication-required',
                                redirect: transition.targetState().name(),
                                params: transition.targetState().params(),
                            });
                            break;

                        case 'RouteError::permission-required':
                            $state.go('error', error);
                            break;

                        case 'RouteError::entity_item_not_found':
                            currentState = error.context.state;
                            stateParams = error.context.stateParams;

                            currentStateParts = currentState.split('.');
                            currentStateParts.pop();

                            if (currentStateParts.length) {
                                InetsysMessages.error({
                                    title: gettextCatalog.getString('El elemento no existe'),
                                    body: gettext('El elemento de la lista al que intenta acceder no existe.'),
                                });

                                // Redirect to parent state to check if it is valid
                                parentState = currentStateParts.join('.');
                                $state.go(parentState, stateParams);
                            }
                            break;

                        case 'RouteError::hidden-element':
                            currentState = error.context.state;
                            stateParams = error.context.stateParams;

                            currentStateParts = currentState.split('.');
                            currentStateParts.pop();

                            if (currentStateParts.length) {
                                InetsysMessages.error({
                                    title: gettextCatalog.getString('La sección está oculta'),
                                    body: gettext('La sección del eCRD a la que intenta acceder está oculta y no es accesible.'),
                                });

                                // Redirect to parent state to check if it is valid
                                parentState = currentStateParts.join('.');
                                $state.go(parentState, stateParams);
                            }
                            break;

                        case 'StudyError::study-status-closed':
                        case 'StudyError::study-status-removed':
                            $state.go('error', error);
                            break;

                        default:
                            // Log unexpected error
                            $log.error(error, 'transition to ' + transition.to().name);

                            $state.go('error', error);
                    }
                }
                else {
                    // Log error
                    $log.error(error, 'transition to state ' + transition.to().name);

                    $state.go('error', {
                        type: error.type || 'Error',
                        code: errorCode,
                        info: error.info || {},
                    });
                }
            }
        });
    }]);
