/* eslint-disable angular/component-limit */
angular.module('ecrd.breadcrumbs', [])
    .component('ecrdBreadcrumbs', {
        transclude: true,
        templateUrl: 'modules/ecrd/components/breadcrumbs/template.html',
    })
    .controller('ecrdBreadcrumbsController', ['$stateParams', function($stateParams) {
        var vm = this;
        vm.stateParams = $stateParams;
    }]);
