angular.module('sharecrf.progress-status').component('progressStatusBar', {
    templateUrl: 'modules/progress-status/components/bar/template.html',
    controller: ['ProgressStatus', function(ProgressStatus) {
        var vm = this;

        vm.progress = {};

        vm.$onInit = function () {
            vm.progress = ProgressStatus.get();
        };
    }],
});
