angular.module('sharecrf.login-info').component('lastLoginDate', {
    templateUrl: 'modules/login-info/components/last-login-date/template.html',
    controller: ['AccountManager', 'Event', function(AccountManager, Event) {
        var vm = this;

        Event.on('login', function() {
            reload(vm);
        });
        Event.on('logout', function() {
            clear(vm);
        });

        vm.$onInit = function() {
            reload(vm);
        };

        vm.close = function() {
            vm.visible = false;
        };

        function reload(ctrl) {
            ctrl.loginInfo = AccountManager.getLoginInfo();
            ctrl.visible = !!ctrl.loginInfo.lastLogin;
        }

        function clear(ctrl) {
            ctrl.loginInfo = null;
            ctrl.visible = null;
        }
    }],
});
