angular.module('study.dashboard')
    .component('inclusionRatioReportBySite', {
        templateUrl: 'modules/dashboard/components/inclusion-ratio-report-by-site/template.html',
        controller: [
            '$window',
            'gettextCatalog',
            'ShareCrfSDK',
            'StudyLanguageService',
            '$log',
            function($window, gettextCatalog, ShareCrfSDK, StudyLanguageService, $log) {
                var vm = this;
                var moment = $window.moment;
                var c3 = $window.c3;
                var d3 = $window.d3;
                var chartData = [];

                vm.loading = false;
                vm.error = false;

                // Configuración por defecto de los filtros
                vm.filters = {
                    startDate: moment().subtract(6, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                };

                // El objeto de gráfico generado
                var chart = null;

                vm.$onInit = function() {
                    createChart();
                    reloadChart();
                };

                vm.reload = function(form) {
                    if (form.$valid) {
                        reloadChart();
                    }
                };

                function createChart() {
                    var chartConfig = {
                        bindto: '#inclusion-ratio-by-site',
                        data: {
                            json: [],
                            type: 'bar',
                            empty: {
                                label: {
                                    text: gettextCatalog.getString('No hay datos'),
                                },
                            },
                            names: {
                                // Nombre de la entidad en la leyenda
                                records: gettextCatalog.getString('Registros'),
                            },
                        },
                        axis: {
                            rotated: true,
                            x: { // eslint-disable-line id-length
                                type: 'category',
                                tick: {
                                    multiline: true,
                                    multilineMax: 3,
                                },
                            },
                            y: { // eslint-disable-line id-length
                                tick: {
                                    format: d3.format('d'),
                                },
                            },
                        },
                    };

                    chart = c3.generate(chartConfig);
                }

                function reloadChart() {
                    if (!chart) {
                        return;
                    }

                    vm.loading = true;
                    vm.error = false;


                    return getData()
                        .then(function(response) {
                            chartData = response.data.map(function(item) {
                                item.label = '[' + item['site.code'] + '] ' + item['site.name'];

                                return item;
                            });

                            // redimensiona la altura del gráfico en función del número de filas
                            var rowHeight = 36; // px per row
                            var offsetLegend = 60; // footer height in px
                            var minHeight = rowHeight + offsetLegend; // at least one row height

                            chart.resize({
                                height: Math.max(minHeight, chartData.length * rowHeight + offsetLegend),
                            });

                            chart.load({
                                unload: true,
                                json: chartData,
                                keys: {
                                    x: 'label', // eslint-disable-line id-length
                                    value: ['records'],
                                },
                                colors: {
                                    records: '#455b73',
                                },
                            });
                        })
                        .then(function() {
                            vm.loading = false;
                        })
                        .catch(function(error) {
                            $log.error(error);
                            vm.error = true;
                            vm.loading = false;
                        });
                }

                function getData() {
                    var parameters = {};
                    if (vm.filters.startDate) {
                        parameters.firstDate = vm.filters.startDate + 'T00:00:00';
                    }
                    if (vm.filters.endDate) {
                        parameters.lastDate = vm.filters.endDate + 'T23:59:59';
                    }
                    parameters.lang = StudyLanguageService.language;

                    return ShareCrfSDK.dashboard.inclusionRateBySite(parameters);
                }

                vm.downloading = false;
                vm.downloadExcel = function() {
                    if (!vm.error && !vm.loading) {
                        generateExcel(chartData);
                    }
                };

                function generateExcel(response) {
                    var title = gettextCatalog.getString('Inclusion por centro');
                    var columns = [
                        {
                            id: 'site.code',
                            header: gettextCatalog.getString('Código de centro'),
                        },
                        {
                            id: 'site.name',
                            header: gettextCatalog.getString('Nombre del centro'),
                        },
                        {
                            id: 'records',
                            header: gettextCatalog.getString('Registros'),
                        },
                    ];

                    var XLSX = $window.XLSX;
                    var workbook = XLSX.utils.book_new();

                    var columnWidth = [];
                    var headers = columns.map(function(column, index) {
                        columnWidth[index] = Math.max(columnWidth[index] || 0, column.header.length);

                        return column.header;
                    });

                    var worksheet = XLSX.utils.aoa_to_sheet([headers]);

                    angular.forEach(response, function(row) {
                        var data = [];
                        angular.forEach(columns, function(column, index) {
                            var value = getRowData(row, column.id);
                            if (angular.isFunction(column.formatter)) {
                                value = column.formatter(row);
                            }
                            data.push(value);
                            var length = value ? String(value).length : 1;
                            columnWidth[index] = Math.max(columnWidth[index] || 0, length);
                        });
                        XLSX.utils.sheet_add_aoa(worksheet, [data], { origin: -1 });
                    });

                    worksheet['!cols'] = columnWidth.map(function(width) {
                        return { wch: Math.min(width ? width : 0, 40) };
                    });

                    XLSX.utils.book_append_sheet(workbook, worksheet, title);
                    XLSX.writeFile(workbook, _slugify(title) + '.xlsx');
                }

                function _slugify(input) {
                    // make lower case and trim
                    var slug = input.toLowerCase().trim();
                    // replace invalid chars with spaces
                    slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
                    // replace multiple spaces or hyphens with a single hyphen
                    slug = slug.replace(/[\s-]+/g, '-');

                    return slug;
                }

                function getRowData(data, attribute) {
                    return angular.isArray(attribute)
                        ? attribute.map(function(attr) { // el valor de la celda se construye a partir de más de un dato
                            return $window._.get(data, attr);
                        })
                        : $window._.get(data, attribute);
                }
            },
        ],
    });
