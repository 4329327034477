'use strict';

angular.module('study.login')
    .config(['$stateProvider', 'RouteProvider', function($stateProvider, RouteProvider) {
        // Login form
        $stateProvider.state(RouteProvider.create('login', {
            url: '/login?warning?message',
            component: 'loginForm',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Acceso');
            }],
            params: {
                redirect: {
                    type: 'string',
                    value: null,
                    squash: true,
                },
                params: {
                    type: 'any',
                    value: null,
                    squash: true,
                },
                error: {
                    type: 'string',
                    value: null,
                    squash: true,
                },
                warning: {
                    type: 'string',
                    value: null,
                    squash: true,
                },
                message: {
                    type: 'string',
                    value: null,
                    squash: true,
                },
            },
            secure: false,
            redirectTo: function(transition) {
                var AccountManager = transition.injector().get('AccountManager');

                return AccountManager.init()
                    .then(function() {
                        if (AccountManager.isLogged()) {
                            return {
                                state: 'front',
                                params: {},
                            };
                        }
                    });
            },
            resolve: {
                onLogin: ['$transition$', '$state', 'AccountManager', 'StudyEnvironment', 'StudyInfo', 'GTM', function($transition$, $state, AccountManager, StudyEnvironment, StudyInfo, GTM) {
                    var targetState = $transition$.params().redirect || 'front';
                    var targetParams = $transition$.params().params || {};

                    return function() {
                        var loggedUser = AccountManager.getLoggedUser();

                        if (loggedUser.inStudy === false) {
                            $state.go('error', {
                                type: 'StudyUserError',
                                code: 'user-not-in-study',
                            });
                        }
                        else if (loggedUser.blocked) {
                            $state.go('error', {
                                type: 'StudyUserError',
                                code: 'study-user-blocked',
                            });
                        }
                        else if (loggedUser.id && loggedUser.currentProfile
                            && (!Array.isArray(loggedUser.profiles) || !loggedUser.profiles.find(function(profile) {
                                return profile.id === loggedUser.currentProfile.id;
                            }))
                        ) {
                            $state.go('error', {
                                type: 'StudyUserError',
                                code: 'environment-access-denied',
                            });
                        }

                        GTM.pushEvent({
                            event: 'login',
                            origin: 'study',
                            study: StudyInfo.getInfo().subdomain,
                            environment: StudyEnvironment.get(),
                            userId: loggedUser.id,
                        });

                        return $state.go(targetState, targetParams);
                    };
                }],
                message: ['$transition$', 'RouteMessages', function($transition$, RouteMessages) {
                    var paramValue = $transition$.params().message;

                    return paramValue ? RouteMessages.getHumanReadableMessage(paramValue) : null;
                }],
                warning: ['$transition$', 'RouteMessages', function($transition$, RouteMessages) {
                    var paramValue = $transition$.params().warning;
                    var readableMessage = paramValue ? RouteMessages.getHumanReadableMessage(paramValue) : null;

                    return readableMessage === paramValue ? null : readableMessage;
                }],
                error: ['$transition$', 'RouteMessages', function($transition$, RouteMessages) {
                    var paramValue = $transition$.params().error;

                    return paramValue ? RouteMessages.getHumanReadableMessage(paramValue) : null;
                }],
            },
        }));
    }]);
