angular.module('sharecrf.environment').controller('EnvironmentInfoController', [
    'StudyLanguageService',
    function EnvironmentInfoController(StudyLanguageService) {
        var vm = this;

        var hostParts = document.location.hostname.split('.'); // eslint-disable-line angular/document-service
        var domain = hostParts.pop();
        var language = StudyLanguageService.language;

        vm.classContainer = 'info-environment--domain-' + domain;
        vm.languageEs = language.code === 'es';
        vm.languageEn = language.code !== 'es';
    },
]);
