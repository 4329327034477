'use strict';

angular.module('app.entity.field').controller('FieldActionsController', [
    '$scope',
    '$exceptionHandler',
    '$injector',
    '$stateParams',
    'RecordDataService',
    'StudyContext',
    'FormFieldsService',
    'CrfService',
    'AccountManager',
    'CrdConfigurationService',
    'ViewMode',
    'ViewModes',
    'Event',
    'Modal',
    function(
        $scope,
        $exceptionHandler,
        $injector,
        $stateParams,
        RecordDataService,
        StudyContext,
        FormFieldsService,
        CrfService,
        AccountManager,
        CrdConfigurationService,
        ViewMode,
        ViewModes,
        Event,
        Modal
    ) {
        var vm = this;
        vm.permissions = {};

        // Instancia de campo del CRD
        var field = null;

        // Atributos del campo: queremos saber si el campo es modificable
        var listIndices = RecordDataService.getCRF().getListIndices($stateParams);
        var fieldUid = null;
        var revisionModeHandler = null;

        var nonEditableRevisionStates = [];

        if (CrdConfigurationService.hasRevisionStates()) {
            vm.revisionModeEnabled = ViewMode.get() === ViewModes.REVISION;
            revisionModeHandler = Event.on('viewModeChanged', function(event, mode) {
                vm.revisionModeEnabled = mode === ViewModes.REVISION;
            });
            nonEditableRevisionStates = CrdConfigurationService.getRevisionStates().getStates().filter(function(state) {
                return !state.allowsEdition();
            });
        }

        // Si el campo tiene la capacidad de tener datos perdidos
        var acceptsMissingData = false;

        var missingDataService = $injector.has('MissingDataService') ? $injector.get('MissingDataService') : undefined;
        var missingValue = $injector.has('MISSING_VALUE') ? $injector.get('MISSING_VALUE') : undefined;
        var revisionCountService = $injector.has('RevisionCount') ? $injector.get('RevisionCount') : undefined;

        vm.$onInit = function() {
            // Permisos para las acciones: controla la visibilidad
            vm.permissions = {
                viewNotes: angular.isUndefined(vm.disableNotes) && StudyContext.canWithQueries('read'),
                viewAudit: angular.isUndefined(vm.disableAudit) && StudyContext.canAccessEntityAudit(),
            };

            AccountManager.init().then( function() {
                vm.permissions.resetValue = canResetValue();
            });

            fieldUid = RecordDataService.getElementUID(vm.fieldId, listIndices).join('.');

            if (missingDataService) {
                // Se añaden como propiedades dinámicas para reaccionar a los cambios de valor
                Object.defineProperties(vm.permissions, {
                    setMissingData: {
                        enumerable: true,
                        get: canSetMissingData,
                    },
                    cancelMissingData: {
                        enumerable: true,
                        get: canCancelMissingData,
                    },
                });
            }

            // Capacidad actual: controla el estado de los botones
            vm.allowed = {
                // No hay restricciones para consultar las notas y el audit más allá de ser accesibles al usuario actual
                viewNotes: vm.permissions.viewNotes,
                viewAudit: vm.permissions.viewAudit,
            };
            var tasks = {
                // Se puede eliminar el valor si el campo está editable. Con el getter vm.allowed.resetValue funciona
                // como FormFieldsService.isFieldDisabled, pero sin tener que resolver stateParams
                resetValue: {
                    enumerable: true,
                    get: function() {
                        return isEditable() && !hasMissingReason();
                    },
                },
            };
            if (missingDataService) {
                tasks.setMissingData = {
                    enumerable: true,
                    get: isEditable,
                };
                tasks.cancelMissingData = {
                    enumerable: true,
                    get: isEditable,
                };
            }
            Object.defineProperties(vm.allowed, tasks);

            function isEditable() {
                return vm.open && FormFieldsService.isFieldEditable(vm.fieldId, listIndices)
                    && !isDisabledByRevision()
                    && CrfService.isElementEditableToCurrentProfile(vm.fieldId);
            }

            function isDisabledByRevision() {
                return !!revisionCountService && nonEditableRevisionStates.some(function(state) {
                    return revisionCountService.isFieldRevised(fieldUid, state.getId());
                });
            }

            // Acción activa sobre este campo
            vm.selectedAction = null;

            $scope.$emit('iconTasksUpdated', true);
        };

        vm.$onDestroy = function() {
            $scope.$emit('iconTasksUpdated', false);

            if (angular.isFunction(revisionModeHandler)) {
                revisionModeHandler();
            }
        };

        vm.$onChanges = function(changes) {
            if (angular.isDefined(changes.fieldId)) {
                // Aseguramos que el ID del campo sea numérico, para ayudar a las comparaciones de tipo ===
                vm.fieldId = parseInt(vm.fieldId, 10);

                if (vm.permissions) {
                    vm.permissions.resetValue = canResetValue();
                }

                field = CrfService.getField(vm.fieldId);

                acceptsMissingData = CrdConfigurationService.hasMissingData() && field.isRequired();
            }
        };

        /**
         * Abre/cierra la lista de acciones
         * Llama a la función de salida onToggleMenu(string field, bool value)
         */
        vm.toggle = function() {
            vm.open = !vm.open;
            vm.onToggleMenu({ field: vm.fieldId, value: vm.open });
        };

        /**
         * Acción: limpiar el valor de un campo
         * Llama a la función de salida onResetValue(string field)
         */
        vm.resetValue = function() {
            if (vm.allowed.resetValue) {
                var previousValue = RecordDataService.getFieldValue(vm.fieldId, listIndices);

                var crf = RecordDataService.getCRF();
                if (crf.hasElement(vm.fieldId)) {
                    vm.onResetValue({
                        field: vm.fieldId,
                        previousValue: previousValue,
                        newValue: RecordDataService.getResetValue(vm.fieldId),
                    });
                }
            }
        };

        /**
         * Seleccionar una acción
         * @param  {string} action Nombre de la acción
         */
        function selectAction(action) {
            if (vm.selectedAction === action) {
                // Ocultar la acción
                vm.selectedAction = null;
            }
            else {
                // Cambiar la acción activa
                vm.selectedAction = action;
            }

            vm.onSelectAction({
                field: vm.fieldId,
                action: vm.selectedAction,
            });
        }

        /**
         * Acción: mostrar/ocultar lista de discrepancias
         */
        vm.toggleNotes = function() {
            if (vm.allowed.viewNotes) {
                selectAction('queries');
            }
        };

        /**
         * Acción: mostrar/ocultar audit del campo
         */
        vm.toggleAudit = function() {
            if (vm.allowed.viewAudit) {
                selectAction('audit');
            }
        };

        function canResetValue() {
            if (!field || !field.allowsValue() || field.getFormControl() === 'files'
                || field.getFormControl() === 'dicom'
                || (field.isEncrypted() && !AccountManager.hasPermission('view encrypted fields'))) {
                return false;
            }

            return StudyContext.canUpdateEntity() && CrfService.isElementEditableToCurrentProfile(vm.fieldId);
        }

        function canSetMissingData() {
            return acceptsMissingData && RecordDataService.isFieldValueEmpty(vm.fieldId, listIndices);
        }

        function hasMissingReason() {
            return missingDataService && angular.isDefined(missingDataService.getFieldReason(vm.fieldId, listIndices));
        }

        function canCancelMissingData() {
            return angular.isDefined(missingValue)
                && acceptsMissingData
                && RecordDataService.getFieldValue(vm.fieldId, listIndices) === missingValue;
        }

        if (missingDataService) {
            vm.setMissingData = function() {
                var previousValue = RecordDataService.getFieldValue(vm.fieldId, listIndices);

                return Modal.open({
                    templateUrl: 'modules/ecrd/components/field/field-actions/modal-missing-data.html',
                    data: {
                        field: field,
                    },
                    onlyActions: true,
                }).closePromise
                    .then(function(result) {
                        if (result.action === 'update-reason') {
                            return vm.onChangeValue({
                                field: vm.fieldId,
                                previousValue: previousValue,
                                newValue: result.value,
                            });
                        }

                        return vm.onToggleMenu();
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            $exceptionHandler(error, 'modal with missing data confirmation');
                        }
                    });
            };

            vm.cancelMissingData = function() {
                // Si se entra a la función es porque el servicio de missing data está definido
                missingDataService.removeFieldReason(vm.fieldId, listIndices);

                return vm.resetValue();
            };
        }
    },
]);
