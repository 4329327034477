!(function(ng) {
    'use strict';

    // Controller constructor
    function ExportAutoDownloadController($scope, $element, $timeout, $log, Event, ShareCrfSDK) {
        var vm = this;

        vm.ready = false;
        vm.downloadUrl = null;
        vm.error = false;

        var exportStartedListener = function ExportAutoDownloadOnExportStarted() {
            vm.ready = false;
            vm.downloadUrl = null;
            vm.error = false;
        };
        var exportFinishedListener = function ExportAutoDownloadOnExportFinished(event, dump) {
            vm.ready = true;
            vm.downloadUrl = ShareCrfSDK.exports.getURL(dump);

            $timeout(function() {
                try {
                    var clickEvent = new MouseEvent('click');
                    $element.find('a')[0].dispatchEvent(clickEvent);
                } catch (error) {
                    $log.error(error);
                    vm.error = true;
                }
            });
        };

        Event.on('exportStarted', exportStartedListener);
        Event.on('exportFinished', exportFinishedListener);

        $scope.$on('$destroy', function() {
            Event.remove('exportStarted', exportStartedListener);
            exportStartedListener = null;
            Event.remove('exportFinished', exportFinishedListener);
            exportFinishedListener = null;
        });
    }

    // Controller dependencies
    ExportAutoDownloadController.$inject = ['$scope', '$element', '$timeout', '$log', 'Event', 'ShareCrfSDK'];

    // Angular controller
    ng.module('export').controller('ExportAutoDownloadController', ExportAutoDownloadController);
})(angular);
