angular.module('app.notifications').config([
    '$stateProvider',
    'RouteProvider',
    function($stateProvider, RouteProvider) {
        $stateProvider.state(RouteProvider.create('notifications', {
            url: '/notifications',
            permission: 'access study notifications',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Notificaciones enviadas');
            }],
            component: 'studyNotificationsTable',
        }));

        $stateProvider.state(RouteProvider.create('record.edit.notifications', {
            url: '/notifications',
            permission: 'read records notifications',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Notificaciones enviadas');
            }],
            component: 'recordNotificationsTable',
            resolve: {
                // This state depends of 'record.edit'
                entityLoaded: ['initRecord', function(initRecord) {
                    return true;
                }],
            },
        }));
    },
]);
