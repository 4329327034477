angular.module('study.dashboard').component('approvalRevisionReport', {
    templateUrl: 'modules/dashboard/components/approval-revision-report/template.html',
    controller: [
        'CrdConfigurationService',
        'gettextCatalog',
        '$q',
        'ShareCrfSDK',
        'ShareCrfApiErrorCodes',
        '$timeout',
        '$window',
        'numberFilter',
        'DashboardUtils',
        function(
            CrdConfigurationService,
            gettextCatalog,
            $q,
            ShareCrfSDK,
            ShareCrfApiErrorCodes,
            $timeout,
            $window,
            numberFilter,
            DashboardUtils
        ) {
            var vm = this;
            var c3 = $window.c3;

            vm.title = '';
            vm.data = {};
            var charts = {};
            var totalBySite = {};
            var chartsData = {
                byApproval: {},
                byRevision: {},
            };

            vm.loading = false;
            vm.error = false;

            vm.hasStates = CrdConfigurationService.hasStates();
            vm.hasRevision = CrdConfigurationService.hasRevisionStates();
            vm.labelPlural = CrdConfigurationService.getLabelPlural();
            var labelPlural = vm.labelPlural;

            if (vm.hasStates && vm.hasRevision) {
                vm.title = gettextCatalog.getString('Estados de aprobación y revisión');
            }
            else if (vm.hasStates) {
                vm.title = gettextCatalog.getString('Estados de aprobación');
            }
            else if (vm.hasRevision) {
                vm.title = gettextCatalog.getString('Estados de revisión');
            }

            vm.approvalStates = vm.hasStates ? CrdConfigurationService.getStates().getStates() : [];
            vm.revisionStates = vm.hasRevision ? CrdConfigurationService.getRevisionStates().getStates() : [];

            vm.$onInit = function() {
                createCharts();
                loadData();
            };

            function loadData() {
                vm.loading = true;
                vm.error = false;

                return $q.resolve()
                    .then(ShareCrfSDK.dashboard.approvalRevisionDetail)
                    .then(function(data) {
                        vm.data = data;

                        angular.forEach(data.total, function(row) {
                            totalBySite[row['site.code']] = row;
                        });

                        resizeCharts();

                        angular.forEach(vm.approvalStates, loadApprovalChart);
                        angular.forEach(vm.revisionStates, loadRevisionChart);

                        setApprovalAverages();
                        setRevisionAverages();
                    })
                    .catch(function(error) {
                        vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                        throw error;
                    })
                    .finally(function() {
                        vm.loading = false;
                    });
            }

            function createCharts() {
                $timeout(function() {
                    angular.forEach(vm.approvalStates, createApprovalChart);
                    angular.forEach(vm.revisionStates, createRevisionChart);
                });
            }

            function createApprovalChart(state) {
                var stateId = state.getId();

                var labelText = '% ' + gettextCatalog.getString('{{label}} {{state}}', {
                    label: labelPlural,
                    state: state.getName(),
                });

                var chartOptions = {
                    bindto: '#approval-' + stateId,
                    data: {
                        json: [],
                        type: 'bar',
                        keys: {
                            x: 'siteCode',
                            value: ['rate'],
                        },
                        empty: {
                            label: {
                                text: gettextCatalog.getString('No hay datos'),
                            },
                        },
                        labels: {
                            format: function(value) {
                                return formatDecimals(value) + '%';
                            },
                        },
                        names: {
                            rate: labelText,
                        },
                        colors: {
                            rate: state.getColor(),
                        },
                    },
                    axis: {
                        rotated: true,
                        x: {
                            type: 'category',
                        },
                        y: {
                            label: {
                                text: labelText,
                                position: 'outer-top',
                            },
                            padding: {
                                top: 0,
                                bottom: 0,
                            },
                        },
                    },
                    bar: {
                        width: 20,
                    },
                    tooltip: {
                        contents: function(data) {
                            var row = chartsData.byApproval[stateId][data[0].index];

                            return DashboardUtils.tooltip({
                                header: '[' + row.siteCode + '] ' + row.siteName,
                                rows: [
                                    {
                                        key: 'rate',
                                        label: state.getName(),
                                        value: DashboardUtils.format.percentage(row.rate) + ' (' + row.records + ')',
                                        color: state.getColor(),
                                    },
                                    {
                                        key: 'total',
                                        label: vm.labelPlural,
                                        value: row.totalRecords,
                                    },
                                ],
                            });
                        },
                    },
                    legend: {
                        show: false,
                    },
                };

                charts[stateId] = c3.generate(chartOptions);
            }

            function createRevisionChart(state) {
                var stateId = state.getId();
                var stateColor = state.getColor();

                var chartOptions = {
                    bindto: '#revision-' + stateId,
                    data: {
                        json: [],
                        type: 'bar',
                        keys: {
                            x: 'siteCode',
                            value: ['rateRevised', 'rateNotRevisedFilled', 'rateNotRevised'],
                        },
                        empty: {
                            label: {
                                text: gettextCatalog.getString('No hay datos'),
                            },
                        },
                        labels: false,
                        names: {
                            rateRevised: '% ' + gettextCatalog.getString('Campos revisados'),
                            rateNotRevisedFilled: '% ' + gettextCatalog.getString('Campos completados sin revisar'),
                            rateNotRevised: '% ' + gettextCatalog.getString('Campos sin completar ni revisar'),
                        },
                        colors: {
                            rateRevised: stateColor,
                            rateNotRevised: '#eee',
                        },
                        groups: [
                            ['rateRevised', 'rateNotRevisedFilled', 'rateNotRevised'],
                        ],
                        order: null,
                        classes: {
                            rateNotRevisedFilled: 'bar-not-revised-filled-' + stateId,
                        },
                    },
                    axis: {
                        rotated: true,
                        x: {
                            type: 'category',
                        },
                        y: {
                            max: 100,
                            min: 0,
                            padding: {
                                top: 0,
                                bottom: 0,
                            },
                            label: {
                                text: '%',
                                position: 'outer-top',
                            },
                        },
                    },
                    bar: {
                        width: 20,
                    },
                    tooltip: {
                        contents: function(data) {
                            var row = chartsData.byRevision[stateId][data[0].index];

                            return DashboardUtils.tooltip({
                                header: '[' + row.siteCode + '] ' + row.siteName,
                                rows: [
                                    {
                                        key: 'rateRevised',
                                        label: gettextCatalog.getString('Campos revisados'),
                                        value: DashboardUtils.format.percentage(row.rateRevised) + ' (' + row.revised + ')',
                                        color: stateColor,
                                    },
                                    {
                                        key: 'rateNotRevisedFilled',
                                        label: gettextCatalog.getString('Campos completados sin revisar'),
                                        value: DashboardUtils.format.percentage(row.rateNotRevisedFilled) + ' (' + row.notRevisedFilled + ')',
                                        // Si no tiene la propiedad color no se pinta el recuadro, que tiene el color correcto en el CSS generado
                                        color: 'transparent',
                                    },
                                    {
                                        key: 'rateNotFilled',
                                        label: gettextCatalog.getString('Campos sin completar ni revisar'),
                                        value: DashboardUtils.format.percentage(row.rateNotRevised) + ' (' + row.notRevised + ')',
                                        color: '#eee',
                                    },
                                    {
                                        key: 'total',
                                        label: gettextCatalog.getString('Total campos'),
                                        value: row.totalFields,
                                    },
                                ],
                            });
                        },
                    },
                };

                charts[stateId] = c3.generate(chartOptions);
            }

            function loadApprovalChart(state) {
                var stateId = state.getId();

                var remainingSites = angular.copy(totalBySite);

                var stateData = vm.data.byApproval[stateId].map(function(row) {
                    delete remainingSites[row['site.code']];

                    return {
                        records: row.records,
                        siteCode: row['site.code'],
                        siteName: row['site.name'],
                        totalRecords: totalBySite[row['site.code']].records,
                        rate: row.records * 100 / totalBySite[row['site.code']].records,
                    };
                });

                angular.forEach(remainingSites, function(row) {
                    stateData.push({
                        records: 0,
                        siteCode: row['site.code'],
                        siteName: row['site.name'],
                        totalRecords: row.records,
                        rate: 0,
                    });
                });

                chartsData.byApproval[stateId] = stateData.sort(function(a, b) {
                    return b.siteCode < a.siteCode ? 1 : -1;
                });

                charts[stateId].load({
                    json: chartsData.byApproval[stateId],
                    keys: {
                        x: 'siteCode',
                        value: ['rate'],
                    },
                });

                var maxRate = Math.max.apply(null, stateData.map(function(row) {
                    return row.rate;
                }));
                if (maxRate > 0) {
                    // dado que la propiedad de axis.padding no se puede establecer dinámicamente, de manera general el
                    // valor es 0 y artificialmente añadimos al valor máximo
                    charts[stateId].axis.max(Math.min(maxRate * 1.05, 100));
                }
            }

            function loadRevisionChart(state) {
                var stateId = state.getId();

                var siteIndices = {};
                var stateData = Object.keys(totalBySite).reduce(function(acc, key) {
                    siteIndices[key] = acc.length;

                    return acc.concat({
                        siteCode: totalBySite[key]['site.code'],
                        siteName: totalBySite[key]['site.name'],
                        totalFields: 0,
                        revised: 0,
                        rateRevised: 0,
                        notRevisedFilled: 0,
                        rateNotRevisedFilled: 0,
                        notRevised: 0,
                        rateNotRevised: 0,
                    });
                }, []);

                angular.forEach(vm.data.byRevision[stateId], function(row) {
                    var siteCode = row['record.site.code'];
                    var siteData = stateData[siteIndices[siteCode]];

                    if (row.revised) {
                        siteData.revised += row.totalRevised;
                    }
                    else if (row.filled) {
                        siteData.notRevisedFilled += row.totalRevised;
                    }
                    else {
                        siteData.notRevised += row.totalRevised;
                    }

                    siteData.totalFields += row.totalRevised;
                });

                angular.forEach(stateData, function(row) {
                    row.rateRevised = row.revised * 100 / row.totalFields;
                    row.rateNotRevisedFilled = row.notRevisedFilled > 0 ? row.notRevisedFilled * 100 / row.totalFields : 0;
                    row.rateNotRevised = row.notRevised > 0 ? row.notRevised * 100 / row.totalFields : 0;
                });

                chartsData.byRevision[stateId] = stateData.sort(function(a, b) {
                    return b.siteCode < a.siteCode ? 1 : -1;
                });

                charts[stateId].load({
                    json: chartsData.byRevision[stateId],
                    keys: {
                        x: 'siteCode',
                        value: ['rateRevised', 'rateNotRevisedFilled', 'rateNotRevised'],
                    },
                });
            }

            function formatDecimals(value) {
                return numberFilter(value || 0, 2);
            }

            function resizeCharts() {
                // cálculo de la altura de los gráficos: como todos tienen el mismo número de filas, la altura va a
                // ser la misma
                // redimensiona la altura del gráfico en función del número de filas
                var rowHeight = 36; // px per row
                var offsetLegend = 74;
                var minHeight = rowHeight + offsetLegend; // at least one row height

                var chartHeight = Math.max(minHeight, vm.data.total.length * rowHeight + offsetLegend);

                angular.forEach(charts, function(chart) {
                    chart.resize({ height: chartHeight });
                });
            }

            function setApprovalAverages() {
                angular.forEach(vm.approvalStates, function(state) {
                    var stateId = state.getId();

                    var totalRecords = vm.data.total.reduce(function(total, row) {
                        return total + row.records;
                    }, 0);

                    var totalWithState = vm.data.byApproval[stateId].reduce(function(total, row) {
                        return total + row.records;
                    }, 0);

                    var rate = totalRecords > 0 ? totalWithState * 100 / totalRecords : 0;

                    charts[stateId].ygrids.add({
                        text: gettextCatalog.getString('Promedio: {{value}}%', {
                            value: formatDecimals(rate || 0),
                        }),
                        value: rate,
                    });
                });
            }

            function setRevisionAverages() {
                angular.forEach(vm.revisionStates, function(state) {
                    var stateId = state.getId();

                    var totalFields = 0;
                    var totalRevised = 0;

                    angular.forEach(chartsData.byRevision[stateId], function(row) {
                        totalFields += row.totalFields;
                        totalRevised += row.revised;
                    });

                    var rate = totalFields > 0 ? totalRevised / totalFields : 0;

                    charts[stateId].ygrids.add({
                        text: gettextCatalog.getString('Promedio: {{value}}%', {
                            value: formatDecimals(rate * 100 || 0),
                        }),
                        value: rate,
                        class: 'average',
                        position: 'middle',
                    });
                });
            }
        },
    ],
});
