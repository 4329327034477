'use strict';

angular.module('front')
    .component('frontPage', {
        templateUrl: 'generated/html/front.html',
        controller: 'FrontPageController',
        bindings: {
            errorCode: '<',
        },
    });
