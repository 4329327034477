/* eslint-disable angular/foreach */
angular.module('sharecrf.partial-date').component('scrfInputPartialDate', {
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        ngDisabled: '<',
        viewFormat: '@',
        name: '@',
    },
    templateUrl: 'modules/partial-date/widgets/input/template.html',
    controller: [
        '$window',
        function($window) {
            var vm = this;

            vm.componentsSeparator = '/';
            vm.viewFormatComponents = ['DD', 'MM', 'YYYY'];
            vm.modelFormatComponents = ['DD', 'MM', 'YYYY'];

            vm.day = '';
            vm.dayIndex = 0;
            vm.dayUnknownLength = 2;

            vm.month = '';
            vm.monthIndex = 1;
            vm.monthUnknownLength = 2;

            vm.year = '';
            vm.yearIndex = 2;
            vm.yearUnknownLength = 4;

            var viewValue = '';
            Object.defineProperty(vm, 'model', {
                enumerable: true,
                configurable: false,
                get: function() {
                    return viewValue;
                },
                set: function(value) {
                    viewValue = value;

                    parseViewValue(value, vm);
                    updateModelValue(vm);
                },
            });

            vm.$onInit = function() {
                vm.componentsSeparator = vm.viewFormat.indexOf('-') > -1 ? '-' : '/';
                vm.viewFormatComponents = vm.viewFormat.split(vm.componentsSeparator);
                vm.viewFormatComponents.forEach(function(value, index) {
                    if (value.indexOf('D') > -1) {
                        vm.dayIndex = index;
                        vm.dayUnknownLength = value.length;
                    }
                    else if (value.indexOf('M') > -1) {
                        vm.monthIndex = index;
                        vm.monthUnknownLength = value.length;
                    }
                    else if (value.indexOf('Y') > -1) {
                        vm.yearIndex = index;
                        vm.yearUnknownLength = value.length;
                    }
                });

                // Bind external ngModel to local model
                vm.ngModel.$render = function() {
                    parseModelValue(vm, vm.ngModel.$modelValue);
                };
            };
            vm.$onUpdate = function() {
                validateModel(vm);
            };

            /**
             * Set validation on external ngModelController
             *
             * @param {Controller} $ctrl  Angular controller
             *
             * @return {String}           Internal model value
             */
            function validateModel($ctrl) {
                var completedComponents = [$ctrl.day, $ctrl.month, $ctrl.year].filter(function(item) {
                    return item !== '';
                });

                // 1.- Fecha incompleta - es necesario que los tres componentes de la fecha estén definidos
                $ctrl.ngModel.$setValidity('incomplete-date', completedComponents.length === 0 || completedComponents.length === 3);

                // 2.- Fecha válida - La fecha debe ser válida aun teniendo algún componente definido como "desconocido"
                var dateFormatComponents = ['', '', ''];
                var modelFormatComponents = ['YYYY', 'MM', 'DD'];
                ['year', 'month', 'day'].forEach(function(component, index) {
                    dateFormatComponents[$ctrl[component + 'Index']] = $ctrl[component] === 'x'.repeat($ctrl[component + 'UnknownLength'])
                        ? '[' + 'x'.repeat($ctrl[component + 'UnknownLength']) + ']'
                        : $ctrl.viewFormatComponents[$ctrl[component + 'Index']];

                    if ($ctrl[component] === 'x'.repeat($ctrl[component + 'UnknownLength'])) {
                        modelFormatComponents[index] = '[x]';
                    }
                });
                var dateFormat = dateFormatComponents.join($ctrl.componentsSeparator);
                var modelFormat = modelFormatComponents.join('-');

                var date = $window.moment($ctrl.model.toLowerCase(), dateFormat, true);
                $ctrl.ngModel.$setValidity('date', completedComponents.length < 3 || date.isValid());

                return completedComponents.length === 3 && date.isValid() ? date.format(modelFormat) : '';
            }

            /**
             * Parse view value
             *
             * @param {String}     value Input value
             * @param {Controller} $ctrl Angular controller
             */
            function parseViewValue(value, $ctrl) {
                $ctrl.day = $ctrl.month = $ctrl.year = '';

                value.split($ctrl.componentsSeparator, 3).forEach(function(item, index) {
                    if (index === $ctrl.dayIndex) {
                        $ctrl.day = item.toLowerCase();
                    }
                    else if (index === $ctrl.monthIndex) {
                        $ctrl.month = item.toLowerCase();
                    }
                    else if (index === $ctrl.yearIndex) {
                        $ctrl.year = item.toLowerCase();
                    }
                });
            }

            /**
             * Parse model value
             *
             * @param {Controller} $ctrl       Angular controller
             * @param {String}     modelValue  Model value
             *
             * @returns {String}               Formatted value
             */
            function parseModelValue($ctrl, modelValue) {
                var modelValueParts = (modelValue || '').split('-', 3);
                var modelFormatComponents = ['YYYY', 'MM', 'DD'];
                var viewFormatComponents = [].concat($ctrl.viewFormatComponents);
                var viewValueParts = ['', '', ''];

                ['year', 'month', 'day'].forEach(function(component, index) {
                    if (modelValueParts[index] === 'x') {
                        modelFormatComponents[index] = '[x]';
                        viewValueParts[$ctrl[component + 'Index']] = 'x'.repeat($ctrl[component + 'UnknownLength']);
                        viewFormatComponents[$ctrl[component + 'Index']] = '[' + 'x'.repeat($ctrl[component + 'UnknownLength']) + ']';
                    }
                    else if (modelValueParts[index]) {
                        viewValueParts[$ctrl[component + 'Index']] = modelValueParts[index];
                    }
                });

                // If date is not complete, nothing to do
                var parsedValue;
                if (viewValueParts.filter(function(item) {
                    return !!item;
                }).length !== 3) {
                    parsedValue = '';
                }
                else {
                    var date = $window.moment(modelValue, modelFormatComponents.join('-'), true);

                    parsedValue = date.isValid()
                        ? date.format(viewFormatComponents.join($ctrl.componentsSeparator))
                        : '';
                }

                $ctrl.model = parsedValue;

                return parsedValue;
            }

            function updateModelValue($ctrl) {
                var modelValueComponents = [
                    $ctrl.year.indexOf('x') > -1 ? 'x' : $ctrl.year,
                    $ctrl.month.indexOf('x') > -1 ? 'x' : $ctrl.month,
                    $ctrl.day.indexOf('x') > -1 ? 'x' : $ctrl.day,
                ].filter(function(item) {
                    return !!item;
                });

                var modelValue = validateModel($ctrl);
                $ctrl.ngModel.$setViewValue(modelValue);
            }
        },
    ],
});
