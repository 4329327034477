angular.module('study-environment').provider('StudyEnvironment', function() {
    var provider = this;
    var _environment;

    provider.set = function(environment) {
        _environment = environment;
    };

    provider.$get = function() {
        return {
            get: function() {
                return _environment;
            },
            isTest: function() {
                return _environment === 'test';
            },
            isLive: function() {
                return _environment === 'live';
            },
        };
    };
});
