'use strict';

angular.module('study.import').controller('ImportLoadCSVStartController', [
    '$q',
    '$filter',
    '$state',
    'Upload',
    'gettext',
    'gettextCatalog',
    'CrfService',
    'CrdConfigurationService',
    'ShareCrfSDK',
    'ErrorMessages',
    function($q, $filter, $state, Upload, gettext, gettextCatalog, CrfService, CrdConfigurationService, ShareCrfSDK, ErrorMessages) {
        var vm = this;

        /**
         * Parámetros de router
         *
         * vm.importMode {string} Indica el modo de funcionamiento, 'create' o 'update'
         */

        // Lista de ficheros adicionales para cada lista
        vm.listCsvFiles = CrfService.getLists().map(function(list) {
            return {
                name: list.getName(),
                label: list.getLabel(),
            };
        });

        vm.csvFiles = {};
        vm.errorFiles = {};

        vm.entityLabel = CrdConfigurationService.getLabelPlural();

        var defaultDateFormat = CrdConfigurationService.getDateFormat();
        var testDateString = (new Date()).toISOString();
        vm.dateFormatOptions = [
            {
                value: defaultDateFormat,
                label: gettextCatalog.getString('Formato de entrada') + ' (' + $filter('dateFormat')(testDateString, defaultDateFormat) + ')',
            },
        ];
        if (defaultDateFormat !== 'YYYY-MM-DD') {
            vm.dateFormatOptions.push({
                value: 'YYYY-MM-DD',
                label: gettextCatalog.getString('Estándar ISO8601') + ' (' + $filter('dateFormat')(testDateString, 'YYYY-MM-DD') + ')',
            });
        }
        if (defaultDateFormat !== 'DD-MMM-YYYY') {
            vm.dateFormatOptions.push({
                value: 'DD-MMM-YYYY',
                label: gettextCatalog.getString('Formato unívoco') + ' (' + $filter('dateFormat')(testDateString, 'DD-MMM-YYYY') + ')',
            });
        }

        vm.options = {
            delimiter: 'COMMA',
            quote: 'DQUOTE',
            headerLine: 2,
            firstDataLine: 3,
            reasonOfChange: '',
            dateFormat: defaultDateFormat,
        };

        vm.delimiterOptions = [
            { id: 'COMMA', 'label': gettext('coma') },
            { id: 'SEMICOLON', 'label': gettext('punto y coma') },
        ];

        vm.quoteOptions = [
            { id: 'SQUOTE', 'label': gettext('comillas simples') },
            { id: 'DQUOTE', 'label': gettext('comillas dobles') },
        ];

        vm.$onInit = function() {
            // Opciones adicionales
            if (vm.importMode === 'update' && CrdConfigurationService.getAudit().hasReason()) {
                // si está activada la razón de cambio, el usuario deberá meter una única razón que se aplicará
                // a todos los cambios
                vm.reasonOfChangeEnabled = true;
            }
        };

        vm.importedFilename = null;
        vm.uploading = false;
        vm.uploadPercentage = 0;
        vm.errors = [];

        vm.cancelUpload = function() {
            vm.uploading = false;
        };

        vm.uploadFiles = function() {
            vm.uploading = true;
            // reiniciamos posibles resultados de una importación anterior
            vm.uploadPercentage = 0;
            vm.errors = [];
            if (angular.isUndefined(vm.csvFiles.root) && vm.importMode == 'create') {
                vm.errors.push(gettext('Debe importar al menos el fichero CSV principal'));
                vm.uploading = false;

                return;
            }
            if (vm.importMode === 'update') {
                var empty = true;
                for (var key in vm.csvFiles) {
                    if (angular.isDefined(vm.csvFiles[key])) {
                        empty = false;
                    }
                }
                if (empty) {
                    vm.errors.push(gettext('Debe seleccionar al menos un fichero CSV'));
                    vm.uploading = false;

                    return;
                }
            }
            var params = {
                delimiter: vm.options.delimiter,
                quote: vm.options.quote,
                headerLine: vm.options.headerLine,
                firstDataLine: vm.options.firstDataLine,
                reasonOfChange: vm.options.reasonOfChange,
                mode: vm.importMode,
                dateFormat: vm.options.dateFormat,
            };
            var sourceMapping = {};

            return ShareCrfSDK.import.create(params)
                .then(function(data) {
                    var importId = data.importId;
                    var uploads = [], promise;
                    for (var source in vm.csvFiles) {
                        sourceMapping[source] = vm.csvFiles[source].name;
                        promise = Upload.upload({
                            url: 'api/import/csv/upload/' + importId + '/' + source,
                            data: {
                                file: vm.csvFiles[source],
                            },
                        }).catch(function(response) {
                            var errorData = response.data;
                            vm.errors.push(gettextCatalog.getString('El fichero {{file}} no es formato CSV', {
                                file: errorData.info.filename,
                            }));

                            return true;
                        });
                        uploads.push(promise);
                    }

                    return $q.all(uploads)
                        .then(function() {
                            if (vm.errors.length === 0) {
                                $state.go('import.data.process', {
                                    importId: importId,
                                    sources: sourceMapping,
                                });
                            } else {
                                vm.uploading = false;
                            }
                        });
                })
                .catch(function(error) {
                    vm.errors.push(ErrorMessages.get(error));
                    vm.uploading = false;
                });
        };
    },
]);
