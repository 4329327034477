/* eslint-disable angular/component-limit */
/* eslint-disable angular/no-service-method */
angular.module('sharecrf-version', [])
    .service('ShareCrfVersion', [function() {
        var data = {
            apiVersion: null,
        };

        return {
            setVersion: function(value) {
                data.apiVersion = value;
            },
            get: function() {
                return data;
            },
        };
    }])
    .factory('ShareCrfVersionInterceptor', ['$window', 'ShareCrfVersion', function($window, ShareCrfVersion) {
        function isApiCall(calledUrl) {
            return calledUrl.indexOf('/' + $window.ShareCRF.environment + '/api/') === 0; // This is a ShareCRF API call
        }

        return {
            response: function(response) {
                if (isApiCall(response.config.url)) {
                    var apiVersion = response.headers('X-Api-Build-SHA');
                    ShareCrfVersion.setVersion(apiVersion);
                }

                return response;
            },
        };
    }])
    // Obtain API version (SHA)
    .config(['$httpProvider', function($httpProvider) {
        $httpProvider.interceptors.push('ShareCrfVersionInterceptor');
    }]);
