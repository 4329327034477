angular.module('ecrd').factory('EcrdMenuScrollManager', [
    '$window',
    'SharecrfViewPort', 
    'Event',
    function($window, SharecrfViewPort, Event) {
        var enabled = false;
        
        function getEffectiveHeight() {
            // Hay una cabecera fija al hacer scroll que limita el espacio disponible para mostrar el menú. El tamaño está fijado por CSS.
            var stickyHeaderHeight = 54;

            var effectiveHeight = 0;

            var viewportHeight = SharecrfViewPort.getSize().height;
            var mainElementClientRect = document.getElementsByTagName('main')[0].getBoundingClientRect();
            
            if (viewportHeight - mainElementClientRect.y <= mainElementClientRect.height) {
                effectiveHeight = viewportHeight;
                if (mainElementClientRect.y > 0) {
                    effectiveHeight -= mainElementClientRect.y;

                    if (mainElementClientRect.y < stickyHeaderHeight) {
                        effectiveHeight -= stickyHeaderHeight - mainElementClientRect.y;
                    }
                } 
                else {
                    effectiveHeight -= stickyHeaderHeight;
                }
            } else if (mainElementClientRect.y < 0) {
                effectiveHeight = mainElementClientRect.height + mainElementClientRect.y - stickyHeaderHeight;
            } else {
                effectiveHeight = mainElementClientRect.height;

                if (mainElementClientRect.y < stickyHeaderHeight) {
                    effectiveHeight -= stickyHeaderHeight - mainElementClientRect.y;
                }
            }

            return effectiveHeight;
        }

        function setMenuHeight() {
            document.querySelector('#entity-menu--wrapper').style.height = getEffectiveHeight() + 'px';
        }

        Event.on('viewport-size-changed', function() {
            if (!enabled) return;

            setMenuHeight();
        });

        return {
            enable: function() {
                if (enabled) return;

                enabled = true;
                angular.element($window).bind('scroll', setMenuHeight);
            },
            disable: function() {
                if (!enabled) return;

                enabled = false;
                angular.element($window).unbind('scroll', setMenuHeight);
            },
            update: function() {
                if (!enabled) return;

                setMenuHeight();
            },
        };
    },
]);