/* eslint-disable angular/di-unused */
angular.module('app.entity.menu').factory('EntityMenu', [
    '$window',
    '$stateParams',
    'EntityMenuConfig',
    'RecordDataService',
    'CrfService',
    'InetsysTools',
    '$q',
    '$timeout',
    function($window, $stateParams, EntityMenuConfig, RecordDataService, CrfService, InetsysTools, $q, $timeout) {
        /**
         * Contiene la información de cada ítem del menú (que se corresponde con una sección, lista o formulario)
         *
         * Está indexado por el ID numérico del elemento en el CRF
         *
         * @type {object}
         */
        var __menuMetadata = {};

        /**
         * Acceso directo a los padres de un nodo del menú que implican un cambio de ámbito (listas), indexados por
         * el ID de la sección hija
         *
         * @type {object}
         */
        var __menuItemsParents = {};
        /**
         * Acceso directo a los hijos de un nodo indexados por el UID cada elemento
         *
         * @type {object}
         */
        var __menuItemsChildren = {};

        /**
         * Lista de ítemes del menú indexados por UID del elemento que manejan
         *
         * @type {object}
         */
        var metadataByUid = {};

        var paused = false;

        function getMenuItemParent(sectionMetadata) {
            var key = sectionMetadata.uid.join('.');
            if (!(key in __menuItemsParents)) {
                var parentId = sectionMetadata.parents.slice(-1)[0];
                if (parentId) {
                    var parentStateParams = InetsysTools.simpleCopy(sectionMetadata.stateParams);
                    if (sectionMetadata.type === 'listItem') {
                        // Si es un ítem de lista, la lista que lo contiene no incluye su propio índice
                        delete parentStateParams[sectionMetadata.element.getName()];
                    }

                    __menuItemsParents[key] = getMenuItem(parentId, parentStateParams);
                } else {
                    __menuItemsParents[key] = null;
                }
            }

            return __menuItemsParents[key];
        }

        function getMenuItemChildren(menuItem) {
            var itemKey = menuItem.uid.join('.');

            if (!__menuItemsChildren[itemKey]) {
                // TODO filter + map = reduce
                __menuItemsChildren[itemKey] = Object.keys(metadataByUid)
                    .filter(function(candidateUid) {
                        return metadataByUid[candidateUid].parent === menuItem;
                    })
                    .map(function(uid) {
                        return metadataByUid[uid];
                    });
            }

            return __menuItemsChildren[itemKey];
        }

        function copySourceItem(source) {
            var itemCopy = {
                label: source.label,
                type: source.type,
                state: source.state,
                components: InetsysTools.simpleCopy(source.components),
                parents: InetsysTools.simpleCopy(source.parents),
            };

            // Tipo lista
            if ('list' in source) {
                itemCopy.list = {};
                for (var key in source.list) {
                    itemCopy.list[key] = copySourceItem(source.list[key]);
                }
                // En la inicialización esta propiedad siempre está vacía
                itemCopy.items = [];
            }

            return itemCopy;
        }

        function initMenuItem(baseStructure, sectionId, stateParams, uid) {
            // Los elementos de tipo "listItem" no se copian de la configuración y no hay peligro por usarlos tal cual
            var menuItem = baseStructure.type === 'listItem' ? baseStructure : copySourceItem(baseStructure);

            menuItem.sectionId = sectionId;
            menuItem.stateParams = InetsysTools.simpleCopy(stateParams);
            menuItem.uid = uid;

            // En algunos cálculos dentro de los plugins se usa este elemento para facilitar las operaciones
            menuItem.element = CrfService.getElement(sectionId);

            // Helpers
            Object.defineProperties(menuItem, {
                parent: {
                    enumerable: true,
                    get: function() {
                        return getMenuItemParent(this);
                    },
                },
                children: {
                    enumerable: true,
                    get: function() {
                        return getMenuItemChildren(this);
                    },
                },
            });

            return menuItem;
        }

        function getItemKey(sectionId, stateParams) {
            var listIndices = CrfService.getListIndices(stateParams);
            var uid = RecordDataService.getElementUID(sectionId, listIndices);

            if (!angular.isArray(uid)) {
                return null;
            }

            if (sectionId in listIndices) {
                // Si el ID de la lista se incluye en los índices, se trata de un elemento de la misma
                var itemId = RecordDataService.getListElementId(sectionId, listIndices[sectionId], listIndices);
                uid.push(itemId);
            }

            return uid.join('.');
        }

        function completeMenuStructure(menuMetadata, baseStructure, stateParams) {
            var listIndices = CrfService.getListIndices(stateParams);
            var sectionData = RecordDataService.getData(listIndices);

            var sectionMetadata;
            for (var sectionId in baseStructure) {
                var elementUid = RecordDataService.getElementUID(sectionId, listIndices);
                if (!elementUid) {
                    continue;
                }

                sectionMetadata = initMenuItem(baseStructure[sectionId], sectionId, stateParams, elementUid);

                var key = elementUid.join('.');
                metadataByUid[key] = sectionMetadata;

                // Para usar en la plantilla
                menuMetadata[sectionId] = sectionMetadata;

                if (baseStructure[sectionId].type === 'list') {
                    // si la lista está inicializada y tiene al menos un elemento en los datos
                    angular.forEach(sectionData[sectionId], function(itemData, itemIndex) {
                        stateParams[sectionMetadata.element.getName()] = itemIndex;

                        var itemUid = elementUid.concat(itemData.__id);
                        var menuItem = initMenuItem({
                            type: 'listItem',
                            element: sectionMetadata.element,
                            id: angular.isUndefined(itemData.__id) ? itemIndex : itemData.__id,
                            label: '',
                            sections: {},
                            // La lista de padres del ítem incluye a la propia lista
                            parents: sectionMetadata.parents.concat(sectionId),
                        }, sectionId, stateParams, itemUid);

                        var itemIndices = InetsysTools.simpleCopy(listIndices);
                        itemIndices[sectionId] = itemIndex + 1;
                        var itemKey = itemUid.join('.');
                        metadataByUid[itemKey] = menuItem;

                        completeMenuStructure(menuItem.sections, baseStructure[sectionId].list, stateParams);
                        sectionMetadata.items.push(menuItem);

                        delete stateParams[sectionMetadata.element.getName()];
                    });
                }
            }

            return menuMetadata;
        }

        function initMenuStructure(menuMetadata, stateParams) {
            var listIndices = CrfService.getListIndices(stateParams);
            var sectionData = RecordDataService.getData(listIndices);

            var pluginIndex, plugin, sectionMetadata;

            for (var sectionId in menuMetadata) {
                sectionMetadata = menuMetadata[sectionId];

                switch (sectionMetadata.type) {
                    case 'form':
                        for (pluginIndex in EntityMenuConfig.plugins) {
                            plugin = EntityMenuConfig.plugins[pluginIndex];

                            var changed = angular.isFunction(plugin.initFormFlags) && plugin.initFormFlags(
                                sectionMetadata,
                                sectionId,
                                sectionData,
                                stateParams
                            );

                            // Si el plugin cambia el estado del elemento del menú y éste no es un nodo raíz, el cambio
                            // se propaga hacia arriba
                            //$timeout(function() {
                            // GARU-5342 No se mete en un $timeout porque hay operaciones muy costosas de reglas en el
                            // mismo tick y la propagación se retrasa de manera destacable
                            var currentItem = sectionMetadata;
                            while (changed && currentItem.parent) {
                                currentItem = currentItem.parent;
                                changed = plugin.propagate && plugin.propagate(currentItem);
                            }
                            //});
                        }
                        break;
                    case 'section':
                        for (pluginIndex in EntityMenuConfig.plugins) {
                            plugin = EntityMenuConfig.plugins[pluginIndex];

                            plugin.initSectionFlags && plugin.initSectionFlags(
                                sectionMetadata,
                                sectionId,
                                sectionData,
                                stateParams
                            );
                        }
                        break;
                    case 'list':
                        for (pluginIndex in EntityMenuConfig.plugins) {
                            plugin = EntityMenuConfig.plugins[pluginIndex];

                            plugin.initListFlags && plugin.initListFlags(
                                sectionMetadata,
                                sectionId,
                                sectionData,
                                stateParams
                            );
                        }

                        angular.forEach(sectionMetadata.items, function(itemData) {
                            for (pluginIndex in EntityMenuConfig.plugins) {
                                var itemPlugin = EntityMenuConfig.plugins[pluginIndex];

                                itemPlugin.initListItemFlags && itemPlugin.initListItemFlags(
                                    itemData,
                                    itemData.sectionId,
                                    itemData.stateParams
                                );
                            }

                            initMenuStructure(itemData.sections, itemData.stateParams);
                        });
                }
            }
        }

        function onEventIterateStructure(eventName, menuMetadata, statePath, stateParams, extraArguments) {
            for (var sectionId in menuMetadata) {
                var section = menuMetadata[sectionId];
                var sectionState = statePath + '.' + section.components.join('.');

                // Call plugin methods
                var method = 'on' + eventName;
                for (var pluginIndex in EntityMenuConfig.plugins) {
                    var plugin = EntityMenuConfig.plugins[pluginIndex];
                    plugin[method] && plugin[method](
                        section,
                        sectionId,
                        section.type,
                        sectionState,
                        stateParams,
                        extraArguments
                    );
                }

                // Reload metadata for each item in lists
                if (section.type === 'list') {
                    var itemState = sectionState + '.item';
                    var itemStateParams = InetsysTools.simpleCopy(stateParams);

                    var element = CrfService.getElement(sectionId);
                    for (var itemIndex in section.items) {
                        var itemMetadata = section.items[itemIndex];
                        itemStateParams[element.getName()] = itemIndex;

                        // Call plugin methods
                        for (pluginIndex in EntityMenuConfig.plugins) {
                            plugin = EntityMenuConfig.plugins[pluginIndex];
                            plugin[method] && plugin[method](
                                itemMetadata,
                                sectionId,
                                itemMetadata.type,
                                itemState,
                                itemStateParams,
                                extraArguments
                            );
                        }

                        onEventIterateStructure(
                            eventName,
                            section.items[itemIndex].sections,
                            itemState,
                            itemStateParams,
                            extraArguments
                        );
                    }
                }
            }
        }

        function reloadMenuStructure(menuMetadata, baseStructure, stateParams) {
            var pluginIndex, plugin;
            for (var sectionId in menuMetadata) {
                var section = menuMetadata[sectionId];

                for (pluginIndex in EntityMenuConfig.plugins) {
                    plugin = EntityMenuConfig.plugins[pluginIndex];
                    plugin.reloadFlags && plugin.reloadFlags(
                        section,
                        sectionId,
                        section.type,
                        section.state,
                        stateParams
                    );
                }

                // Reload metadata for each item in lists
                if (section.type === 'list') {
                    var listIndices = CrfService.getListIndices(stateParams);
                    var listData = RecordDataService.getList(sectionId, listIndices);

                    var reloadedListItems = [];
                    var itemState = section.state + '.item';

                    var element = CrfService.getElement(sectionId);
                    for (var itemDataIndex in listData) {
                        var itemData = listData[itemDataIndex];
                        var itemId = angular.isUndefined(itemData.__id) ? itemDataIndex : itemData.__id;

                        stateParams[element.getName()] = itemDataIndex;

                        var menuItem = getMenuItem(sectionId, stateParams);

                        if (menuItem) {
                            for (pluginIndex in EntityMenuConfig.plugins) {
                                plugin = EntityMenuConfig.plugins[pluginIndex];

                                if (angular.isFunction(plugin.reloadItemFlags)) {
                                    plugin.reloadItemFlags(menuItem, itemState, stateParams, sectionId);
                                }
                            }

                            reloadMenuStructure(menuItem.sections, baseStructure[sectionId].list, stateParams);
                        } else {
                            var itemUid = section.uid.concat(itemId);
                            // New item - This is executed only if entity has been modified in server side
                            menuItem = initMenuItem({
                                type: 'listItem',
                                element: section.element,
                                id: itemId,
                                label: '',
                                sections: {},
                                // La lista de padres del ítem incluye a la propia lista
                                parents: section.parents.concat(sectionId),
                            }, sectionId, stateParams, itemUid);

                            // Let plugins to modify list item
                            for (pluginIndex in EntityMenuConfig.plugins) {
                                plugin = EntityMenuConfig.plugins[pluginIndex];

                                plugin.initListItemFlags && plugin.initListItemFlags(
                                    menuItem,
                                    sectionId,
                                    stateParams
                                );
                            }
                            completeMenuStructure(menuItem.sections, baseStructure[sectionId].list, stateParams);
                            initMenuStructure(menuItem.sections, stateParams);
                        }

                        reloadedListItems.push(menuItem);

                        delete stateParams[element.getName()];
                    }

                    // TODO Lodash.merge
                    $window._.merge(section.items, reloadedListItems);
                    // InetsysTools.deepMerge(section.items, reloadedListItems);
                }
            }
        }

        function getMenuItem(sectionId, stateParams) {
            var key = getItemKey(sectionId, stateParams);

            return metadataByUid[key];
        }

        function executeNode(eventName, menuItem, extraArguments) {
            // List of arguments to pass to plugin method
            var argumentList = [
                menuItem,
                menuItem.sectionId,
                menuItem.stateParams,
            ].concat(extraArguments);

            // Call plugin method
            var plugin;
            var pluginMethod;

            for (var pluginIndex in EntityMenuConfig.plugins) {
                plugin = EntityMenuConfig.plugins[pluginIndex];
                pluginMethod = plugin['on' + eventName];

                var changed = angular.isFunction(pluginMethod) && pluginMethod(argumentList);

                // Si el plugin cambia el estado del elemento del menú y éste no es un nodo raíz, el cambio se
                // propaga hacia arriba
                var currentItem = menuItem;
                while (changed && currentItem.parent) {
                    currentItem = currentItem.parent;
                    changed = plugin.propagate && plugin.propagate(currentItem);
                }
            }
        }

        return {
            clear: function() {
                __menuMetadata = {};
                __menuItemsParents = {};
                __menuItemsChildren = {};
                metadataByUid = {};
            },
            pause: function() {
                paused = true;
            },
            resume: function() {
                paused = false;
            },
            init: function() {
                completeMenuStructure(__menuMetadata, EntityMenuConfig.structure, {});
                initMenuStructure(__menuMetadata, {});
            },
            getAccess: function() {
                var access = {};

                for (var pluginIndex in EntityMenuConfig.plugins) {
                    var plugin = EntityMenuConfig.plugins[pluginIndex];

                    plugin.buildAccess && plugin.buildAccess(access);
                }

                return access;
            },
            reload: function() {
                reloadMenuStructure(__menuMetadata, EntityMenuConfig.structure, {
                    recordId: $stateParams.recordId,
                });

                return true;
            },
            get: function() {
                return __menuMetadata;
            },
            on: function(eventName) {
                if (paused) return;

                // Get extra arguments
                var extraArguments = [];
                for (var argumentIndex = 1; argumentIndex < arguments.length; argumentIndex++) {
                    extraArguments.push(arguments[argumentIndex]);
                }

                // Iterate menu structure calling event method of each plugin
                onEventIterateStructure(eventName, __menuMetadata, 'record.edit.data', {
                    recordId: $stateParams.recordId,
                }, extraArguments);
            },
            sectionOn: function(eventName, sectionId, stateParams) {
                if (paused) return;

                var menuItem = getMenuItem(sectionId, stateParams);
                if (!menuItem) {
                    return;
                }

                // Discard arguments 1-3
                var extraArguments = Array.prototype.slice.call(arguments, 3);

                return executeNode(eventName, menuItem, extraArguments);
            },
            /**
             * Add item
             *
             * Creates a new menú element and its submenu tree, under a list item.
             *
             * @param {string} listId Unique ID of the list where the new item will be added
             *
             * @return {boolean} TRUE if the item is created
             */
            addItem: function(listId) {
                if (paused) return;

                var listMenuItem = getMenuItem(listId, $stateParams);
                if (!listMenuItem) {
                    return false;
                }

                // New element to be added
                var stateParams = InetsysTools.simpleCopy($stateParams);
                var listIndices = CrfService.getListIndices(stateParams);
                // obtenemos los datos "flatten" que estén bajo los índices de las listas padres si existen
                var levelData = RecordDataService.getData(listIndices);
                // obtenemos los datos concretos del array correspondiente a la lista
                var listData = levelData[listId];

                if (listData.length == 0) {
                    return false;
                }
                var itemData = listData[listData.length - 1];
                stateParams[listMenuItem.element.getName()] = listMenuItem.items.length;

                var itemUid = listMenuItem.uid.concat(itemData.__id);

                var newElement = initMenuItem({
                    type: 'listItem',
                    element: listMenuItem.element,
                    id: itemData.__id,
                    label: '',
                    sections: {},
                    // La lista de padres del ítem incluye a la propia lista
                    parents: listMenuItem.parents.concat(listId),
                }, listId, stateParams, itemUid);

                var key = itemUid.join('.');
                metadataByUid[key] = newElement;

                // Let plugins to modify list item
                for (var pluginIndex in EntityMenuConfig.plugins) {
                    var plugin = EntityMenuConfig.plugins[pluginIndex];

                    plugin.initListItemFlags && plugin.initListItemFlags(newElement, listId, stateParams);
                }

                // Complete item children
                completeMenuStructure(newElement.sections, listMenuItem.list, stateParams);
                initMenuStructure(newElement.sections, stateParams);

                // Add item to menu metadata
                listMenuItem.items.push(newElement);

                // this.reload();

                this.sectionOn.apply(this, ['ListItemCreated', listId, $stateParams, listMenuItem.items.length - 1]);

                return true;
            },
            /**
             * Delete item
             *
             * Remove a menu item and its submenu tree from a specified list
             *
             * @param {string} listId Unique ID of the list where the new item will be added
             * @param {integer} itemIndex Index of the element to be removed
             *
             * @return {boolean} Success
             */
            deleteItem: function(listId, itemIndex) {
                if (paused) return;

                var listMenuItem = getMenuItem(listId, $stateParams);
                if (!listMenuItem) {
                    return false;
                }

                if (listMenuItem.items.length > itemIndex) {
                    // El elemento que se va a borrar
                    var menuItem = listMenuItem.items[itemIndex];

                    listMenuItem.items.splice(itemIndex, 1);
                }

                // Se eliminan del objeto interno los elementos cuya raíz coincide con el ítem eliminado (descendientes)
                var deletedItemKey = menuItem.uid.join('.');
                var keys = Object.keys(metadataByUid);
                for (var index = keys.length - 1; index >= 0; index--) {
                    if (keys[index].indexOf(deletedItemKey) === 0) {
                        delete metadataByUid[keys[index]];
                    }
                }

                this.sectionOn.apply(this, ['ListItemDeleted', listId, $stateParams, itemIndex]);

                return true;
            },
        };
    },
]);
