angular.module('app.entity.actions').component('recordActions', {
    templateUrl: 'modules/ecrd/components/actions/components/record-actions/template.html',
    bindings: {},
    controller: [
        '$injector',
        'RecordValidationService',
        'RecordMetadataService',
        'CrdConfigurationService',
        'StudyContext',
        'ViewMode',
        'ViewModes',
        'Event',
        'gettextCatalog',
        'InetsysMessages',
        'Modal',
        'ErrorMessages',
        '$exceptionHandler',
        'AccountManager',
        function(
            $injector,
            RecordValidationService,
            RecordMetadataService,
            CrdConfigurationService,
            StudyContext,
            ViewMode,
            ViewModes,
            Event,
            gettextCatalog,
            InetsysMessages,
            Modal,
            ErrorMessages,
            $exceptionHandler,
            AccountManager
        ) {
            var vm = this;

            var RecordStatesService = $injector.has('RecordStatesService')
                ? $injector.get('RecordStatesService')
                : null;

            var FormRevisionEditorService = $injector.has('FormRevisionEditorService')
                ? $injector.get('FormRevisionEditorService')
                : null;

            var eventListeners = [];
            var auditEnabled = CrdConfigurationService.hasAudit() && StudyContext.canAccessEntityAudit();

            // La etiqueta para colocar el texto en el título
            vm.recordLabel = CrdConfigurationService.getLabelSingular();

            // Los estados disponibles
            vm.availableStates = RecordStatesService ? RecordStatesService.getAvailableStates() : {};

            // Si tiene la capacidad de actualizar los flags de estados (poner o quitar), se habilita el botón
            vm.editableStates = RecordStatesService ? RecordStatesService.getEditableStates() : {};

            vm.activeStates = getEnabledStates();
            vm.globalMetadata = {};
            vm.isLocked = !!RecordStatesService && RecordStatesService.isLocked();
            vm.auditableStates = getAuditableStates();
            vm.hasAuditableStates = Object.keys(vm.auditableStates).length > 0;

            // Si está abierto el panel de acciones sobre estados
            vm.openActions = false;

            // Si tiene la capacidad de actualizar el flag de bloqueo (poner o quitar), se habilita el botón
            vm.hasEditableLock = !!RecordStatesService && RecordStatesService.hasEditableLock();

            // Los estados editables dependientes del estado actual
            vm.editionInfo = {};

            // Indexado por ID del estado
            vm.cannotUpdateReason = {};

            // Revisión
            vm.revisionModeEnabled = false;
            vm.canReviseFields = false;
            vm.revisionStates = [];

            vm.isAvailable = false;

            vm.$onInit = function() {
                setUpListeners();
                setEditableStates();

                vm.reloadGlobalMetadata();

                vm.revisionModeEnabled = ViewMode.get() === ViewModes.REVISION;

                vm.revisionStates = CrdConfigurationService.getRevisionStates().getStates().filter(function(state) {
                    return AccountManager.hasPermission('set revision state ' + state.getId());
                });

                vm.canReviseFields = vm.revisionStates.length > 0;

                // Si hay estados habilitados en el cuaderno
                vm.isAvailable = (!!RecordStatesService && RecordStatesService.hasAnyEditableAction())
                    || vm.canReviseFields;
            };

            vm.$onDestroy = function() {
                tearDownListeners();
            };

            vm.reloadGlobalMetadata = function() {
                // Clean previous metadata
                for (var key in vm.globalMetadata) {
                    delete vm.globalMetadata[key];
                }

                // Build state keys list
                var availableStates = [];
                if (RecordStatesService) {
                    availableStates = [].concat(
                        !!RecordStatesService && RecordStatesService.hasLock() ? ['locked'] : [],
                        Object.keys(RecordStatesService.getAvailableStates())
                    );
                }

                // Get metadata
                for (var index in availableStates) {
                    var stateKey = availableStates[index];
                    var globalState = RecordMetadataService.getGlobalState(stateKey);
                    if (globalState) {
                        vm.globalMetadata[stateKey] = globalState;
                    }
                }
            };

            vm.toggleActions = function() {
                vm.openActions = !vm.openActions;
            };

            vm.toggleLock = function() {
                if (!RecordStatesService) {
                    return;
                }

                return RecordStatesService.toggleLock()
                    .then(function(result) {
                        if (result === 'cancel') {
                            return;
                        }

                        vm.isLocked = RecordStatesService.isLocked();

                        // Al haber cambiado el valor de bloqueo puede cambiar la disponibilidad de los botones
                        setEditableStates();

                        var successTitle;

                        if (vm.isLocked) {
                            successTitle = gettextCatalog.getString('Se ha bloqueado la entidad');
                        } else {
                            successTitle = gettextCatalog.getString('Se ha liberado el bloqueo sobre la entidad');
                        }

                        // Success message
                        InetsysMessages.success({
                            title: successTitle,
                        });
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            InetsysMessages.error({
                                mode: 'modal',
                                title: RecordStatesService.isLocked()
                                    ? gettextCatalog.getString('No se ha podido liberar el bloqueo sobre la entidad')
                                    : gettextCatalog.getString('No se ha podido bloquear la entidad'),
                                body: ErrorMessages.get(error),
                            });
                        }

                        return $exceptionHandler(error, 'toggle entity lock');
                    })
                    .finally(function() {
                        // Close actions popup
                        vm.openActions = false;

                        vm.reloadGlobalMetadata();
                    });
            };

            vm.toggleState = function(stateId) {
                if (!RecordStatesService) {
                    return;
                }

                return RecordStatesService.toggleState(stateId)
                    .then(function(result) {
                        if (result === 'cancel') {
                            return;
                        }

                        // Se recarga la información de estados global
                        vm.activeStates = getEnabledStates();
                        vm.auditableStates = getAuditableStates();
                        vm.hasAuditableStates = Object.keys(vm.auditableStates).length > 0;

                        var titleParams = {
                            name: vm.availableStates[stateId].name,
                        };

                        // Success message
                        InetsysMessages.success({
                            title: vm.activeStates[stateId]
                                ? gettextCatalog.getString('Se ha activado el estado {{name}}', titleParams)
                                : gettextCatalog.getString('Se ha anulado el estado {{name}}', titleParams),
                        });
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            var titleParams = {
                                name: vm.availableStates[stateId].name,
                            };
                            InetsysMessages.error({
                                mode: 'modal',
                                title: vm.activeStates[stateId]
                                    ? gettextCatalog.getString('No se ha podido anular el estado {{name}}', titleParams)
                                    : gettextCatalog.getString('No se ha podido activar el estado {{name}}', titleParams),
                                body: ErrorMessages.get(error),
                            });
                        }

                        return $exceptionHandler(error, 'toggle entity state');
                    })
                    .finally(function() {
                        // Close actions popup
                        vm.openActions = false;

                        vm.reloadGlobalMetadata();
                    });
            };

            vm.viewAuditChanges = function(stateId) {
                if (!RecordStatesService) {
                    return;
                }

                return RecordStatesService.getAuditChanges(stateId)
                    .then(function(response) {
                        vm.openActions = false;

                        return Modal.open({
                            template: 'modules/states/components/record-states/modal-audit-changes.html',
                            data: {
                                state: CrdConfigurationService.getStates().getState(stateId),
                                changes: response.data,
                            },
                        }).closePromise;
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            InetsysMessages.error({
                                mode: 'modal',
                                title: gettextCatalog.getString('No se ha podido obtener audit del estado {{name}}', {
                                    name: vm.availableStates[stateId].name,
                                }),
                                body: ErrorMessages.get(error),
                            });
                        }

                        return $exceptionHandler(error, 'view entity audit changes');
                    });
            };

            vm.setRevisionView = function(stateId) {
                if (!FormRevisionEditorService) {
                    return;
                }

                return Event.broadcast('setRevisionViewActionStart', [stateId])
                    .then(function(event) {
                        if (event.defaultPrevented) {
                            return;
                        }

                        FormRevisionEditorService.init(stateId);
                        ViewMode.setRevisionMode();
                        vm.revisionModeEnabled = true;

                        vm.openActions = false;
                    });
            };

            function setUpListeners() {
                eventListeners.push(RecordValidationService.events.on('validateRecord', setEditableStates));

                // De manera general, al recargar los metadatos se actualiza la información
                eventListeners.push(RecordMetadataService.events.on('load', function() {
                    setEditableStates();
                    vm.isLocked = !!RecordStatesService && RecordStatesService.isLocked();
                    vm.activeStates = getEnabledStates();
                    vm.auditableStates = getAuditableStates();
                    vm.hasAuditableStates = Object.keys(vm.auditableStates).length > 0;

                    // GARU-4167 Los iconos de cabecera necesitan actualizar la información del metadata global cada vez
                    // que se modifica fuera de este controlador
                    vm.reloadGlobalMetadata();
                }));

                if (CrdConfigurationService.hasRevisionStates()) {
                    eventListeners.push(
                        Event.on('viewModeChanged', function(event, mode) {
                            vm.revisionModeEnabled = mode === ViewModes.REVISION;
                        })
                    );
                }
            }

            function tearDownListeners() {
                angular.forEach(eventListeners, function(deregister) {
                    deregister();
                });
            }

            function getEnabledStates() {
                return RecordStatesService ? RecordStatesService.getEnabledStates() : {};
            }

            function getDisabledStates() {
                return RecordStatesService ? RecordStatesService.getDisabledStates() : {};
            }

            function setEditableStates() {
                if (RecordStatesService) {
                    vm.editionInfo = RecordStatesService.getEditionInfo();

                    angular.forEach(vm.editionInfo, function(data, stateId) {
                        vm.cannotUpdateReason[stateId] = translateReason(data.reason);
                    });
                }
            }

            function getAuditableStates() {
                return auditEnabled ? angular.merge(getEnabledStates(), getDisabledStates()) : {};
            }

            function translateReason(reason) {
                if (reason === 'validation') {
                    return gettextCatalog.getString('Se deben corregir los errores para ejecutar esta acción');
                }

                if (reason === 'lock') {
                    return gettextCatalog.getString('{{entity}} en estado bloqueado de forma global', {
                        entity: vm.recordLabel,
                    });
                }

                if (reason === 'permission') {
                    return gettextCatalog.getString('Su perfil de acceso no cuenta con permisos para ejecutar esta acción');
                }

                return reason;
            }
        },
    ],
});
