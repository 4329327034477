angular.module('ecrd')
    .controller('MultipleCheckboxController', function() {
        var vm = this;

        vm.onChange = function(dataModel, fieldId, availableOptions) {
            var fieldValue = dataModel[fieldId] || [];

            if (!fieldValue.length) {
                return;
            }

            var validOptions = availableOptions.map(function(option) {
                return option.value;
            });

            var cleanOptions = [];
            for (var i = 0; i < fieldValue.length; i++) {
                var optionValue = fieldValue[i];
                if (validOptions.indexOf(optionValue) >= 0) {
                    cleanOptions.push(optionValue);
                }
            }

            dataModel[fieldId] = cleanOptions;
        };
    });
