'use strict';

angular.module('ecrd')
    .filter('elementLabel', [function() {
        // Source: http://stackoverflow.com/a/22535765
        function stripTags(input, allowed) {
            if (!input) return input;
            allowed = (((allowed || '') + '')
                .toLowerCase()
                .match(/<[a-z][a-z0-9]*>/g) || [])
                .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

            return input.replace(commentsAndPhpTags, '')
                .replace(tags, function(match0, match1) {
                    return allowed.indexOf('<' + match1.toLowerCase() + '>') > -1 ? match0 : '';
                });
        }

        return function(fieldLabel) {
            var label = fieldLabel;
            if (angular.isArray(fieldLabel)) {
                label = fieldLabel.join(' ⇢ ');
            }

            return stripTags(label, '<sup><sub>');
        };
    }]);
