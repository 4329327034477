'use strict';

angular.module('ecrd')
.factory('CrdConfigurationService', ['CoreConfiguration', function CrdConfigurationService(CoreConfiguration) {
    var publicAPI = {};
    // 1. Custom functions

    // 2. Inherited public
    Object.getOwnPropertyNames(Object.getPrototypeOf(CoreConfiguration)).filter(function(key) {
        if (typeof CoreConfiguration[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
            if (typeof publicAPI[key] != 'undefined') {
                throw new Error("Function "+key+" is present in Core object");
            }
            publicAPI[key] = CoreConfiguration[key].bind(CoreConfiguration);
        }
    });

    return publicAPI;
}]);
