angular.module('ecrd').factory('EncryptedFieldsVisibility', [
    'RecordDataService',
    'Event',
    'AccountManager',
    'CrfService',
    'RecordRulesService',
    'UNKNOWN_VALUE',
    function(RecordDataService, Event, AccountManager, CrfService, RecordRulesService, UNKNOWN_VALUE) {
        var fieldsInfo = {};

        var clearValueListener = null;
        var automaticRuleListener = null;

        var EncryptedFieldsVisibility = {
            clear: function() {
                fieldsInfo = {};

                if (angular.isFunction(clearValueListener)) {
                    clearValueListener();
                    clearValueListener = null;
                }

                if (angular.isFunction(automaticRuleListener)) {
                    automaticRuleListener();
                    automaticRuleListener = null;
                }
            },
            init: function() {
                EncryptedFieldsVisibility.clear();

                return AccountManager.init().then( function() {
                    if (AccountManager.hasPermission('view encrypted fields')) {
                        // Evento al limpiar un campo desde el menú de acciones
                        clearValueListener = Event.on('updatedRecordFieldValue', function(
                            event,
                            recordData,
                            fieldId,
                            payload
                        ) {
                            var field = CrfService.getField(fieldId);
                            if (!payload.afterValue && field.isEncrypted()) {
                                EncryptedFieldsVisibility.set(fieldId, payload.listIndices);
                            }
                        });

                        // Evento al limpiar un campo por una regla automática
                        automaticRuleListener = RecordRulesService.addEventListener('onSetValueChanged', function(
                            ruleAction,
                            listIndices
                        ) {
                            var fieldId = ruleAction.definition.target;
                            var field = CrfService.getField(fieldId);
                            if (!ruleAction.definition.value && field.isEncrypted()) {
                                EncryptedFieldsVisibility.set(fieldId, listIndices);
                            }
                        });
                    }
                });
            },
            set: function(fieldId, listIndices) {
                var uid = RecordDataService.getElementUID(fieldId, listIndices);
                fieldsInfo[uid.join('.')] = true;
                Event.trigger('encryptedFieldVisible', uid);
            },
            get: function(fieldId, listIndices) {
                var uid = RecordDataService.getElementUID(fieldId, listIndices);

                return !!fieldsInfo[uid.join('.')];
            },
            isEncryptable: function(value) {
                return typeof value !== 'undefined' // eslint-disable-line angular/definedundefined
                    && !Number.isNaN(value)
                    && value !== null
                    && value !== ''
                    && value !== false
                    && value !== UNKNOWN_VALUE
                    && (!Array.isArray(value) || value.length > 0) // eslint-disable-line angular/typecheck-array
                    && !value.encrypted
                ;
            },
        };

        return EncryptedFieldsVisibility;
    },
]);