'use strict';

angular.module('error')
    .controller('HelpInvalidErrorController', [
        'CrdConfigurationService',
        'RecordDataService',
        'RecordValidationService',
        'CrfService',
        '$state',
        'FormFieldsService',
        function(
            CrdConfigurationService,
            RecordDataService,
            RecordValidationService,
            CrfService,
            $state,
            FormFieldsService
        ) {
            var vm = this;

            vm.entitySingular = CrdConfigurationService.getLabelSingular();
            vm.hasUnavailableFields = false;

            var getStateName = function(fieldUId) {
                var state = 'record.edit.data';
                var element = CrfService.getElementByUID(fieldUId);
                var form = element.getForm();
                var lists = element.getParentLists();
                angular.forEach(lists, function(list) {
                    state = state + '.' + list.getPathFromList().join('.') + '.item';
                });

                return state + '.' + form.getPathFromList().join('.');
            };

            vm.invalidFields = RecordValidationService.getInvalidFields().reduce(function(acc, fieldUid) {
                var fieldId = parseInt(fieldUid[fieldUid.length - 1], 10);
                if (!CrfService.isElementAvailableToCurrentProfile(fieldId)) {
                    vm.hasUnavailableFields = true;

                    return acc;
                }

                var listIndices = RecordDataService.getListIndicesByUID(fieldUid);
                var stateParams = CrfService.getNamedListIndices(listIndices);

                return acc.concat({
                    uid: fieldUid,
                    label: RecordDataService.getElementFullLabeledPathByUID(fieldUid),
                    stateHref: $state.href(getStateName(fieldUid), stateParams),
                    hidden: !FormFieldsService.isElementVisible(fieldId, listIndices),
                });
            }, []);
        },
    ]);
