angular.module('ecrd.records').factory('RecordsManagerService', [
    '$window',
    '$filter',
    'ShareCrfSDK',
    'LoadingService',
    'gettextCatalog',
    'CoreRecord',
    'UNKNOWN_VALUE',
    'UnknownService',
    function RecordsManagerService(
        $window,
        $filter,
        ShareCrfSDK,
        LoadingService,
        gettextCatalog,
        CoreRecord,
        UNKNOWN_VALUE,
        UnknownService
    ) {
        var ecrdSDK = ShareCrfSDK.ecrd;
        var randomizationSDK = ShareCrfSDK.randomization;

        function _slugify(input) {
            // make lower case and trim
            var slug = input.toLowerCase().trim();
            // replace invalid chars with spaces
            slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
            // replace multiple spaces or hyphens with a single hyphen
            slug = slug.replace(/[\s-]+/g, '-');

            return slug;
        }
        function _getRowData(data, attribute) {
            if (angular.isArray(attribute)) {
                // el valor de la celda se construye a partir de más de un dato
                return attribute.map(function(attr) {
                    return $window._.get(data, attr);
                });
            }

            return $window._.get(data, attribute);
        }

        function _wrapRequest(request) {
            return LoadingService.addPromise(request);
        }

        function createQuery(sdkParameters) {
            return _wrapRequest(ecrdSDK.createQuery(sdkParameters));
        }

        function getAuditLog(recordId) {
            return _wrapRequest(ecrdSDK.getRecordAuditLog(recordId));
        }

        function getQueries(recordId, globalLoading) {
            return globalLoading === false
                ? ecrdSDK.getRecordQueriesList(recordId)
                : _wrapRequest(ecrdSDK.getRecordQueriesList(recordId));
        }

        function getRecord(params, globalLoading) {
            return globalLoading === false
                ? ecrdSDK.getRecord(params)
                : _wrapRequest(ecrdSDK.getRecord(params));
        }

        function listRecords(sdkParameters) {
            return ecrdSDK.getRecordsList(sdkParameters);
        }

        function listRecordsURL(sdkParameters) {
            return ecrdSDK.getRecordsListURL(sdkParameters);
        }

        function replyQuery(sdkParameters) {
            return _wrapRequest(ecrdSDK.replyQuery(sdkParameters));
        }
        function closeQuery(sdkParameters) {
            return _wrapRequest(ecrdSDK.closeQuery(sdkParameters));
        }

        function setFormLock(sdkParameters) {
            if (!sdkParameters.recordId) {
                sdkParameters.recordId = CoreRecord.getId();
            }

            return _wrapRequest(ecrdSDK.setFormLock(sdkParameters));
        }

        function setFormState(sdkParameters) {
            if (!sdkParameters.recordId) {
                sdkParameters.recordId = CoreRecord.getId();
            }

            return _wrapRequest(ecrdSDK.setFormState(sdkParameters));
        }

        function setGlobalState(sdkParameters) {
            if (!sdkParameters.recordId) {
                sdkParameters.recordId = CoreRecord.getId();
            }

            return _wrapRequest(ecrdSDK.setGlobalState(sdkParameters));
        }

        function setGlobalLock(sdkParameters) {
            if (!sdkParameters.recordId) {
                sdkParameters.recordId = CoreRecord.getId();
            }

            return _wrapRequest(ecrdSDK.setGlobalLock(sdkParameters));
        }

        function getAuditChanges(sdkParameters) {
            if (!sdkParameters.recordId) {
                sdkParameters.recordId = CoreRecord.getId();
            }

            return _wrapRequest(ecrdSDK.getAuditChanges(sdkParameters));
        }

        function randomize(recordId) {
            return _wrapRequest(ecrdSDK.randomize(recordId));
        }
        function unblind(recordId) {
            return _wrapRequest(ecrdSDK.unblind(recordId));
        }
        function getRandomizationStockSummary(sdkParameters) {
            return _wrapRequest(randomizationSDK.getStockSummary(sdkParameters));
        }
        function getRandomizationStock(params) {
            return randomizationSDK.getStockList(params);
        }
        function updateRandomizationStock(itemId, sdkParameters) {
            return _wrapRequest(randomizationSDK.updateStockItem(itemId, sdkParameters));
        }
        function getRandomizationStockLog(sdkParameters) {
            return randomizationSDK.getStockLog(sdkParameters);
        }
        function getRevelations(sdkParameters) {
            return _wrapRequest(randomizationSDK.getRevelations(sdkParameters));
        }

        function downloadExcel(sdkParameters, columns, title) {
            sdkParameters.limit = null; // download all
            sdkParameters.offset = 0;

            var unknownConfig = UnknownService.getConfig();

            return ecrdSDK.getRecordsList(sdkParameters)
                .then(function(result) {
                    var XLSX = $window.XLSX;
                    var workbook = XLSX.utils.book_new();

                    var col, cellRef, row;

                    var columnWidth = [];
                    var headers = columns.map(function(column, index) {
                        columnWidth[index] = Math.max(columnWidth[index] || 0, column.header.length);

                        return column.header;
                    });
                    var worksheet = XLSX.utils.aoa_to_sheet([headers]);
                    for (col = 0; col < headers.length; col++) {
                        cellRef = XLSX.utils.encode_cell({  /* eslint-disable id-length */
                            c: col,
                            r: 0,
                        });
                        if (worksheet[cellRef]) {
                            worksheet[cellRef].z = '@';
                        }
                    }

                    var data;
                    angular.forEach(result.data, function(dataRow) {
                        data = [];
                        angular.forEach(columns, function(column, indexCol) {
                            var value = _getRowData(dataRow, column.id);
                            if (value === UNKNOWN_VALUE) {
                                value = unknownConfig.label;
                            }
                            else if (angular.isFunction(column.formatter)) {
                                value = column.formatter(value);
                            }
                            data.push(value);
                            var length = value ? String(value).length : 1;
                            columnWidth[indexCol] = Math.max(columnWidth[indexCol] || 0, length);
                        });
                        XLSX.utils.sheet_add_aoa(worksheet, [data], { origin: -1 });
                    });
                    var range = XLSX.utils.decode_range(worksheet['!ref']);
                    for (col = 0; col < headers.length; col++) {
                        if (columns[col].type === 'string' || columns[col].type === 'boolean' || columns[col].type === 'date') {
                            for (row = 1; row <= range.e.r; row++) {
                                cellRef = XLSX.utils.encode_cell({ /* eslint-disable id-length */
                                    c: col,
                                    r: row,
                                });
                                if (worksheet[cellRef]) {
                                    worksheet[cellRef].t = 's';
                                    worksheet[cellRef].z = '@';
                                }
                            }
                        } else if (columns[col].type === 'percentage') {
                            for (row = 1; row <= range.e.r; row++) {
                                cellRef = XLSX.utils.encode_cell({ /* eslint-disable id-length */
                                    c: col,
                                    r: row,
                                });
                                if (worksheet[cellRef]) {
                                    worksheet[cellRef].t = 'n';
                                    worksheet[cellRef].z = '0.00%';
                                }
                            }
                        }
                    }

                    worksheet['!cols'] = columnWidth.map(function(width) {
                        return { wch: Math.min(width ? width : 0, 40) };
                    });
                    XLSX.utils.book_append_sheet(workbook, worksheet, title);
                    XLSX.writeFile(workbook, _slugify(title) + '.xlsx');
                });
        }

        // Formateadores de valores para Excel
        var excelFormatter = {};
        excelFormatter.boolean = function() {
            var textoTrue = gettextCatalog.getString('Sí');
            var textoFalse = gettextCatalog.getString('No');

            return function(value) {
                return value ? textoTrue : textoFalse;
            };
        };
        excelFormatter.date = function(format) {
            return function(value) {
                return value ? $filter('dateFormat')(value, format || 'fielddate') : null;
            };
        };
        excelFormatter.datetime = function() {
            return function(value) {
                return value ? $filter('dateFormat')(value, 'timelong') : null;
            };
        };
        excelFormatter.fieldValue = function(fieldId) {
            return function(value) {
                var crf = CoreRecord.getData().getCRF();
                var field = crf.getField(fieldId);

                if (field !== null && field.getFormControl() === 'datepicker') {
                    var format = field.getDefaultFormat();
                    return $filter('dateFormat')(value, format);
                }

                return $filter('fieldSource')(value, fieldId);
            };
        };
        excelFormatter.metadataStateActive = function() {
            return function(metadata) {
                return excelFormatter.boolean()(metadata && metadata.value);
            };
        };

        function bulkList(sdkParameters) {
            return ecrdSDK.getRecordsBulkList(sdkParameters);
        }

        function bulkCertcopy(sdkParameters) {
            return ecrdSDK.getRecordsBulkCertcopies(sdkParameters);
        }

        function getRevision(recordId, globalLoading) {
            var promise = ShareCrfSDK.revision.listRevisionStatus({ id: recordId });

            return globalLoading === false ? promise : _wrapRequest(promise);
        }

        return {
            createQuery: createQuery,
            getAuditLog: getAuditLog,
            getQueries: getQueries,
            getRecord: getRecord,
            list: listRecords,
            listURL: listRecordsURL,
            replyQuery: replyQuery,
            closeQuery: closeQuery,
            setFormLock: setFormLock,
            setFormState: setFormState,
            setGlobalState: setGlobalState,
            setGlobalLock: setGlobalLock,
            randomize: randomize,
            unblind: unblind,
            getRandomizationStockSummary: getRandomizationStockSummary,
            getRandomizationStock: getRandomizationStock,
            updateRandomizationStock: updateRandomizationStock,
            getRandomizationStockLog: getRandomizationStockLog,
            getRevelations: getRevelations,
            downloadExcel: downloadExcel,
            excelFormatter: excelFormatter,
            getAuditChanges: getAuditChanges,
            bulkList: bulkList,
            bulkCertcopy: bulkCertcopy,
            getRevision: getRevision,
        };
    },
]);
