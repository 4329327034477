'use strict';

angular.module('user')
    .provider('LogoutManager', function() {
        var provider = this;

        this.onLogout = ['$window', function($window) {
            $window.location = '/' + $window.ShareCRF.environment;
        }];

        this.$get = ['$injector', function($injector) {
            return {
                onLogout: function() {
                    return $injector.invoke(provider.onLogout);
                },
            };
        }];
    });
