/* eslint-disable angular/component-limit */
angular
    .module('app.entity.common', ['inetsys.modal',])
    .constant('EntityMenuStatus', {
        CLEAN: 0,
        MODIFIED: 1,
        IN_PROCESS: 2,
        COMPLETED: 3,
    })
    .constant('EntityFormStatus', {
        CLEAN: 0,
        IN_PROCESS: 1,
        COMPLETED: 2
    })
    .constant('EntityNotesStatus', {
        NONE: 0,
        OPEN_NOTES: 1,
        CLOSED_NOTES: 2
    })
    /**
     * Filtro: labelValue
     * Formatea el valor acorde a un diccionario [{value: XXX, label: 'XXX'}, ... ]
     *
     * @param  {string}  value    El valor numérico tal y como viene de entity
     * @param  {object}  options  Diccionario de traducción
     * @return {string}           Valor formateado
     */
    .filter('labelValue', function() {
        return function(value, options) {
            for (var optionIndex in options) {
                var option = options[optionIndex];
                if (typeof option.value !== 'undefined' && option.value == value) {
                    return typeof option.label !== 'undefined' ? option.label : value;
                }
            }

            return value;
        };
    })
    /**
     * Filtro: fieldSource
     * Formatea el valor legible de un campo según su definición.
     *
     * @param  {string}  value     El valor numérico tal y como viene de entity
     * @param  {object}  fieldName El nombre del campo
     * @return {string}            Valor formateado
     */
    .filter('fieldSource', ['CrfService', 'gettextCatalog', function labelValueFilterDefinition(CrfService, gettextCatalog) {
        return function(value, fieldId) {
            var field = CrfService.getElement(fieldId);

            // GARU-4325
            if (field.getFormControl() === 'checkbox') {
                return value
                    ? gettextCatalog.getString('Marcado')
                    : gettextCatalog.getString('Desmarcado');
            }

            return field.getReadableValue(value);
        };
    }])
    /**
     * Filtro: nl2br
     * Reescribe los saltos de línea en HTML como <br>
     * Se usa en los detalles de las notas de discrepancia, por ejemplo
     *
     * @param  {string} input  Cadena con saltos de línea
     * @return {string}        Texto en HTML
     */
    .filter('nl2br', function() {
        return function(input) {
            return !input ? input : input.split('\n').join('<br>');
        };
    });
