'use strict';

angular.module('study.placeholders').controller('PlaceholderEntityFieldValueController', [
    '$scope',
    '$filter',
    'CoreRecordData',
    'RecordDataService',
    'RecordValidationService',
    'CrfService',
    '$stateParams',
    'gettextCatalog',
    function(
        $scope,
        $filter,
        CoreRecordData,
        RecordDataService,
        RecordValidationService,
        CrfService,
        $stateParams,
        gettextCatalog
    ) {
        var vm = this;

        var stateParams = $scope.stateParams || {};
        var fieldName = $scope.fieldName;

        var form = null;
        var field = null;

        // El path del campo tiene la forma [nombre del formulario].[nombre del campo]
        var fieldParts = fieldName.split('.');
        if (fieldParts.length < 2) {
            return;
        }

        var allForms = CrfService.getForms();
        for (var index in allForms) {
            if (allForms[index].getName() === fieldParts[0]) {
                form = allForms[index];
                break;
            }
        }

        if (form) {
            field = form.getElementByName(fieldParts[1]);
        }

        var fieldStateParams = {};

        for (var listId in stateParams) {
            var paramValue = stateParams[listId];
            var targetListId = listId;

            if (angular.isString(paramValue)) {
                if (paramValue.indexOf('@') > -1) {
                    var parts = paramValue.split('@');
                    paramValue = parts[0];
                    targetListId = parts[1];
                }

                switch (paramValue) {
                    case 'current':
                        fieldStateParams[listId] = angular.isDefined($stateParams[targetListId])
                            ? parseInt($stateParams[targetListId])
                            : undefined;
                        break;

                    case 'previous':
                        fieldStateParams[listId] = angular.isDefined($stateParams[targetListId])
                            ? parseInt($stateParams[targetListId]) - 1
                            : undefined;
                        break;

                    case 'next':
                        fieldStateParams[listId] = angular.isDefined($stateParams[targetListId])
                            ? parseInt($stateParams[targetListId]) + 1
                            : undefined;
                        break;
                }
            } else {
                fieldStateParams[targetListId] = paramValue;
            }
        }

        var listIndices = CrfService.getListIndices(fieldStateParams);

        vm.valid = true;

        // GARU-4616 Como los campos de fichero tienen una representación especial, se usan las propiedades originales
        // para usar una plantilla específica
        Object.defineProperty(vm, 'value', {
            get: function() {
                return field ? RecordDataService.getFieldValue(field.getId(), listIndices) : undefined;
            },
        });
        vm.formControl = field ? field.getFormControl() : null;

        /**
         * Devuelve el valor representable de una variable. Por ejemplo, en el caso de selects, transforma el número
         * en el label del option
         *
         * @return {string} Valor representable para el placeholder
         */
        function getFormattedValue() {
            if (!field) {
                return '';
            }
            var value = RecordDataService.getFieldValue(field.getId(), listIndices);
            var readableValue = '';
            var option = null;

            switch (vm.formControl) {
                case 'radio':
                case 'select':
                    option = field.getOptions().getOptionByValue(value);

                    if (option !== null) {
                        readableValue = option.label;
                    }
                    break;

                case 'datepicker':
                case 'datecomponents':
                    readableValue = $filter('dateFormat')(value, 'fielddate');
                    break;

                case 'datetimepicker':
                    readableValue = $filter('dateFormat')(value, field.getFormat());
                    break;

                case 'checkbox':
                    readableValue = value
                        ? gettextCatalog.getString('Marcado')
                        : gettextCatalog.getString('Desmarcado');
                    break;

                case 'multiple_checkbox':
                    option = field.getOptions().getOptionByValue(value) || [];

                    readableValue = '' + option.map(function(item) {
                        return item.label;
                    }).join(', ');
                    break;

                default:
                    readableValue = angular.isUndefined(value) || value === null ? '' : '' + value;
            }

            return readableValue;
        }

        vm.print = function() {
            var readableValue = '';

            if (field && CoreRecordData.getElementPath(field.getId(), listIndices)) {
                vm.valid = RecordValidationService.validateField(field.getId(), listIndices);
                readableValue = !vm.valid && ['radio', 'select'].indexOf(vm.formControl) > -1
                    ? gettextCatalog.getString('Valor inválido')
                    : getFormattedValue(field, listIndices);
            }

            return readableValue;
        };
    }]);
