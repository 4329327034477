'use strict';

angular.module('ecrd.core')
    .provider('CoreRules', function() {
        var provider = this;

        provider.$get = ['CoreEcrd', function(CoreEcrd) {
            return CoreEcrd.getRules();
        }];
    });
