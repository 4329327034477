angular.module('app.entity.common')
    .controller('EntityModalConfirmChanges', ['$scope', function($scope){
        // TODO más bien eliminar cómo funciona este módulo
        $scope.isAuthenticated = true;

        $scope.confirmChangesAndExit = function(){
            $scope.closeThisDialog(true);
        };
        $scope.cancelChangesAndExit = function(){
            $scope.closeThisDialog(false);
        };
        $scope.continueEditing = function(){
            $scope.dismissThisDialog();
        };
    }]);
