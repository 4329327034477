'use strict';

angular.module('export')
    .controller('ExportListController', [
        '$scope',
        'ShareCrfSDK',
        'ShareCrfApiErrorCodes',
        'Event',
        'gettext',
        function($scope, ShareCrfSDK, ShareCrfApiErrorCodes, Event, gettext) {
            var vm = this;

            vm.ready = false;
            vm.loading = false;

            vm.items = [];
            vm.totalItems = 0;
            vm.limit = 5;
            vm.orderBy = 'createdAt';
            vm.orderReverse = true;
            vm.currentPage = 0;

            vm.$onInit = function() {
                vm.ready = true;

                return vm.load();
            };

            vm.load = function() {
                if (vm.loading) return;

                vm.loading = true;
                vm.error = false;

                var parameters = {
                    order: vm.orderBy + ' ' + (vm.orderReverse ? 'desc' : 'asc'),
                    limit: vm.limit,
                    offset: vm.limit * vm.currentPage,
                };

                return ShareCrfSDK.exports.list(parameters)
                    .then(function(response) {
                        vm.items = response.rows;
                        vm.totalItems = response.count;
                        vm.error = false;
                    })
                    .catch(function(error) {
                        vm.items = [];
                        vm.totalItems = 0;
                        vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);
                    })
                    .finally(function() {
                        vm.loading = false;
                    });
            };

            vm.getUrl = function(data) {
                return ShareCrfSDK.exports.getURL(data);
            };

            vm.describeParameters = function(params) {
                var description = [];
                switch (params.format) {
                    case 'csv':
                        description.push(gettext('Formato: CSV'));
                        break;
                    case 'spss':
                        description.push(gettext('Formato: SPSS'));
                        break;
                    case 'sas':
                        description.push(gettext('Formato: SAS'));
                        break;
                    case 'excel':
                        description.push(gettext('Formato: Excel'));
                        break;
                }
                if (params.sites && params.sites.length) {
                    description.push(gettext('Contiene algún filtro de centro'));
                }
                switch (params.delimiter) {
                    case 'COMMA':
                        description.push(gettext('Delimitador: coma'));
                        break;
                    case 'SEMICOLON':
                        description.push(gettext('Delimitador: punto y coma'));
                        break;
                }
                switch (params.quote) {
                    case 'DQUOTE':
                        description.push(gettext('Carácter de escape: comillas dobles'));
                        break;
                    case 'SQUOTE':
                        description.push(gettext('Carácter de escape: comillas simples'));
                        break;
                }
                if (params.valueLabels) {
                    description.push(gettext('Exportar etiquetas'));
                }

                return description;
            };

            /**
             * Traduce el código interno de estado de una exportación en un texto legible
             *
             * @param  {object} item Datos de la exportación
             *
             * @return {string}      Estado legible
             */
            vm.getStatus = function(item) {
                switch (item.status) {
                    case 'idle':
                        return gettext('Pendiente');
                    case 'running':
                        return gettext('En ejecución');
                    case 'finished':
                        return gettext('Completada');
                    case 'error':
                        return gettext('Error');
                    default:
                        return item.status;
                }
            };

            var reloadList = function reloadList() {
                vm.currentPage = 0;
                vm.load();
            };

            Event.on('exportCreated', reloadList);
            Event.on('exportFinished', reloadList);
            Event.on('exportError', reloadList);
            $scope.$on('$destroy', function() {
                Event.remove('exportCreated', reloadList);
                Event.remove('exportFinished', reloadList);
                Event.remove('exportError', reloadList);
                reloadList = null;
            });
        },
    ]);
