angular.module('ecrd')
    .filter('uidToLabel', ['CoreCRF', function(CoreCRF) {
        return function(uid, separator) {
            var resolvedPath = [];
            var uidParts = Array.isArray(uid) ? uid : uid.split('.');

            for (var index = 0; index < uidParts.length; index++) {
                var elementId = uidParts[index];
                var element = CoreCRF.getElement(elementId);
                if (!element) {
                    return '';
                }

                resolvedPath = resolvedPath.concat(element.getFullLabelFromList());

                if (element.isList() && uidParts[index + 1]) {
                    resolvedPath.push(uidParts[index + 1]);
                    index++;
                }
            }

            return resolvedPath.join(separator || ' > ');
        };
    }]);