'use strict';

angular.module('study.dashboard')
    .config(['$stateProvider', 'RouteProvider', function($stateProvider, RouteProvider) {
        $stateProvider.state(RouteProvider.create('dashboard', {
            url: '/dashboard',
            component: 'dashboardMain',
            permission: 'access dashboard',
        }));

        $stateProvider.state(RouteProvider.create('dashboard.inclusion-ratio-report', {
            url: '/inclusion-ratio-report',
            component: 'inclusionRatioReport',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Informe de ratio de inclusión');
            }],
            permission: 'read inclusion rate report',
        }));

        $stateProvider.state(RouteProvider.create('dashboard.approval-revision-report', {
            url: '/approval-revision-report',
            component: 'approvalRevisionReport',
            pageTitle: ['gettextCatalog', 'CrdConfigurationService', function(gettextCatalog, CrdConfigurationService) {
                var hasApproval = CrdConfigurationService.hasStates();
                var hasRevision = CrdConfigurationService.hasRevisionStates();

                if (hasApproval && hasRevision) {
                    return gettextCatalog.getString('Estados de aprobación y revisión');
                }

                if (hasApproval) {
                    return gettextCatalog.getString('Estados de aprobación');
                }

                if (hasRevision) {
                    return gettextCatalog.getString('Estados de revisión');
                }

                return '';
            }],
            permission: 'read approval revision report',
        }));
    }]);
