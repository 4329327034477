angular.module('sharecrf.viewport').factory('SharecrfViewPort', [
    '$window',
    '$document',
    'Event',
    function($window, $document, Event) {
        var __data = {
            width: 0,
            height: 0,
        };

        var docEl = angular.element($document)[0];
        var windowEl = angular.element($window)[0];

        function calculateViewPortSize(data) {
            var viewport = data || {};

            viewport.width = Math.max(docEl.documentElement.clientWidth || 0, windowEl.innerWidth || 0);
            viewport.height = Math.max(docEl.documentElement.clientHeight || 0, windowEl.innerHeight || 0);

            return viewport;
        }

        calculateViewPortSize(__data);

        angular.element($window).bind('resize', function() {
            calculateViewPortSize(__data);

            Event.trigger('viewport-size-changed', __data);
        });

        return {
            getSize: function() {
                return __data;
            },
        };
    },
]);