'use strict';

angular.module('study.import').controller('ImportMainController', ['AccountManager', function(AccountManager) {
    var vm = this;

    vm.permissions = {};

    vm.$onInit = function() {
        vm.permissions = {
            'Ver importacion': true,
            'edit-records-id': ['import records', 'id records'].every(function(permission) {
                return AccountManager.hasPermission(permission);
            }),
        };
    };
}]);
