/* eslint-disable id-length */
angular.module('study.dashboard')
    .component('inclusionRatioReportSummary', {
        templateUrl: 'modules/dashboard/components/inclusion-ratio-report-summary/template.html',
        bindings: {
            hideFooter: '<',
        },
        controller: [
            '$window',
            'gettextCatalog',
            'ShareCrfSDK',
            '$log',
            'CrdConfigurationService',
            'DashboardUtils',
            function($window, gettextCatalog, ShareCrfSDK, $log, CrdConfigurationService, DashboardUtils) {
                var vm = this;
                var c3 = $window.c3;
                var d3 = $window.d3;
                var moment = $window.moment;

                vm.loading = false;
                vm.error = false;

                vm.entityLabel = CrdConfigurationService.getLabelPlural();

                // El objeto de gráfico generado
                var chart = null;
                var chartData = [];

                vm.resume = {};

                vm.$onInit = function() {
                    createChart();
                    reloadChart();
                };

                function createChart() {
                    var chartConfig = {
                        bindto: '#inclusion-ratio-chart-summary',
                        data: {
                            json: [],
                            type: 'line',
                            names: {
                                accumulated: gettextCatalog.getString('Acumulado'),
                            },
                            empty: {
                                label: {
                                    text: gettextCatalog.getString('No hay datos'),
                                },
                            },
                        },
                        axis: {
                            x: {
                                type: 'category',
                                tick: {
                                    format: function() {
                                        return '';
                                    },
                                },
                            },
                            y: {
                                min: 0,
                                padding: {
                                    bottom: 0,
                                },
                                tick: {
                                    format: d3.format('d'),
                                },
                            },
                        },
                        tooltip: {
                            contents: function(data) {
                                var row = chartData[data[0].index];

                                return DashboardUtils.tooltip({
                                    header: gettextCatalog.getString('Semana {{week}} {{year}}', row),
                                    rows: [
                                        {
                                            key: 'records',
                                            label: vm.entityLabel,
                                            value: row.records,
                                        },
                                        {
                                            key: 'accumulated',
                                            label: gettextCatalog.getString('Acumulado'),
                                            value: row.accumulated,
                                            color: '#455b73',
                                        },
                                    ],
                                });
                            },
                        },
                    };

                    chart = c3.generate(chartConfig);
                }

                function reloadChart() {
                    if (!chart) {
                        return;
                    }

                    vm.loading = true;
                    vm.error = false;

                    return getData()
                        .then(function(response) {
                            vm.resume = {
                                firstInclusionDate: response.firstInclusionDate,
                                lastInclusionDate: response.lastInclusionDate,
                                totalRecords: response.totalRecords,
                            };

                            chartData = prepareChartData(response.data);

                            chart.load({
                                unload: true,
                                json: chartData,
                                keys: {
                                    x: 'yearweek',
                                    value: ['accumulated'],
                                },
                                colors: {
                                    accumulated: '#455b73',
                                },
                            });
                        })
                        .then(function() {
                            vm.loading = false;
                        })
                        .catch(function(error) {
                            $log.error(error);
                            vm.error = true;
                            vm.loading = false;
                        });
                }

                function getData() {
                    return ShareCrfSDK.dashboard.inclusionRateSummary();
                }

                /**
                 * Compatibiliza las filas de datos, incluyendo las líneas en blanco que no vienen de la API
                 *
                 * @param  {Object[]} resultRows Líneas de datos obtenidas de la API
                 *
                 * @return {Object[]}            Lista de elementos para insertar en la gráfica
                 */
                function prepareChartData(resultRows) {
                    var initialDate = vm.resume.firstInclusionDate;

                    var currentMoment = moment(initialDate).startOf('isoWeek');
                    var currentYear;
                    var currentWeek;
                    var accumulated = 0;

                    return resultRows.reduce(function(acc, row) {
                        currentYear = parseInt(currentMoment.format('GGGG'), 10);
                        currentWeek = parseInt(currentMoment.format('W'), 10);

                        while (row.year > currentYear || (row.year === currentYear && row.week > currentWeek)) {
                            acc.push({
                                records: 0,
                                accumulated: accumulated,
                                year: currentYear,
                                week: currentWeek,
                                yearweek: currentYear + '-' + currentWeek,
                            });

                            currentMoment.add(1, 'weeks');
                            currentYear = parseInt(currentMoment.format('GGGG'), 10);
                            currentWeek = parseInt(currentMoment.format('W'), 10);
                        }

                        acc.push(angular.merge({}, row, {
                            yearweek: row.year + '-' + row.week,
                        }));

                        currentMoment.add(1, 'weeks');
                        accumulated += row.records;

                        return acc;
                    }, []);
                }
            },
        ],
    });
