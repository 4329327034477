'use strict';

angular.module('app.entity.list')
    .controller('RemoveListRowModalController', ['$scope', '$injector', '$stateParams', 'StudyContext', 'CrfService', 'CrdConfigurationService', 'listItem', 'gettext', function($scope, $injector, $stateParams, StudyContext, CrfService, CrdConfigurationService, listItem, gettext) {
        var vm = this;

        var changesService = null;
        if ($injector.has('RecordChangesService')) {
            changesService = $injector.get('RecordChangesService');
        }

        var listIndices = {};

        function needsReasonOfRemoval() {
        if (CrdConfigurationService.getAudit().hasReason()) {
                listIndices = CrfService.getListIndices($stateParams);
                return changesService.isListItemInOriginalData(listItem.listId, listIndices, listItem.index);
            }
            return false;
        }
        vm.needsReason = needsReasonOfRemoval();

        if (vm.needsReason) {
            vm.itemChange = changesService.getListItemChange(listItem.listId, listIndices, listItem.index);

            // Si no hay una razón definida se reutiliza la última registrada
            if (!vm.itemChange.fixedReason) {
                vm.itemChange.fixedReason = changesService.getPreviousFixedReason();
            }
            if (!vm.itemChange.manualReason) {
                vm.itemChange.manualReason = changesService.getPreviousManualReason();
            }
        } else {
            vm.itemChange = null;
        }

        function checkAvailability() {
            if (!StudyContext.isUpdateEntityAvailable()) {
                vm.canRemove = false;
                vm.cannotRemoveReason = gettext('No se puede modificar la lista en estos momentos');
            }
            else if (vm.needsReason && !changesService.hasValidListItemChange(listItem.listId, listIndices, listItem.index)) {
                vm.canRemove = false;
                vm.cannotRemoveReason = gettext('No se ha especificado la razón de la eliminación del registro');
            }
            else {
                vm.canRemove = true;
                vm.cannotRemoveReason = null;
            }
        }

        checkAvailability();

        vm.updateReason = function() {
            checkAvailability();
        };

        vm.confirm = function() {
            if (vm.needsReason) {
                var listIndices = CrfService.getListIndices($stateParams);
                changesService.addListItemChange(listItem.listId, listIndices, listItem.index, false);
            }

            $scope.closeThisDialog();
        };

        vm.cancel = function() {
            $scope.dismissThisDialog();
        };
    }]);
