angular.module('sharecrf.radios').component('radios', {
    templateUrl: 'modules/radios/widgets/radios/template.html',
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        options: '<',
        layout: '@',
        disabled: '<ngDisabled',
    },
    controller: ['$scope', function($scope) {
        var vm = this;

        vm.model = {};
        vm.invalidOption = false;
        vm.$onInit = function() {
            vm.ngModel.$render = function() {
                vm.formatter(vm.ngModel.$modelValue);
                vm.searchForInvalidOptions();
            };

            $scope.$watch('$ctrl.ngModel.$modelValue', function(newValue, oldValue) {
                vm.formatter(vm.ngModel.$modelValue);
                vm.searchForInvalidOptions();
            });
        };

        vm.formatter = function(modelValue) {
            vm.model = modelValue;
        };

        vm.searchForInvalidOptions = function(options) {
            var optionValues = (options || vm.options || []).map(function(option) {
                return option.value;
            });

            vm.invalidOption = vm.ngModel.$modelValue && optionValues.indexOf(vm.ngModel.$modelValue) === -1;
        };

        vm.setOption = function(option) {
            if (vm.disabled) {
                return;
            }

            vm.ngModel.$setViewValue(option.value);
        };

        vm.$onChanges = function(changes) {
            if (changes.options) {
                vm.searchForInvalidOptions(changes.options.currentValue);
            }
        };
    }],
});
