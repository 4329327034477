/*! inetsys-angular-math - v0.0.1 - 2017-01-16 */
'use strict';

// Source: src/math.service.js
/* globals moment */

angular.module('inetsys.math', [])
.factory('AdvancedMath', function AdvancedMathService() {
    // Funciones para fórmulas personalizadas, que no está en Math
    return {
        round: function round(number, decimals) {
            if (typeof number === 'number') {
                return parseFloat(number.toFixed(decimals));
            }
            return NaN;
        },
        /**
         * Devuelve la diferencia en días de dos fechas
         * @param  {Date|string} initialDate   Fecha inicial
         * @param  {Date|string} finalDate     Fecha final
         * @return {integer}                   Diferencia en días
         */
        dateDiffDays: function dateDiffDays(initialDate, finalDate) {
            var initialMoment, finalMoment;
            if (initialDate instanceof Date) {
                initialMoment = moment(initialDate);
            }
            else if (typeof initialDate == 'string') {
                initialMoment = moment(initialDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601]);
            }
            else {
                return NaN;
            }

            if (finalDate instanceof Date) {
                finalMoment = moment(finalDate);
            }
            else if (typeof finalDate == 'string') {
                finalMoment = moment(finalDate, ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', moment.ISO_8601]);
            }
            else {
                return NaN;
            }

            if (initialMoment.isValid() && finalMoment.isValid()) {
                return initialMoment.diff(finalMoment, 'days');
            }
            return NaN;
        },
    };
});
