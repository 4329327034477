angular.module('ecrd').factory('CrfService', [
    'CoreCRF',
    'EcrdService',
    function CrfService(CoreCRF, EcrdService) {
        var publicAPI = {};

        // 1. Custom functions

        publicAPI.isElementAvailableToCurrentProfile = EcrdService.isElementAvailableToCurrentProfile;
        publicAPI.isElementEditableToCurrentProfile = EcrdService.isElementEditableToCurrentProfile;

        // 2. Inherited public
        var currentPrototype = Object.getPrototypeOf(CoreCRF);
        while (currentPrototype) {
            Object.getOwnPropertyNames(currentPrototype).filter(function(key) {
                if (typeof currentPrototype[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                    publicAPI[key] = CoreCRF[key].bind(CoreCRF);
                }
            });
            currentPrototype = Object.getPrototypeOf(currentPrototype);
        }

        return publicAPI;
    },
]);
