angular.module('study.import').component('importRecordsId', {
    templateUrl: 'modules/import/components/update-ids/records-id.template.html',
    controller: ['$q', 'Upload', 'ShareCrfApiErrorCodes', '$window', function(
        $q,
        Upload,
        ShareCrfApiErrorCodes,
        $window
    ) {
        var vm = this;

        vm.form = {
            delimiter: 'COMMA',
            quote: 'DQUOTE',
            file: null,
        };

        vm.uploading = false;
        vm.uploaded = false;
        vm.errors = [];
        vm.results = [];

        vm.uploadFile = function() {
            vm.uploading = true;
            vm.uploaded = false;
            vm.errors = [];
            vm.results = [];

            var timezone = angular.isDefined($window.jstz) ? $window.jstz.determine().name() : null;

            return $q.resolve()
                .then(function() {
                    return Upload.upload({
                        url: 'api/import/csv/update-ids',
                        noModalError: true,
                        data: {
                            options: {
                                delimiter: vm.form.delimiter,
                                quote: vm.form.quote,
                            },
                            file: vm.form.file,
                        },
                        headers: {
                            'X-ShareCRF-Timezone': timezone,
                        },
                    }).then(function(response) {
                        vm.results = response.data;
                    });
                })
                .catch(function(error) {
                    var errorData = 'data' in error ? error.data : error;
                    vm.errors.push(ShareCrfApiErrorCodes.getHumanReadableMessage(errorData));
                    vm.results = errorData.info;
                })
                .finally(function() {
                    vm.uploading = false;
                    vm.uploaded = true;
                });
        };
    }],
});
