(function() {
    'use strict';

    angular.module('sharecrf.loading').factory('LoadingService', LoadingService);

    LoadingService.$inject = ['$window', '$timeout', 'Modal'];

    function LoadingService($window, $timeout, Modal) {
        var _ = $window._;

        var _pending = [];

        function _addLoading(loadingId) {
            $timeout(function() {
                _pending.push({
                    _id: loadingId,
                });
            });
        }

        function _removeLoading(promiseId) {
            $timeout(function() {
                _.remove(_pending, { _id: promiseId });
            }, 300); // pequeño retardo para evitar el parpadeo, si se crean varias muy seguidas se mantiene el efecto
        }

        function addPromise(promise) {
            var promiseId = _.uniqueId('promise');

            _addLoading(promiseId);

            return promise.finally(function(result) {
                _removeLoading(promiseId);

                return result;
            });
        }

        function getPendingLoadings() {
            return _pending;
        }

        function isLoading() {
            return _pending.length > 0;
        }

        // Manual loading
        var _current = null;
        function init(settings) {
            _current = Modal.open(settings);
        }

        function clear() {
            _current && _current.close();
        }

        return {
            // Global loading
            addPromise: addPromise,
            getPendingLoadings: getPendingLoadings,
            isLoading: isLoading,
            // Manual
            init: init,
            clear: clear,
        };
    }
})();
