'use strict';

angular.module('inetsys.tools', [])
    .service('InetsysTools', function() {
        /**
         * Set or clear the hashkey for an object.
         * @param obj object
         * @param h the hashkey (!truthy to delete the hashkey)
         */
        function setHashKey(obj, h) {
          if (h) {
            obj.$$hashKey = h;
          } else {
            delete obj.$$hashKey;
          }
        }

        function deepMerge(source, destination, keep) {
            // quedarse las claves existentes si no están en source
            keep = keep || false;

            var key, i, l, j, jj;

            // copia entre arrays directamente
            if (angular.isArray(source)) {
                if (angular.isArray(destination)) {
                    destination.length = 0;
                }
                else {
                    destination = [];
                }
                for (j=0, jj=source.length; j<jj; j++) {
                    destination.push(angular.copy(source[j]));
                }
                return destination;
            }

            // copia entre objetos
            var h = destination.$$hashKey;
            var keys = Object.keys(destination);
            if (!keep) {
                for (i=0, l=keys.length; i<l; i++) {
                    key = keys[i];
                    // elimina las propiedades no presentes en el origen
                    if (key !== '$$hashKey' && angular.isUndefined(source[key])) {
                        delete destination[key];
                    }
                }
            }
            keys = Object.keys(source);
            for (i=0, l=keys.length; i<l; i++) {
                key = keys[i];
                if (angular.isObject(source[key])) {
                    if (angular.isDate(source[key])) {
                        // TODO ver el caso de un objeto fecha
                        destination[key] = new Date(source[key].valueOf());
                    }
                    else if (angular.isArray(source[key])) {
                        // TODO ver el caso de arrays de objetos
                        if (angular.isArray(destination[key])) {
                            destination[key].length = 0; // truncate sin perder la referencia
                        }
                        else {
                            destination[key] = []; // nuevo array, no existía la clave
                        }
                        for (j=0, jj=source[key].length; j<jj; j++) {
                            destination[key].push(angular.copy(source[key][j]));
                        }
                    }
                    else {
                        if (!angular.isObject(destination[key])) {
                            destination[key] = {};
                        }
                        deepMerge(source[key], destination[key], keep);
                    }
                }
                else {
                    // primitiva, hacemos una asignación directa
                    destination[key] = source[key];
                }
            }

            setHashKey(destination, h);
            return destination;
        }

        function simpleCopy(source) {
            if (!source || typeof source !== 'object') {
                return source;
            }

            var destination = angular.isArray(source) ? [] : {};
            for (var key in source) {
                destination[key] = source[key];
            }

            return destination;
        }

        return {
            deepMerge: deepMerge,
            simpleCopy: simpleCopy,
        };
    });
