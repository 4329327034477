'use strict';

angular.module('study.import').controller('ImportLoadCSVProcessController', ['$window', '$log', '$state', '$timeout', 'ShareCrfSDK', 'ImportMessageCodes', function($window, $log, $state, $timeout, ShareCrfSDK, ImportMessageCodes) {
    var vm = this;

    /**
     * Bindings:
     *  - vm.importId : UUID generado para esta importación
     *  - vm.sources: objeto que relaciona un id de fichero (root, ID lista, etc.) con el nombre del CSV original
     */

    vm.errors = [];
    vm.results = [];

    vm.finished = false;
    vm.processedLines = 0;

    var importSocket = null;
    vm.$onInit = function() {
        if (!vm.importId) {
            $state.go('import.data');

            return;
        }

        $log.debug('CSV sources', vm.sources);

        // io es una variable global que pone la librería JS socket.io-client
        importSocket = $window.io.connect('/import', {
            path: '/' + $window.ShareCRF.environment + '/socket.io',
        });
        importSocket.on('connect', function() {
            $log.debug('emit begin', vm.importId);
            importSocket.emit('begin', vm.importId);
        });

        importSocket.on('endline', function(data) {
            $log.debug('Received endline', data);
            $timeout(function() {
                vm.processedLines = data;
            });
        });
        importSocket.on('message', function(data) {
            $log.debug('Received message', data);
        });
        importSocket.on('info', function(data) {
            $log.debug('Received info', data);
            $timeout(function() {
                if (angular.isArray(data)) {
                    for (var i = 0, ii = data.length; i < ii; i++) {
                        // l10n del código de mensaje
                        data[i].readableMessage = ImportMessageCodes.getMessage(data[i]);
                        // GARU-2319 Nombre fichero original
                        if (vm.sources[data[i].source]) {
                            data[i].source = vm.sources[data[i].source];
                        }
                        vm.results.unshift(data[i]);
                    }
                }
            });
        });
        importSocket.on('error', function(data) {
            $log.debug('Received error', data);
            $timeout(function() {
                if (angular.isArray(data)) {
                    for (var i = 0, ii = data.length; i < ii; i++) {
                        // l10n del código de mensaje
                        data[i].readableMessage = ImportMessageCodes.getMessage(data[i]);
                        // GARU-2319 Nombre fichero original
                        if (vm.sources[data[i].source]) {
                            data[i].source = vm.sources[data[i].source];
                        }
                        vm.errors.unshift(data[i]);
                    }
                }
            });
        });
        importSocket.on('endfile', function(data) {
            $log.debug('Received endfile', data);
        });
        importSocket.on('finish', function(data) {
            $timeout(function() {
                vm.finished = true;
                importSocket.disconnect();
                importSocket = null;
            });
            $log.debug('Received finish', data);
        });
        importSocket.on('disconnect', function(data) {
            $log.debug('Received disconnect', data);
        });
        importSocket.on('reconnect', function(data) {
            $log.debug('Received reconnect', data);
        });

        var params = {
            id: vm.importId,
        };
        ShareCrfSDK.import.process(params)
            .catch(function(error) {
                $log.error(error, 'request-error');
            });
    };

    vm.cancel = function() {
        if (importSocket) {
            importSocket.emit('cancel', vm.importId);
            importSocket.disconnect();
        }
        importSocket = null;

        return $state.go('import.data.start');
    };

    vm.complete = function() {
        if (importSocket) {
            // si continúa abierto por lo que sea ...
            importSocket.disconnect();
            importSocket = null;
        }

        return $state.go('import.data.finished', {
            importId: vm.importId,
            sources: vm.sources,
        });
    };
}]);
