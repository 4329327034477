angular.module('user')
    .service('UserSites', ['AccountManager', 'ShareCrfSDK', 'Event', function(AccountManager, ShareCrfSDK, Event) {
        var UserSites = {};

        var loadPromise = null;

        UserSites.get = function() {
            if (!loadPromise) {
                loadPromise = AccountManager.init()
                    .then(function() {
                        return ShareCrfSDK.users.getAvailableSites();
                    });
            }

            return loadPromise;
        };

        Event.on('logout', function() {
            loadPromise = null;
        });

        return UserSites;
    }]);
