angular.module('app.entity.field')
    .component('fieldTime', {
        templateUrl: 'modules/ecrd/components/field/field-time/content.html',
        require: {
            ngModel: 'ngModel',
        },
        bindings: {
            fieldId: '@',
            fieldName: '@',
            fieldAccuracy: '@',
            disabled: '<ngDisabled',
        },
        controller: [
            '$scope',
            'gettextCatalog',
            function($scope, gettextCatalog) {
                var vm = this;
                var EMPTY_TIME = '';

                vm.ngModel = null;

                vm.time = null;
                vm.hour = null;
                vm.minutes = null;
                vm.seconds = null;

                vm.hourLabel = null;
                vm.minutesLabel = null;
                vm.secondsLabel = null;

                vm.accuracy = null;

                var formatTime = function() {
                    var hour = vm.hour || '';
                    var minutes = vm.minutes || '';
                    var seconds = null;

                    var timeParts = [hour, minutes];

                    if (vm.accuracy === 'seconds') {
                        seconds = vm.seconds || '';
                        timeParts.push(seconds);
                    }

                    if (timeParts.every(function(part) {
                        return !part;
                    })) {
                        return EMPTY_TIME;
                    }

                    return timeParts.join(':');
                };

                vm.$onInit = function() {
                    vm.accuracy = vm.fieldAccuracy;

                    vm.hourLabel    = gettextCatalog.getString('hh', {}, 'Campo de hora, hh:-:-');
                    vm.minutesLabel = gettextCatalog.getString('mm', {}, 'Campo de hora, -:mm:-');
                    vm.secondsLabel = gettextCatalog.getString('ss', {}, 'Campo de hora, -:-:ss');

                    vm.ngModel.$render = function() {
                        vm.time = vm.ngModel.$viewValue;
                        vm.hour = vm.time ? vm.time.split(':')[0] : '';
                        vm.minutes = vm.time ? vm.time.split(':')[1] : '';
                        vm.seconds = vm.time ? vm.time.split(':')[2] : '';

                        vm.ngModel.$setViewValue(vm.time);
                    };
                };

                $scope.$watch('$ctrl.hour', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        vm.time = newValue + ':' + vm.minutes;

                        if (vm.accuracy === 'seconds') {
                            vm.time += ':' + vm.seconds;
                        }
                    }
                });

                $scope.$watch('$ctrl.minutes', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        vm.time = vm.hour + ':' + newValue;

                        if (vm.accuracy === 'seconds') {
                            vm.time += ':' + vm.seconds;
                        }
                    }
                });

                $scope.$watch('$ctrl.seconds', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        vm.time = vm.hour + ':' + vm.minutes + ':' + newValue;
                    }
                });

                $scope.$watch('$ctrl.time', function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        var time = formatTime();
                        vm.ngModel.$setViewValue(time);
                    }
                });
            },
        ],
});