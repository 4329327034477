'use strict';

angular.module('user')
    .component('loginForm', {
        templateUrl: 'modules/user/components/login-form/template.html',
        controller: 'UserLoginController',
        bindings: {
            message: '<',
            warning: '<',
            error: '<',
            onLogin: '&',
        },
    });
