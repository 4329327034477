'use strict';

angular.module('error')
.component('simpleMessage', {
    transclude: true,
    templateUrl: 'modules/error/components/simple/content.html',
    controller: 'SimpleMessageController',
    bindings: {
        level: '@',
        text: '<',
        code: '<',
    },
});
