/* eslint-disable angular/no-run-logic */
/* eslint-disable angular/component-limit */
/* eslint-disable angular/module-dependency-order */
angular.module('sharecrf.gtm', ['ui.router', 'page-title'])
    .factory('GTM', ['$window', '$log', function ($window, $log) {
        return {
            isEnabled: function () {
                return !!$window.dataLayer;
            },
            pushEvent: function (event) {
                var dataLayer = $window.dataLayer || null;

                if (dataLayer) {
                    dataLayer.push(event);

                    $log.debug('%c ShareCRF %c GTM %c',
                        'background: #455B73; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
                        'background: #4285f4; padding: 1px; border-radius: 0 3px 3px 0; color: #fff',
                        'background: transparent',
                        event
                    );
                }
            },
        };
    }])
    .run(['$location', '$log', '$transitions', 'PageTitle', 'GTM', function ($location, $log, $transitions, PageTitle, GTM) {
        if (!GTM.isEnabled()) {
            $log.info('%c ShareCRF %c GTM %c GTM is not enabled',
                'background: #455B73; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
                'background: #4285f4; padding: 1px; border-radius: 0 3px 3px 0; color: #fff',
                'background: transparent'
            );
        } else {
            $transitions.onSuccess({}, function () {
                GTM.pushEvent({
                    event: 'virtualPageview',
                    pageUrl: $location.absUrl(),
                    pageTitle: PageTitle.getInfo().title,
                });
            });
        }
    }]);
