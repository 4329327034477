angular.module('study.i18n').provider('StudyLanguageService', function () {
    var provider = this;

    provider.language = {
        code: 'es',
    };

    // Service
    provider.$get = ['$window', 'gettextCatalog', function($window, gettextCatalog) {
        function initialize() {
            var lang = provider.language.code;

            // angular-gettext config
            gettextCatalog.setCurrentLanguage(lang);

            // TrNgGrid language settings
            var TrNgGrid = $window.TrNgGrid;

            if (TrNgGrid) {
                if (angular.isUndefined(TrNgGrid.translations)) {
                    TrNgGrid.translations = {};
                }
                // Lo que hacemos es, como el idioma por defecto es 'es' y está puesto a pelo
                // en el código, sobreescribimos ese idioma. De otro modo, nos obligaría a ir tabla
                // por tabla y añadir el parámetro locale en cada HTML
                var defaultTranslation = {
                    en: {},
                    es: {
                        'Search': 'Buscar',
                        'Page': 'Página',
                        'First Page': 'Primera página',
                        'Next Page': 'Siguiente página',
                        'Previous Page': 'Página anterior',
                        'Last Page': 'Última página',
                        'Sort': 'Ordenar',
                        'No items to display': 'No hay elementos a mostrar',
                        'displayed': 'mostrados',
                        'in total': 'en total',
                    },
                    de: {
                        'Search': 'Suche',
                        'Page': 'Seite',
                        'First Page': 'Erste Seite',
                        'Next Page': 'Nächste Seite',
                        'Previous Page': 'Vorherige Seite',
                        'Last Page': 'Letzte Seite',
                        'Sort': 'Sortieren',
                        'No items to display': 'Keine Elemente zum Anzeigen',
                        'displayed': 'angezeigt',
                        'in total': 'insgesamt',
                    },
                    pt: {
                        'Search': 'Pesquisar',
                        'Page': 'Página',
                        'First Page': 'Primeira página',
                        'Next Page': 'Próxima página',
                        'Previous Page': 'Página anterior',
                        'Last Page': 'Última página',
                        'Sort': 'Ordenar',
                        'No items to display': 'Não há itens para mostrar',
                        'displayed': 'mostrados',
                        'in total': 'no total',
                    },
                };
                TrNgGrid.translations.en = defaultTranslation[lang];
                TrNgGrid.translations.de = defaultTranslation[lang];
                TrNgGrid.translations.pt = defaultTranslation[lang];

                if (TrNgGrid.translationDateFormat) {
                    var defaultTranslationDateFormat = {
                        en: 'yyyy-MM-dd',
                        es: 'dd/MM/yyyy',
                        de: 'dd.MM.yyyy',
                        pt: 'dd/MM/yyyy',
                    };
                    TrNgGrid.translations.en[TrNgGrid.translationDateFormat] = defaultTranslationDateFormat[lang];
                    TrNgGrid.translations.de[TrNgGrid.translationDateFormat] = defaultTranslationDateFormat[lang];
                    TrNgGrid.translations.pt[TrNgGrid.translationDateFormat] = defaultTranslationDateFormat[lang];
                }
            }
        }

        return {
            language: provider.language,
            initialize: initialize,
        };
    }];
});
