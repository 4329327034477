angular.module('sharecrf.partial-date').component('scrfFieldPartialDate', {
    transclude: true,
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        ngDisabled: '<',
        partialDateSettings: '<',
        name: '@',
    },
    templateUrl: 'modules/partial-date/widgets/components/template.html',
    controller: [
        '$window',
        '$timeout',
        '$element',
        'gettextCatalog',
        function($window, $timeout, $element, gettextCatalog) {
            var vm = this;

            // Local model

            vm.day = '';
            vm.month = '';
            vm.year = '';

            // Available options

            vm.dayOptions = [
                { value: '', label: gettextCatalog.getString('- Seleccionar día -') },
                { value: 'x', label: gettextCatalog.getString('Desconocido') },
            ];
            for (var day = 1; day <= 31; day++) {
                vm.dayOptions.push({ value: pad(day, 2, '0'), label: day });
            }

            vm.monthOptions = [
                { value: '', label: gettextCatalog.getString('- Seleccionar mes -') },
                { value: 'x', label: gettextCatalog.getString('Desconocido') },
                { value: '01', label: gettextCatalog.getString('enero') },
                { value: '02', label: gettextCatalog.getString('febrero') },
                { value: '03', label: gettextCatalog.getString('marzo') },
                { value: '04', label: gettextCatalog.getString('abril') },
                { value: '05', label: gettextCatalog.getString('mayo') },
                { value: '06', label: gettextCatalog.getString('junio') },
                { value: '07', label: gettextCatalog.getString('julio') },
                { value: '08', label: gettextCatalog.getString('agosto') },
                { value: '09', label: gettextCatalog.getString('septiembre') },
                { value: '10', label: gettextCatalog.getString('octubre') },
                { value: '11', label: gettextCatalog.getString('noviembre') },
                { value: '12', label: gettextCatalog.getString('diciembre') },
            ];

            vm.yearOptions = [];
            vm.yearEmptyText = gettextCatalog.getString('- Seleccionar año -')

            // Update value in external ngModelControler when local model changes
            vm.updateModelValue = function() {
                var modelValue = vm.year + '-' + vm.month + '-' + vm.day;
                vm.ngModel.$setViewValue(modelValue);
                validateModel(vm);
            };

            // Year editor

            vm.yearOptionsVisible = false;
            vm.openYearOptions = function() {
                // If field is disabled, do nothing
                if (vm.ngDisabled) {
                    return;
                }
                // Update year options
                updateYearOptions(vm);

                // Add onclick to body to close calendar
                bindOnClickToClose();

                vm.yearOptionsVisible = true;
            };
            vm.closeYearOptions = function() {
                // Remove onclick to body to close calendar
                unbindOnClickToClose();

                vm.yearOptionsVisible = false;
            };

            vm.referenceYear = null;
            vm.stepYears = 4;
            vm.showPreviousYears = function() {
                vm.referenceYear -= (2 * vm.stepYears + 1);
                vm.yearOptions = getYearOptions(vm.referenceYear, vm.stepYears);
            };
            vm.showNextYears = function() {
                vm.referenceYear += (2 * vm.stepYears + 1);
                vm.yearOptions = getYearOptions(vm.referenceYear, vm.stepYears);
            };

            vm.setYear = function(value) {
                vm.year = value;
                vm.closeYearOptions();
                vm.updateModelValue();
            };

            // Component lifecycle hooks

            vm.$onInit = function() {
                // Bind external ngModel to local model
                vm.ngModel.$render = function() {
                    var dateParts = (vm.ngModel.$viewValue || '').split('-');

                    vm.day = dateParts.length === 3 ? dateParts[2] : '';
                    vm.month = dateParts.length > 1 ? dateParts[1] : '';
                    vm.year = dateParts.length > 0 ? dateParts[0] : '';

                    validateModel(vm);
                };
            };
            vm.$onUpdate = function() {
                validateModel(vm);
            };

            // Internals

            /**
             * Set validation on external ngModelController
             *
             * @param {Controller} $ctrl Angular controller
             */
            function validateModel($ctrl) {
                var completedComponents = [$ctrl.day, $ctrl.month, $ctrl.year].filter(function(item) {
                    return item !== '';
                });

                // 1.- Fecha incompleta - es necesario que los tres componentes de la fecha estén definidos
                $ctrl.ngModel.$setValidity('incomplete-date', completedComponents.length === 0 || completedComponents.length === 3);

                // 2.- Fecha válida - La fecha debe ser válida aun teniendo algún componente definido como "desconocido"
                var dateFormat = ($ctrl.year === 'x' ? '[x]' : 'YYYY') + '-' + ($ctrl.month === 'x' ? '[x]' : 'MM') + '-' + ($ctrl.day === 'x' ? '[x]' : 'DD');
                var modelValue = vm.year + '-' + vm.month + '-' + vm.day;

                $ctrl.ngModel.$setValidity('date', completedComponents.length < 3 || $window.moment(modelValue, dateFormat, true).isValid());
            }

            /**
             * Update year options in controller
             *
             * @param {Controller} $ctrl Angular controller
             */
            function updateYearOptions($ctrl) {
                $ctrl.referenceYear = $ctrl.year && $ctrl.year !== 'x' ? +$ctrl.year : (new Date).getFullYear();

                $ctrl.yearOptions = getYearOptions($ctrl.referenceYear, $ctrl.stepYears);
            }

            /**
             * Get year options
             *
             * @param {Number} selectedYear Reference year
             * @param {Number} stepYears    Number of options before and after the reference year
             *
             * @return {Number[]}           Options
             */
            function getYearOptions(selectedYear, stepYears) {
                var years = [];

                for (var iteration = selectedYear - stepYears; iteration <= selectedYear + stepYears; iteration++) {
                    years.push(iteration);
                }

                return years;
            }

            /**
             * Pad string
             *
             * @param {String} content content
             * @param {Number} length  Length
             * @param {String} char    Character to add on the left
             *
             * @returns {String}       Content
             */
            function pad(content, length, char) {
                return '' + char.repeat(length - ('' + content).length) + content;
            }

            function onclickListener(event) {
                var yearWrapper = $element.find('div')[3];
                var clickedElement = angular.element(event.target);

                // Allow clicks on calendar popup and input. Close on click in any other element.
                if (!isDescendant(yearWrapper, clickedElement[0])) {
                    vm.closeYearOptions();
                }
            }

            function isDescendant(parent, child) {
                var node = child.parentNode;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }

                return false;
            }

            /** */
            function bindOnClickToClose() {
                $timeout(function() {
                    if (!vm.binded) {
                        // eslint-disable-next-line angular/document-service
                        document.body.addEventListener('click', onclickListener);
                        vm.binded = true;
                    }
                });
            }
            /** */
            function unbindOnClickToClose() {
                $timeout(function() {
                    if (vm.binded) {
                        // eslint-disable-next-line angular/document-service
                        document.body.removeEventListener('click', onclickListener, false);
                        vm.binded = false;
                    }
                });
            }
        },
    ],
});
