'use strict';

angular.module('route')
    .provider('RouteAuth', function() {
        var provider = this;

        provider.init = ['$q', function($q) {
            return $q.resolve;
        }];
        provider.getLoggedUser = null;
        provider.isLogged = null;
        provider.hasPermission = null;

        provider.$get = ['$injector', function($injector) {
            return {
                init: function() {
                    return $injector.invoke(provider.init)();
                },
                getLoggedUser: function() {
                    return provider.getLoggedUser ? $injector.invoke(provider.getLoggedUser)() : {};
                },
                isLogged: function() {
                    return provider.isLogged ? $injector.invoke(provider.isLogged)() : false;
                },
                hasPermission: function(permission) {
                    return provider.hasPermission ? $injector.invoke(provider.hasPermission)(permission) : false;
                },
            };
        }];
    });
