angular.module('error').config(['$logProvider', '$provide', function($logProvider, $provide) {
    function localstorage() {
        try {
            return window.localStorage;
        } catch (ignoredError) {}
    }
    /* eslint-disable no-undef */
    var storage = typeof chrome !== 'undefined'
            && typeof chrome.storage !== 'undefined'
        ? chrome.storage.local : localstorage();
    /* eslint-enable no-undef */

    var levels = {
        debug: 1,
        info: 2,
        warn: 3,
        error: 4,
        // to hide all console messages, if localStorage variable exists
        none: 5,
    };
    var debug = false;
    var loggedLevel = 5;

    if (storage && storage.scrfConsole) {
        loggedLevel = levels[storage.scrfConsole] || 1;
        debug = (loggedLevel === 1);
    }

    if (debug) {
        $logProvider.debugEnabled(true);
    } else {
        $logProvider.debugEnabled(false);
    }

    function _xdr(url) {
        var headers = {};
        if ( ! /\.min.*\.js/.test(url)) {
            // version client
            headers['X-App-Debug'] = 1;
        }

        return new Promise(function(resolve, reject) { // eslint-disable-line no-undef
            var req = new XMLHttpRequest();
            req.open('get', url);
            req.onerror = reject;
            for (var key in headers) {
                req.setRequestHeader(key, headers[key]);
            }
            req.onreadystatechange = function onreadystatechange() {
                if (req.readyState === 4) {
                    if ((req.status >= 200 && req.status < 300) ||
                            (url.substr(0, 7) === 'file://' && req.responseText)) {
                        resolve(req.responseText);
                    } else {
                        reject(new Error('HTTP status: ' + req.status + ' retrieving ' + url));
                    }
                }
            };
            req.send();
        });
    }

    $provide.decorator('$log', ['$delegate', function $logDecorator($delegate) {
        var originalError = $delegate.error;

        $delegate.error = function(msg, cause) {
            // If current log level allows to log errors on console, go on
            if (loggedLevel <= levels.error) {
                originalError.apply($delegate, arguments);
            }

            // Always log errors using FrontErrors, if exists
            if (window.FrontErrors && typeof window.FrontErrors.listener === 'function') {
                var message;
                var fileName = msg.fileName;
                var lineNumber = msg.lineNumber;
                var columnNumber = msg.columnNumber;

                if (typeof msg === 'object') {
                    message = msg.message || 'Unknown message';
                    if (msg.type) {
                        message += ' - ' + msg.type;
                    }
                    if (msg.code) {
                        message += '::' + msg.code;
                    }

                    // si está cargado stacktrace-js
                    if (typeof window.StackTrace !== 'undefined') {
                        // devolvemos directamente la promise, en el then se llama al FrontErrors
                        return window.StackTrace.fromError(msg, { ajax: _xdr})
                            .then(function(stack) {
                                // stack es un Array de StackFrame (https://github.com/stacktracejs/stackframe)
                                try {
                                    msg.stack = stack.map(function(stackframe) {
                                        return stackframe.toString();
                                    }).join('\n');
                                } catch (err) {
                                    window.FrontErrors.listener(err.message, fileName, lineNumber, columnNumber, err);
                                    msg.stack = stack.toString();
                                }

                                if (typeof cause === 'string') {
                                    msg.stack += '\ncaused in ' + cause;
                                }

                                window.FrontErrors.listener(message, fileName, lineNumber, columnNumber, msg);
                            })
                            .catch(function(err) {
                                // 1. mandamos el error de StackTrace
                                window.FrontErrors.listener(err.message, fileName, lineNumber, columnNumber, err);
                                // 2. mandamos el error original
                                window.FrontErrors.listener(message, fileName, lineNumber, columnNumber, msg);
                            });
                    }

                    // si no está cargado stacktrace-js continúa aquí
                    var stack = msg.stack ? msg.stack : '';
                    if (typeof stack !== 'string') {
                        try {
                            stack = JSON.stringify(stack);
                        } catch (error) {}
                    }
                    if (typeof cause === 'string') {
                        stack += '\ncaused in ' + cause;
                    }
                    msg.stack = stack;

                } else {
                    message = msg;
                }

                window.FrontErrors.listener(message, fileName, lineNumber, columnNumber, msg);
            }
        };

        if (loggedLevel > levels.debug) {
            $delegate.debug = angular.noop;
        }
        if (loggedLevel > levels.info) {
            $delegate.log = angular.noop;
            $delegate.info = angular.noop;
        }
        if (loggedLevel > levels.warn) {
            $delegate.warn = angular.noop;
        }

        return $delegate;
    }]);
}]);
