angular.module('ecrd').factory('RecordClipboardService', [
    'RecordDataService',
    'RecordValidationService',
    'FormFieldsService',
    'Event',
    function(RecordDataService, RecordValidationService, FormFieldsService, Event) {
        // Interfaz de objeto de datos indexados
        function createObject() {
            return {
                // pares clave-valor indexados por UID del campo afectado
                data: {},
                has: function(fieldUid) {
                    return getKey(fieldUid) in this.data;
                },
                get: function(fieldUid) {
                    return this.data[getKey(fieldUid)];
                },
                put: function(fieldUid, value) {
                    this.data[getKey(fieldUid)] = value;
                },
                remove: function(fieldUid) {
                    delete this.data[getKey(fieldUid)];
                },
                pop: function(fieldUid) {
                    var value = this.get(fieldUid);
                    this.remove(fieldUid);

                    return value;
                },
                // Limpiar los datos
                clear: function() {
                    this.data = {};
                },
            };
        }

        // Los datos del portapapeles: pares clave-valor indexados por UID del campo afectado
        var data = createObject();

        // Razones de cambio que se establecen al cambiar los valores de los campos
        var reasonsOfChange = createObject();

        // Las claves se normalizan como string
        function getKey(fieldUid) {
            return angular.isArray(fieldUid) ? fieldUid.join('.') : fieldUid;
        }

        // Limpiar todos los datos del servicio
        function clear() {
            data.clear();
            reasonsOfChange.clear();
        }

        function storeField(field, listIndices, options) {
            var fieldId = field.getId();
            var uid = RecordDataService.getElementUID(fieldId, listIndices);
            var value = RecordDataService.getFieldValue(fieldId, listIndices);

            // Si no se especifica el parámetro replace actúa como TRUE
            options = options || {};
            if (angular.isDefined(value) && (!data.has(uid) || options.replace !== false)) {
                data.put(uid, value);

                Event.trigger('clipboardFieldStored', fieldId, listIndices);
            }

            storeChildren(field, listIndices, options);
        }

        function restoreField(field, listIndices) {
            var fieldId = field.getId();

            // Aquí puede ocurrir que haya dos reglas afectando al mismo tiempo: una de hide que se cumpla y una de
            // hideChildren que no lo haga. En este caso se guardaría el valor por la primera regla, y la segunda
            // trataría de recuperarlo. Obligando a que el campo esté visible antes de recuperar el valor podemos
            // asegurar que no va a haber un funcionamiento extraño
            // GARU-7311 Se normaliza para todos los campos porque puede haber reglas para ocultar su formulario
            if (FormFieldsService.isElementVisible(fieldId, listIndices)) {
                var uid = RecordDataService.getElementUID(fieldId, listIndices);

                if (data.has(uid)) {
                    var storedValue = data.pop(uid);
                    var reasonOfChange = reasonsOfChange.pop(uid);

                    // GARU-5332 Si el valor cambia, se fuerza la revalidación del campo y se lanza el evento del
                    // portapapeles
                    if (RecordDataService.setFieldValue(fieldId, storedValue, listIndices)) {
                        RecordValidationService.validateField(fieldId, listIndices);
                    }

                    Event.trigger('clipboardFieldRestored', fieldId, listIndices, storedValue, reasonOfChange);
                }

                restoreChildren(field, listIndices);
            }
        }

        function storeSection(element, listIndices, options) {
            if (!element) {
                return;
            }

            if (element.isForm()) {
                angular.forEach(element.getFields(), function(field) {
                    storeField(field, listIndices, options);
                });
            } else if (element.isSection()) {
                angular.forEach(element.getChildren(), function(childElement) {
                    storeSection(childElement, listIndices, options);
                });
            } else if (element.isList()) {
                var itemsCount = RecordDataService.getListLength(element.getId(), listIndices);
                var localIndices = angular.copy(listIndices);
                for (var index = 0; index < itemsCount; index++) {
                    localIndices[element.getId()] = index + 1;

                    angular.forEach(element.getChildren(), function(childElement) {
                        storeSection(childElement, localIndices, options);
                    });
                }
            }
        }

        function restoreSection(element, listIndices) {
            if (!element) {
                return;
            }

            if (element.isForm()) {
                angular.forEach(element.getFields(), function(field) {
                    restoreField(field, listIndices);
                });
            } else if (element.isSection()) {
                angular.forEach(element.getChildren(), function(childElement) {
                    restoreSection(childElement, listIndices);
                });
            } else if (element.isList()) {
                var elementId = element.getId();
                var itemsCount = RecordDataService.getListLength(elementId, listIndices);
                var localIndices = angular.copy(listIndices);
                for (var index = 0; index < itemsCount; index++) {
                    localIndices[elementId] = index + 1;

                    angular.forEach(element.getChildren(), function(childElement) {
                        restoreSection(childElement, localIndices);
                    });
                }
            }
        }

        function storeChildren(parentField, listIndices, options) {
            angular.forEach(parentField.getDescendants(), function(subfield) {
                storeField(subfield, listIndices, options);
            });
        }

        function restoreChildren(parentField, listIndices) {
            angular.forEach(parentField.getDescendants(), function(subfield) {
                restoreField(subfield, listIndices);
            });
        }

        function addReasonOfChange(fieldId, listIndices, reasonObject, options) {
            var uid = RecordDataService.getElementUID(fieldId, listIndices);

            // Si no se especifica el parámetro replace actúa como TRUE
            if (options && !options.replace && reasonsOfChange.has(uid)) {
                return;
            }

            // Para evitar que desde fuera se modifique el objeto hacemos una copia, aprovechando para recoger solamente
            // los datos que nos interesa restaurar
            var reasonSubset = {
                fixedReason: reasonObject.fixedReason,
                manualReason: reasonObject.manualReason,
            };

            return reasonsOfChange.put(uid, reasonSubset);
        }

        return {
            clear: clear,
            storeField: storeField,
            restoreField: restoreField,
            storeSection: storeSection,
            restoreSection: restoreSection,
            storeChildren: storeChildren,
            restoreChildren: restoreChildren,
            addReasonOfChange: addReasonOfChange,
        };
    },
]);
