(function() {
    'use strict';

    angular.module('sharecrf.loading')
        .controller('ShareCrfLoadingController', ShareCrfLoadingController);

    ShareCrfLoadingController.$inject = ['LoadingService'];

    function ShareCrfLoadingController(LoadingService) {
        var vm = this;

        vm.pendingLoadings = LoadingService.getPendingLoadings();
    }
})();
