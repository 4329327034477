/* eslint-disable angular/no-service-method */
'use strict';

angular.module('study-info', ['sharecrf-sdk']).service('StudyInfo', [
    '$q',
    'ShareCrfSDK',
    function ($q, ShareCrfSDK) {
        var initied = false;
        var loadPromise = null;
        var serviceData = {
            study: {},
        };

        var StudyInfo = {};

        StudyInfo.init = function () {
            var promise;

            if (!initied) {
                promise = StudyInfo.load()
                    .then(function () {
                        initied = true;
                    });
            } else {
                promise = $q.resolve();
            }

            return promise;
        };

        StudyInfo.load = function () {
            var promise;

            if (loadPromise) {
                promise = loadPromise;
            } else {
                loadPromise = ShareCrfSDK.info.getStudy()
                    .then(function (studyInfo) {
                        StudyInfo.setInfo(studyInfo);

                        loadPromise = null;
                    });

                promise = loadPromise;
            }

            return promise;
        };

        StudyInfo.setInfo = function (studyInfo) {
            for (var property in serviceData.study) {
                delete serviceData.study[property];
            }

            _.assign(serviceData.study, studyInfo);
        };

        StudyInfo.getInfo = function () {
            return serviceData.study;
        };

        return StudyInfo;
    },
]);
