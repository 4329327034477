'use strict';

angular.module('study.placeholders').directive('entityFieldValue', function() {
    return {
        restrict: 'A',
        replace: false,
        templateUrl: 'modules/placeholders/entity-field-value/template.html',
        controller: 'PlaceholderEntityFieldValueController',
        controllerAs: 'placeholder',
        scope: {
            fieldName: '@entityFieldValue',
            stateParams: '=entityFieldValueParams',
        },
    };
});
