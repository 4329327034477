angular.module('ecrd.record.bulk').controller('RecordBulkStatusController', [
    'CrdConfigurationService',
    'AccountManager',
    'RecordsManagerService',
    'gettextCatalog',
    'ShareCrfApiErrorCodes',
    function (CrdConfigurationService, AccountManager, RecordsManagerService, gettextCatalog, ShareCrfApiErrorCodes) {
        var vm = this;

        // Lista de operaciones disponibles según la configuración del estudio y los propios permisos del usuario
        vm.availableOperations = [];

        // La opción seleccionada con los radios
        vm.selectedOperation = null;

        // Si el estado seleccionado necesita firmar, el texto asociado
        vm.signatureDeclaration = null;

        // La firma del usuario, si la ha puesto
        vm.userSignature = '';

        // Resultado de las operaciones
        vm.result = [];

        // Flag para indicar que se la operación está en marcha
        vm.ready = false;

        // Si es obligatoria la firma para continuar
        vm.signatureRequired = false;

        // Flag que indica que hay un error de firma. En este caso no es necesario continuar las operaciones, ya que
        // teóricamente todos van a fallar
        vm.signatureError = false;

        // Cabecera de la tabla de resultados
        vm.entityLabel = CrdConfigurationService.getLabelSingular();

        // El nombre de estado actualmente seleccionado
        vm.stateName = null;

        // El nombre traducido de la acción seleccionada: establecer/anular
        vm.actionLabel = null;

        vm.$onInit = function() {
            initAvailableOperations();
        };

        function initAvailableOperations() {
            vm.availableOperations = [];

            var statesConfiguration = CrdConfigurationService.getStates();
            if (statesConfiguration.hasRecordLock()) {
                if (AccountManager.hasPermission('lock records')) {
                    vm.availableOperations.push({
                        id: 'locked',
                        operation: 'set',
                        label: gettextCatalog.getString('Bloquear {{ entityLabel }}', {
                            entityLabel: vm.entityLabel,
                        }),
                        signMessage: statesConfiguration.hasRecordSignOnLock()
                            ? gettextCatalog.getString('Confirmación de firma')
                            : '',
                    });
                }
                if (AccountManager.hasPermission('unlock records')) {
                    vm.availableOperations.push({
                        id: 'locked',
                        operation: 'unset',
                        label: gettextCatalog.getString('Desbloquear {{ entityLabel }}', {
                            entityLabel: vm.entityLabel,
                        }),
                    });
                }
            }

            angular.forEach(statesConfiguration.getStates(), function(state) {
                var stateId = state.getId();
                var stateName = state.getName();

                angular.forEach(['set', 'unset'], function(operation) {
                    if (AccountManager.hasPermission(operation + ' records state ' + stateId)) {
                        var operationData = {
                            id: stateId,
                            name: stateName,
                            operation: operation,
                            signMessage: operation === 'set' && state.requiresSignature()
                                ? state.getSignatureMessage()
                                : '',
                        };

                        operationData.label = operation === 'set'
                            ? gettextCatalog.getString('Activar {{ state }}', { state: state.getName() })
                            : gettextCatalog.getString('Anular {{ state }}', { state: state.getName() });

                        vm.availableOperations.push(operationData);
                    }
                });
            });
        }

        vm.onSelectOperation = function(operation) {
            vm.signatureDeclaration = operation.signMessage;
            vm.stateName = operation.name;
            vm.userSignature = ''; // Si cambia el estado hay que volver a colocar la firma
            vm.signatureRequired = !!vm.signatureDeclaration;
            vm.actionLabel = operation.label;
        };

        vm.bulkUpdate = function() {
            vm.ready = true;
            vm.result = [];

            var frags = vm.selectedOperation.split('-');
            var stateId = frags[0];
            var action = frags[1];

            var commonParams = {
                context: 'record',
                state: stateId,
                enabled: action === 'set' ? true : false,
            };

            if (vm.userSignature) {
                commonParams.password = vm.userSignature;
            }

            var recordsList = vm.records.slice();

            var nextRecord = function() {
                var record = recordsList.shift();
                if (!record) {
                    return;
                }

                var params = angular.copy(commonParams);
                params.recordId = record.id;

                var resultLine = addResult({
                    uid: record.uid,
                    status: 'processing',
                    message: gettextCatalog.getString('Procesando...'),
                });

                RecordsManagerService.setGlobalState(params)
                    .then(function () {
                        var actionMessage;
                        var resultMessage;

                        if (params.state === 'locked') {
                            resultMessage = action === 'set'
                                ? gettextCatalog.getString('Bloqueado')
                                : gettextCatalog.getString('Desbloqueado');
                        } else {
                            actionMessage = action === 'set'
                                ? gettextCatalog.getString('activado')
                                : gettextCatalog.getString('desactivado');

                            resultMessage = gettextCatalog.getString('{{ state }} {{ action }}', {
                                state: vm.stateName,
                                action: actionMessage,
                            }, 'bulk states, ej: "Firmado activado"');
                        }

                        Object.assign(resultLine, {
                            status: 'success',
                            message: resultMessage,
                        });
                        nextRecord();
                    })
                    .catch(function (error) {
                        Object.assign(resultLine, {
                            status: 'error',
                            message: formatErrorMessage(error, action),
                        });

                        if ('code' in error && ['signature-not-specified', 'bad-signature'].indexOf(error.code) > -1) {
                            vm.signatureError = true;
                        } else {
                            // si el error no es de firma, sigue con el siguiente record
                            nextRecord();
                        }
                    });
            };

            nextRecord();
        };

        function addResult(line) {
            vm.result.unshift(line);

            return line;
        }

        function formatErrorMessage(error, action) {
            if (error.code === 'record-action-update-global-metadata-already-done') {
                var currentAction, nextAction;

                if (action === 'set') {
                    currentAction = gettextCatalog.getString('activado');
                    nextAction = gettextCatalog.getString('activar');
                } else {
                    currentAction = gettextCatalog.getString('desactivado');
                    nextAction = gettextCatalog.getString('desactivar');
                }

                return gettextCatalog.getString('No se puede {{ action }} {{ state }} (ya estaba {{ status }})', {
                    action: nextAction,
                    state: vm.stateName,
                    status: currentAction,
                });
            }

            return ShareCrfApiErrorCodes.getHumanReadableMessage(error);
        }
    },
]);