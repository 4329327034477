angular.module('ecrd.notifications').component('recordNotificationsTable', {
    templateUrl: 'modules/ecrd/components/notifications/table/template.html',
    controller: [
        'NotificationsService',
        'Modal',
        '$exceptionHandler',
        function(NotificationsService, Modal, $exceptionHandler) {
            var vm = this;

            // Lista de notificaciones
            vm.notifications = [];

            // Configuración de la tabla
            vm.table = {
                orderBy: 'createdAt',
                orderReverse: true,
                limit: 25,
                currentPage: 0,
                count: 0,
                loading: false,
            };

            vm.loadNotifications = function() {
                vm.table.loading = true;

                return NotificationsService.getRecordNotifications({
                    order: 'createdAt ' + (vm.table.orderReverse ? 'desc' : 'asc'),
                    limit: vm.table.limit,
                    offset: vm.table.limit * vm.table.currentPage, // vm.currentPage is zero-based
                })
                    .then(function(result) {
                        vm.notifications = result.rows;
                        vm.table.count = result.count;
                    })
                    .finally(function() {
                        vm.table.loading = false;
                    });
            };

            vm.showMessage = function(notification) {
                return openModal(notification, 'modules/notifications/modal/details-message.html');
            };

            vm.showChannel = function(notification) {
                return openModal(notification, 'modules/notifications/modal/details-channel.html');
            };

            function openModal(notification, templateUrl) {
                return Modal.open({
                    templateUrl: templateUrl,
                    data: {
                        notification: notification,
                    },
                }).closePromise.catch($exceptionHandler);
            }
        },
    ],
});
