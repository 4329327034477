'use strict';

angular.module('study.import')
.component('importLoadCSVMain', {
    templateUrl: 'modules/import/components/load-csv/main.html',
    controller: 'importLoadCSVMainController',
    bindings: {
        routeParamMode: '<',
    },
});
