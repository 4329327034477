'use strict';

angular.module('ecrd')
    .filter('elementValue', ['$filter', '$sce', 'gettextCatalog', function($filter, $sce, gettextCatalog) {
        // Source: http://stackoverflow.com/a/22535765
        function stripTags(input, allowed) {
            if (!input) return input;
            allowed = (((allowed || '') + '')
                .toLowerCase()
                .match(/<[a-z][a-z0-9]*>/g) || [])
                .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

            return input.replace(commentsAndPhpTags, '')
                .replace(tags, function(match0, match1) {
                    return allowed.indexOf('<' + match1.toLowerCase() + '>') > -1 ? match0 : '';
                });
        }

        return function(fieldValue) {
            var formatted;
            switch (fieldValue.type) {
                case 'boolean':
                    formatted = fieldValue.value
                        ? gettextCatalog.getString('Marcado')
                        : gettextCatalog.getString('Desmarcado');
                    break;

                case 'option':
                    formatted = fieldValue.label;
                    break;
                case 'options':
                    formatted = (fieldValue.value || []).map(function(item) {
                        return item.label;
                    }).join(', ');
                    break;

                case 'date':
                    formatted = fieldValue.value
                        ? $filter('dateFormat')(fieldValue.value, 'dateinput')
                        : '';
                    break;

                case 'files':
                    formatted = $sce.trustAsHtml(fieldValue.value.map(function(file) {
                        var html = '';
                        var fileFamily = $filter('fileFamily')(file.mimetype);

                        html += '<span class="sharecrf-field-file">';
                        if (file.url) {
                            html += '<a class="file file-' + fileFamily + '" href="' + file.url + '" target="_blank">';
                            html += file.filename;
                            html += '</a>';
                        } else {
                            html += '<span class="file file-' + fileFamily + '">';
                            html += file.filename;
                            html += '</span>';
                        }
                        html += '</span>';

                        return html;
                    }).join(', '));
                    break;

                case 'dicom':
                    formatted = $sce.trustAsHtml(fieldValue.value.map(function(file) {
                        var html = '';
                        var fileFamily = $filter('fileFamily')(file.mimetype);

                        html += '<span class="sharecrf-field-file">';
                        if (file.url) {
                            html += '<a class="file file-' + fileFamily + '" href="' + file.url + '" target="_blank">';
                            html += file.id.substring(3);
                            html += '</a>';
                        } else {
                            html += '<span class="file file-' + fileFamily + '">';
                            html += file.id.substring(3);
                            html += '</span>';
                        }
                        html += '</span>';

                        return html;
                    }).join(', '));
                    break;

                case 'number':
                    formatted = fieldValue.value !== null ? '' + fieldValue.value : '';
                    break;

                case 'longtext':
                    formatted = stripTags(fieldValue.value.replace(/\n/g, '<br>'), '<br>');
                    break;

                case 'time':
                case 'text':
                default:
                    formatted = stripTags(fieldValue.value);
                    formatted = '' + fieldValue.value;
            }

            if (formatted === '') {
                formatted = '<i>(' + gettextCatalog.getString('Vacío') + ')</i>';
            }

            return formatted;
        };
    }]);
