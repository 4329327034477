(function(angular, _, moment) {
    'use strict';

    angular.module('security-redirect').provider('SecurityRedirect', function() {
        var provider = this;

        provider.url = '/';
        provider.parameter = 'warning';
        provider.defaultCode = 'session-expired';

        var serviceWatcher = null;
        var serviceCountdown = null;

        var getDuration = function(value) {
            var duration;

            var stringValue = '' + value;
            var match = /(\d+)(Y|M|d|h|m|s)?/.exec(stringValue);
            if (match) {
                var amount = match[1];
                var unit = match[2] || 's';

                duration = moment.duration(parseInt(amount), unit);
            }

            return duration;
        };

        var getMiliseconds = function(value) {
            var duration = getDuration(value);

            return duration && duration.as('ms');
        };

        var getSeconds = function(value) {
            var duration = getDuration(value);

            return duration && duration.as('s');
        };

        provider.$get = ['$log', '$window', '$cookies', '$interval', '$timeout', '$q', 'AccountManager', 'Modal', 'Event', 'Route', function($log, $window, $cookies, $interval, $timeout, $q, AccountManager, Modal, Event, Route) {
            var sessionSettings = {};
            var logged = false;

            var Service = {};

            Service.loadSessionSettings = function() {
                _.merge(sessionSettings, {
                    initied: null,
                    maxAge: null,
                    expiresWindowRefresh: null,
                    logoutWarningTime: null,
                }, $cookies.getObject('session_settings') || {});

                return Service;
            };

            Service.clearSessionSettings = function() {
                _.merge(sessionSettings, {
                    initied: null,
                    maxAge: null,
                    expiresWindowRefresh: null,
                    logoutWarningTime: null,
                });
            };

            Service.getRemainingTime = function(unit) {
                var milisecondsToExpire = (Service.getExpirationTime() * 1000) - moment.utc().valueOf();
                unit = unit || 's';

                return unit === 's' ? Math.round(milisecondsToExpire / 1000) : milisecondsToExpire;
            };

            Service.getExpirationTime = function() {
                // Para dar tiempo a hacer la llamada al logout con credenciales válidas, se dejan 5 segundos de margen
                // antes de expirar la sesión
                return sessionSettings.initied ? sessionSettings.initied + getSeconds(sessionSettings.maxAge) - 5 : null;
            };

            Service.isInAutoReloadSessionTime = function() {
                if (!sessionSettings.expiresWindowRefresh) {
                    return false;
                }

                return Service.getRemainingTime('s') < getSeconds(sessionSettings.expiresWindowRefresh);
            };

            Service.showMessage = function(countdownSeconds) {
                return Modal
                    .open({
                        className: 'expiration-message',
                        onlyActions: true,
                        controller: 'ExpirationMessageController',
                        controllerAs: '$ctrl',
                        template: 'html/expiration-message.html',
                        data: {
                            remaining: countdownSeconds,
                        },
                    })
                    .closePromise
                    .then(function(reload) {
                        if (reload) {
                            return Service.reloadSession();
                        }
                        else {
                            Service.cancelCountdown();
                            Service.initCountdown();
                        }
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            $log.error(error, 'reload session');
                            // Se ha producido un error al renovar sesión. Se continua como si no se hubiera hecho click
                            // en el botón de "estoy aquí" para que se cierre la sesión y se redirija a la pantalla de
                            // login
                        }

                        // Se ha llegado al final de la cuenta >> Logout + redirección
                        var promise = $q.resolve()
                            .then(function() {
                                if (AccountManager.isSessionOpen()) {
                                    return AccountManager.logout().catch(function(logoutError) {
                                        $log.error(logoutError, 'logout after expired session');
                                    });
                                }
                            });

                        if (Route.isSecure()) {
                            Modal.open({
                                template: 'html/expirated-session.html',
                                className: 'expiration-redirection',
                            });

                            promise = promise.then(Service.redirect);
                        }

                        return promise;
                    });
            };

            Service.initCountdown = function() {
                Service.loadSessionSettings();

                if (AccountManager.isSessionOpen()) {
                    var milisecondsToExpire = Service.getRemainingTime('ms');

                    serviceCountdown = $timeout(
                        Service.showMessage,
                        milisecondsToExpire - getMiliseconds(sessionSettings.logoutWarningTime),
                        true,
                        Math.round(Math.min(getSeconds(sessionSettings.logoutWarningTime), milisecondsToExpire / 1000))
                    );
                }
            };

            var lastInitiedValue = null;
            Service.initWatch = function() {
                if (!serviceWatcher) {
                    serviceWatcher = $interval(function() {
                        $timeout(function() {
                            Service.loadSessionSettings();

                            if (!logged && sessionSettings.initied) {
                                Service.cancelCountdown();
                                Service.cancelWatch();
                                $window.location.reload();
                            } else if (logged && !sessionSettings.initied) {
                                Service.cancelCountdown();
                                Service.cancelWatch();
                                $window.location = '/' + $window.ShareCRF.environment;
                            } else if (lastInitiedValue && lastInitiedValue != sessionSettings.initied) {
                                Service.cancelCountdown();
                                Service.initCountdown();
                            }

                            lastInitiedValue = sessionSettings.initied;
                        });
                    }, 1000);
                }
            };
            Service.cancelWatch = function() {
                if (serviceWatcher) {
                    $interval.cancel(serviceWatcher);
                    serviceWatcher = null;
                }
            };

            Service.cancelCountdown = function() {
                if (serviceCountdown) {
                    $timeout.cancel(serviceCountdown);
                    serviceCountdown = null;
                }
            };

            Service.reloadSession = function() {
                return AccountManager.reloadSession();
            };

            Service.redirect = function(code) {
                Service.cancelCountdown();
                Service.cancelWatch();
                $window.location = provider.url + '?' + provider.parameter + '=' + (code || provider.defaultCode);
            };

            Service.init = function() {
                Event.on('accountInitied', function() {
                    if (AccountManager.isSessionOpen()) {
                        Service.initCountdown();
                        logged = true;
                    }

                    Service.initWatch();
                });
                Event.on('beforeLogin', function() {
                    Service.cancelWatch();
                });
                Event.on('login', function() {
                    logged = AccountManager.isSessionOpen();
                    Service.initCountdown();
                    Service.initWatch();
                });
                Event.on('logout', function() {
                    Service.cancelCountdown();
                    Service.clearSessionSettings();
                    logged = false;
                });

                Event.on('session', function() {
                    Service.cancelCountdown();
                    Service.initCountdown();
                });
            };

            return Service;
        }];
    });
})(this.angular, this._, this.moment);
