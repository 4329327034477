'use strict';

angular.module('route')
    .provider('Route', function() {
        var securedRoutes = [];

        this.create = function(name, options) {
            // Store state name
            options.name = name;

            // Is secured
            var secure = typeof options.secure === 'undefined' ? true : !!options.secure;
            if (options.permission) {
                secure = true;
            }

            if (secure) {
                securedRoutes.push(options.name);
            }

            // Route resolvers
            options.resolve = options.resolve || {};

            // Get logged user
            options.resolve.loggedUser = ['RouteAuth', function(RouteAuth) {
                return RouteAuth.init()
                    .then(function() {
                        return RouteAuth.getLoggedUser();
                    });
            }];

            // If route is secure and is not defined any redirection if user is not logged >> Error
            if (secure) {
                options.resolve.checkUserIsLogged = ['$q', 'RouteAuth', 'loggedUser', 'RouteError', function($q, RouteAuth, loggedUser, RouteError) {
                    if (!RouteAuth.isLogged()) {
                        var error = new RouteError('authentication-required');

                        return $q.reject(error);
                    }
                }];
            }

            // Check user permission
            if (options.permission) {
                options.resolve.checkPermission = ['$q', 'RouteAuth', 'loggedUser', 'RouteError', 'checkUserIsLogged', function($q, RouteAuth, loggedUser, RouteError, checkUserIsLogged) {
                    var hasPermission = false;

                    if (Array.isArray(options.permission)) {
                        hasPermission = options.permission.every(function(singlePermission) {
                            return RouteAuth.hasPermission(singlePermission);
                        });
                    }
                    else {
                        hasPermission = RouteAuth.hasPermission(options.permission);
                    }

                    if (!hasPermission) {
                        var error = new RouteError('permission-required');
                        error.info = {
                            permission: options.permission,
                        };

                        return $q.reject(error);
                    }
                }];
            }

            // Params
            options.params = options.params || {};
            var params = options.bindParams || [];
            params.reduce(function(previous, paramName) {
                options.params[paramName] = {
                    type: 'string',
                    value: null,
                    squash: true,
                };

                options.resolve[paramName] = ['$transition$', 'RouteMessages', function($transition$, RouteMessages) {
                    var transitionParams = $transition$.params();
                    var paramValue = transitionParams[paramName];

                    return paramValue ? RouteMessages.getHumanReadableMessage(paramValue) : null;
                }];
            }, null);

            return options;
        };

        this.$get = ['$state', function($state) {
            return {
                isSecure: function(routeName) {
                    if (!routeName) {
                        routeName = $state.current.name;
                    }

                    return securedRoutes.indexOf(routeName) !== -1;
                },
            };
        }];
    });
