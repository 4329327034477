'use strict';

angular.module('study.export')
    .config(['$stateProvider', 'RouteProvider', function($stateProvider, RouteProvider) {
        $stateProvider.state(RouteProvider.create('export', {
            url: '/export',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Exportar datos');
            }],
            component: 'exportForm',
            permission: 'export records',
        }));
    }]);
