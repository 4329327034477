angular.module('ecrd').factory('ViewMode', [
    'ViewModes',
    'Event',
    function(ViewModes, Event) {
        var currentMode = ViewModes.EDIT;

        function isValid(candidate) {
            return [ViewModes.EDIT, ViewModes.REVISION].indexOf(candidate) > -1;
        }

        function setMode(mode) {
            if (isValid(mode)) {
                var changed = currentMode !== mode;
                currentMode = mode;

                if (changed) {
                    Event.trigger('viewModeChanged', currentMode);
                }
            }

            return currentMode;
        }

        return {
            setRevisionMode: function () {
                return setMode(ViewModes.REVISION);
            },
            reset: function() {
                return setMode(ViewModes.EDIT);
            },
            get: function() {
                return currentMode;
            },
        };
    },
]);