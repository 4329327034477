angular.module('sharecrf.progress-status').factory('ProgressStatus', [
    'RecordService',
    'CrdConfigurationService',
    function(RecordService, CrdConfigurationService) {
        var data = {
            completedPercent: 0,
            missingPercent: 0,
            missing: 0,
        };

        function reload() {
            var recordStats = RecordService.get().stats || {};

            data.completedPercent = Math.floor(recordStats.rfnfp || 0);

            if (CrdConfigurationService.hasMissingData()) {
                data.missingPercent = Math.floor(recordStats.rmfp || 0);
                data.missing = recordStats.rmfn;
            }
        }

        function reset() {
            data.completedPercent = 0;
            data.missingPercent = 0;
            data.missing = 0;
        }

        return {
            get: function() {
                return data;
            },
            init: function() {
                reload();
            },
            reload: function() {
                reload();
            },
            clear: function () {
                reset();
            },
        };
    },
]);
