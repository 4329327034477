angular.module('ecrd').controller('EntityEditIdController', [
    '$state',
    'gettextCatalog',
    'RecordService',
    'ShareCrfSDK',
    'InetsysMessages',
    'ErrorMessages',
    function($state, gettextCatalog, RecordService, ShareCrfSDK, InetsysMessages, ErrorMessages) {
        var vm = this;

        var recordId = RecordService.getId();
        vm.editableUId = RecordService.getUId();

        vm.saving = false;

        vm.saveEntityId = function(form) {
            if (form && form.$invalid) return;

            vm.saving = true;

            return ShareCrfSDK.ecrd.updateRecordUId(recordId, vm.editableUId)
                .then(function() {
                    vm.saving = false;

                    InetsysMessages.success({
                        title: gettextCatalog.getString('Se ha actualizado el ID único'),
                    });

                    $state.go('record.edit.id', {
                        recordId: recordId,
                    }, {
                        reload: true,
                    });
                })
                .catch(function(error) {
                    vm.saving = false;

                    InetsysMessages.error({
                        title: gettextCatalog.getString('Se ha producido un error al actualizar el identificador único'),
                        body: ErrorMessages.get(error),
                    });
                });
        };
    },
]);
