angular.module('ecrd.record.bulk').controller('RecordBulkCertcopyController', [
    'CrdConfigurationService',
    'RecordsManagerService',
    'ShareCrfApiErrorCodes',
    function (CrdConfigurationService, RecordsManagerService, ShareCrfApiErrorCodes) {
        var vm = this;

        // Operaciones disponibles según permisos y configuración de estados. Solamente se permiten descargar ficheros
        // asociados a un cambio de estado
        vm.availableOperations = [];

        // Estado de carga: idle/loading/ready/error
        vm.status = 'idle';

        // El estado seleccionado por el usuario
        vm.selectedState = null;

        // El nombre del estado seleccionado, para aparecer en la plantilla
        vm.stateName = null;

        // Objeto de resultados devuelto por la API
        vm.result = null;

        // El mensaje de error
        vm.error = null;

        // Para indicar los resultados
        vm.entityLabel = CrdConfigurationService.getLabelPlural();

        vm.$onInit = function() {
            initAvailableOperations();
        };

        function initAvailableOperations() {
            vm.availableOperations = [];

            angular.forEach(CrdConfigurationService.getStates().getStates(), function (state) {
                if (state.requiresCertifiedCopy()) {
                    vm.availableOperations.push({
                        id: state.getId(),
                        name: state.getName(),
                    });
                }
            });
        }

        vm.onSelectState = function(state) {
            vm.stateName = state.name;
        };

        vm.bulkDownload = function () {
            vm.status = 'loading';
            vm.result = null;

            var records = vm.records.map(function(record) {
                return record.id;
            });

            RecordsManagerService.bulkCertcopy({
                records: records,
                state: vm.selectedState,
            })
                .then(function(result) {
                    vm.result = result.data;
                    vm.status = 'ready';
                })
                .catch(function(error) {
                    vm.status = 'error';
                    vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);
                });
        };
    },
]);