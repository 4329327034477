angular.module('app.entity.field').component('fieldActions', {
    templateUrl: 'modules/ecrd/components/field/field-actions/content.html',
    controller: 'FieldActionsController',
    bindings: {
        fieldId: '@',
        open: '<',
        selectedAction: '<',
        disableNotes: '@',
        onToggleMenu: '&',
        onResetValue: '&',
        onSelectAction: '&',
        onChangeValue: '&',
    },
});
