'use strict';

angular.module('ecrd')
    .factory('RecordValidationService', ['CoreRecordValidation', function RecordValidationService(CoreRecordValidation) {
        var publicAPI = {};
        // 1. Custom functions

        // 2. Inherited public
        Object.getOwnPropertyNames(Object.getPrototypeOf(CoreRecordValidation)).forEach(function(key) {
            if (typeof CoreRecordValidation[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                if (typeof publicAPI[key] != 'undefined') {
                    throw new Error("Function "+key+" is present in Core object");
                }
                publicAPI[key] = CoreRecordValidation[key].bind(CoreRecordValidation);
            }
        });

        publicAPI.events = CoreRecordValidation.events;

        return publicAPI;
    }]);
