angular.module('sharecrf.unknown-value').component('unknownValueSwitcher', {
    bindings: {
        fieldId: '<',
        fieldValue: '<',
        disabled: '<',
        onChange: '&',
    },
    templateUrl: 'modules/unknown-value/components/switcher/template.html',
    controller: [
        '$stateParams',
        'UNKNOWN_VALUE',
        'UnknownService',
        'RecordDataService',
        'CrfService',
        'Event',
        '$injector',
        function($stateParams, UNKNOWN_VALUE, UnknownService, RecordDataService, CrfService, Event, $injector) {
            var vm = this;
            var field = null;
            var uid = null;
            var listIndices = CrfService.getListIndices($stateParams);

            var EncryptedFieldsVisibility = $injector.has('EncryptedFieldsVisibility') ? $injector.get('EncryptedFieldsVisibility') : null;
            var encryptedFieldVisibleHandler = null;

            vm.$onInit = function() {
                field = CrfService.getField(vm.fieldId);
                uid = RecordDataService.getElementUID(vm.fieldId, listIndices);

                vm.visible = field.isEncrypted()
                    ? EncryptedFieldsVisibility !== null && EncryptedFieldsVisibility.get(vm.fieldId, listIndices)
                    : true;

                encryptedFieldVisibleHandler = Event.on('encryptedFieldVisible', function(event, fieldUid) {
                    if (uid.join('.') === fieldUid.join('.')) {
                        vm.visible = true;
                    }
                });

                vm.unknownConfig = UnknownService.getConfig();
            };

            vm.$onDestroy = function() {
                if (angular.isFunction(encryptedFieldVisibleHandler)) {
                    encryptedFieldVisibleHandler();
                }
            };

            vm.isUnknownValue = function() {
                return vm.fieldValue === UNKNOWN_VALUE;
            };

            vm.onChangeUnknownValue = function() {
                if (vm.disabled) return;

                var newValue = vm.isUnknownValue()
                    ? getResetValue(vm.fieldId)
                    : UNKNOWN_VALUE;

                vm.onChange({
                    fieldValue: newValue,
                });
            };

            function getResetValue(fieldId) {
                return RecordDataService.getResetValue(fieldId);
            }
        },
    ],
});
