/* eslint-disable angular/definedundefined */
angular.module('ecrd').factory('FieldsCompletionService', [
    'CoreFieldsCompletion',
    function FieldsCompletionService(CoreFieldsCompletion) {
        var publicAPI = {};
        // 1. Custom functions

        // 2. Inherited public
        Object.getOwnPropertyNames(Object.getPrototypeOf(CoreFieldsCompletion)).filter(function(key) {
            if (typeof CoreFieldsCompletion[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                if (typeof publicAPI[key] !== 'undefined') {
                    throw new Error('Function ' + key + ' is present in Core object');
                }
                publicAPI[key] = CoreFieldsCompletion[key].bind(CoreFieldsCompletion);
            }
        });

        return publicAPI;
    },
]);
