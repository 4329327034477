angular.module('app.entity.field').controller('FieldEncryptedController', [
    'AccountManager',
    'CrfService',
    '$stateParams',
    'EncryptedFieldsVisibility',
    'Event',
    'RecordDataService',
    function(
        AccountManager,
        CrfService,
        $stateParams,
        EncryptedFieldsVisibility,
        Event,
        RecordDataService
    ) {
        var vm = this;

        vm.canViewEncryptedFields = false;
        vm.originalValueVisible = false;

        var encryptedFieldVisibleHandler = null;

        var listIndices = CrfService.getListIndices($stateParams);

        vm.$onInit = function() {
            var uid = RecordDataService.getElementUID(vm.fieldId, listIndices);

            AccountManager.init().then( function() {
                vm.canViewEncryptedFields = AccountManager.hasPermission('view encrypted fields');

                if (EncryptedFieldsVisibility.get(vm.fieldId, listIndices)) {
                    vm.originalValueVisible = true;

                } else if (!EncryptedFieldsVisibility.isEncryptable(vm.fieldValue)
                    && vm.canViewEncryptedFields) {
                    vm.displayOriginalValue();
                }

                encryptedFieldVisibleHandler = Event.on('encryptedFieldVisible', function(event, fieldUid) {
                    if (uid.join('.') === fieldUid.join('.')) {
                        vm.originalValueVisible = true;
                    }
                });
            });
        };

        vm.$onDestroy = function() {
            if (angular.isFunction(encryptedFieldVisibleHandler)) {
                encryptedFieldVisibleHandler();
            }
        };

        vm.displayOriginalValue = function() {
            vm.originalValueVisible = true;
            EncryptedFieldsVisibility.set(vm.fieldId, listIndices);
        };
    },
]);
