angular.module('sharecrf.unknown-value')
    .provider('UnknownService', function() {
        var _unknown = {};

        /**
         * Setter propiedades de campo "No disponible"
         * @param {Object} unknown - Objeto con las propiedades label y tooltip
         */
        this.setConfig = function(unknown) {
            _unknown = unknown;
        };

        this.$get = ['gettextCatalog', function(gettextCatalog) {

            if (!_unknown.label) {
                _unknown.label = gettextCatalog.getString('N/A');
            }

            return {
                getConfig: function() {
                    return _unknown;
                },
            };
        }];
    });
