'use strict';

angular.module('sharecrf.common', [
  'ui.select',
  'gettext',
])
.factory('upload_to', ['Upload', '$timeout', function(Upload, $timeout) {
  return function upload_to($scope, conf, path, callback) {
    var file = conf, data = {};
    if ('file' in conf) {
      file = conf.file;
      data = conf;
    }

    file.upload = Upload.upload({
      url: path,
      method: 'POST',
      sendFieldsAs: 'form',
      file: file,
      data: data,
      fileFormDataName: 'file',
    });

    file.upload.then(function(response) {
      $timeout(function() {
        file.result = response.data;
        callback(response.data);
      });
    }, function(response) {
      if (response.status > 0) {
        $scope.errorMsg = response.status + ': ' + response.data;
      }
    });

    file.upload.progress(function(evt) {
      // Math.min is to fix IE which reports 200% sometimes
      file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
    });

    file.upload.xhr(function(xhr) {
    });
  };
}])
.config(['uiSelectConfig', function(uiSelectConfig) {
  uiSelectConfig.theme = 'select2';
}])
.filter('long_date', function() {
  return function(text) {
    var d = moment(text);

    return !d.isValid() ? text : d.format('LLL');
  };
})
.filter('lldate', function() {
  return function(text) {
    if (!text) {
      return '';
    }

    var d = moment(text);

    return !d.isValid() ? text : d.format('LL');
  };
})
.filter('llldate', function() {
  return function(text) {
    if (!text) {
      return '';
    }

    var d = moment(text);

    return !d.isValid() ? text : d.format('LLL');
  };
})
.filter('nl2br', ['$sce', function($sce) {
  return function(text) {
    return text ? $sce.trustAsHtml(text.replace(/\n/g, '<br>')) : '';
  };
}])
.filter('bytes', function() {
  return function(bytes, precision) {
    if (bytes === 0 || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    if (typeof precision === 'undefined') precision = 1;
    var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
      number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
  };
})
// update google analytics when change ui-router state
.run(['$transitions', '$location', '$window', function($transitions, $location, $window) {
  $transitions.onSuccess({}, function() {
    if (!$window.ga) {
      return;
    }

    $window.ga('send', 'pageview', {
      page: $location.path()
    });
  });
}]);
