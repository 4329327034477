'use strict';

angular.module('ecrd.core')
    .provider('CoreEcrd', function() {
        var provider = this;
        provider.definition = {};

        provider.$get = ['$window', function CoreEcrd($window) {
            return new $window.ShareCrfCore.Ecrd(provider.definition);
        }];
    });
