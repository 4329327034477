'use strict';

angular.module('study.placeholders')
    .controller('PlaceholderEntityListTableController', ['$scope', 'CrfService', 'RecordDataService', function($scope, CrfService, RecordDataService) {
        var vm = this;

        var stateParams = $scope.stateParams || {};
        var listName = $scope.listName;
        var list = CrfService.getElementByName(listName);
        var listIndices = CrfService.getListIndices(stateParams);

        vm.data = list ? RecordDataService.getList(list.getId(), listIndices) : [];

        vm.availableFields = {};
        vm.availableFieldsCount = 0;

        angular.forEach(list.getListableFields(), function(field) {
            var fieldId = field.getId();

            vm.availableFields[fieldId] = CrfService.isElementAvailableToCurrentProfile(fieldId);
            if (vm.availableFields[fieldId]) {
                vm.availableFieldsCount++;
            }
        });
    }]);
