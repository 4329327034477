angular.module('scrf.notifications').component('studyNotificationsTable', {
    templateUrl: 'modules/notifications/components/table/template.html',
    controller: [
        '$exceptionHandler',
        'TableItems',
        'ShareCrfSDK',
        'ShareCrfApiErrorCodes',
        'Modal',
        'CrdConfigurationService',
        function($exceptionHandler, TableItems, ShareCrfSDK, ShareCrfApiErrorCodes, Modal, CrdConfigurationService) {
            var vm = this;

            vm.error = false;
            vm.notifications = [];

            vm.table = {
                loading: true,
                error: false,
                count: 0,
                offset: 0,
                limit: TableItems.getSelectedOption('notifications'),
                currentPage: 0,
                orderBy: 'createdAt',
                orderReverse: true,
                filters: {
                },
            };

            vm.entityLabel = '';

            vm.$onInit = function() {
                vm.entityLabel = CrdConfigurationService.getLabelSingular();
            };

            vm.get = function() {
                vm.table.loading = true;
                vm.table.error = false;

                var params = parseTableSettings(vm.table);

                return ShareCrfSDK.notifications.list(params)
                    .then(function(queriesData) {
                        vm.notifications = queriesData.rows;
                        vm.table.count = queriesData.count;

                        vm.table.loading = false;
                    })
                    .catch(function(error) {
                        vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                        vm.table.loading = false;
                        vm.table.error = true;

                        $exceptionHandler(error);
                    });
            };

            vm.showMessage = function(notification) {
                return openModal(notification, 'modules/notifications/modal/details-message.html');
            };

            vm.showChannel = function(notification) {
                return openModal(notification, 'modules/notifications/modal/details-channel.html');
            };

            function parseTableSettings(settings) {
                var filters = [];
                var order = '';

                if (settings.orderBy) {
                    order = settings.orderBy + ' ' + (settings.orderReverse ? 'desc' : 'asc');
                }

                var params = {
                    limit: settings.limit,
                    offset: settings.currentPage * settings.limit,
                };
                if (filters.length) {
                    params.filters = filters.join(' and ');
                }
                if (order) {
                    params.order = order;
                }

                return params;
            }

            function openModal(notification, templateUrl) {
                return Modal.open({
                    templateUrl: templateUrl,
                    data: {
                        notification: notification,
                    },
                }).closePromise.catch($exceptionHandler);
            }
        },
    ],
});
