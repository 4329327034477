'use strict';

angular.module('ecrd')
    .factory('RecordRulesService', ['CoreRecordRules', function RecordRulesService(CoreRecordRules) {
        var publicAPI = {};
        // 1. Custom functions

        // 2. Inherited public
        Object.getOwnPropertyNames(Object.getPrototypeOf(CoreRecordRules)).forEach(function(key) {
            if (typeof CoreRecordRules[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                if (typeof publicAPI[key] != 'undefined') {
                    throw new Error("Function "+key+" is present in Core object");
                }
                publicAPI[key] = CoreRecordRules[key].bind(CoreRecordRules);
            }
        });

        return publicAPI;
    }]);
