'use strict';

angular.module('inetsys.conditions', [])
.constant('_', window._)
.factory('conditions', ['_', function conditionsFactory(_) {
    /**
     * Compara dos valores en una expresión, para determinar si son iguales
     * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
     * @param  {mixed} valueRight      Valor a la derecha de la comparación
     * @return {boolean}               Resultado de la comparación
     */
    function equal(valueLeft, valueRight) {
        return _.isEqual(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si son distintos
     * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
     * @param  {mixed} valueRight      Valor a la derecha de la comparación
     * @return {boolean}               Resultado de la comparación
     */
    function notEqual(valueLeft, valueRight) {
        return conditions.equal(valueLeft, valueRight) ? false : true;
    }

    /**
     * Determina si el valor se considera vacío, es decir que no tiene valor
     * o tiene un valor vacío
     * @param  {mixed}   value Valor a comprobar
     * @return {boolean}       Resultado de la comprobación
     */
    function empty(value) {
        if (_.isDate(value)) {
            return false;
        }

        if (_.isObject(value) || _.isString(value)) {
            return _.isEmpty(value);
        }

        return _.isNil(value) || _.isNaN(value);
    }

    /**
     * Determina si el valor se considera no vacío, es decir que tiene valor
     * y éste no está vacío
     * @param  {mixed}   value Valor a comprobar
     * @return {boolean}       Resultado de la comprobación
     */
    function notEmpty(value) {
        return conditions.empty(value) ? false : true;
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * es mayor o igual que el derecho
     * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
     * @param  {mixed} valueRight      Valor a la derecha de la comparación
     * @return {boolean}               Resultado de la comparación
     */
    function greaterThanOrEqual(valueLeft, valueRight) {
        return conditions.notEmpty(valueLeft) && conditions.notEmpty(valueRight) && _.gte(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * es estrictamente mayor que el derecho
     * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
     * @param  {mixed} valueRight      Valor a la derecha de la comparación
     * @return {boolean}               Resultado de la comparación
     */
    function greaterThan(valueLeft, valueRight) {
        return conditions.notEmpty(valueLeft) && conditions.notEmpty(valueRight) && _.gt(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * es estrictamente menor que el derecho
     * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
     * @param  {mixed} valueRight      Valor a la derecha de la comparación
     * @return {boolean}               Resultado de la comparación
     */
    function lessThan(valueLeft, valueRight) {
        return conditions.notEmpty(valueLeft) && conditions.notEmpty(valueRight) && _.lt(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * es menor o igual que el derecho
     * @param  {mixed} valueLeft       Valor a la izquierda de la comparación
     * @param  {mixed} valueRight      Valor a la derecha de la comparación
     * @return {boolean}               Resultado de la comparación
     */
    function lessThanOrEqual(valueLeft, valueRight) {
        return conditions.notEmpty(valueLeft) && conditions.notEmpty(valueRight) && _.lte(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * contiene al derecho
     * @param  {string} valueLeft  Valor a la izquierda de la comparación
     * @param  {string} valueRight Valor a la derecha de la comparación
     * @return {boolean}           Resultado de la comparación
     * @todo   Documentar los parámetros de estas funciones
     */
    function contains(valueLeft, valueRight) {
        return _.includes(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * no contiene al derecho
     * @param  {string} valueLeft  Valor a la izquierda de la comparación
     * @param  {string} valueRight Valor a la derecha de la comparación
     * @return {boolean}           Resultado de la comparación
     * @todo   Documentar los parámetros de estas funciones
     * @todo   Si el valor es nulo, ¿debería devolver true?
     */
    function notContains(valueLeft, valueRight) {
        return conditions.contains(valueLeft, valueRight) ? false : true;
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * comienza por el derecho
     * @param  {string} valueLeft  Valor a la izquierda de la comparación
     * @param  {string} valueRight Valor a la derecha de la comparación
     * @return {boolean}           Resultado de la comparación
     * @todo   Documentar los parámetros de estas funciones
     */
    function startsWith(valueLeft, valueRight) {
        return _.startsWith(valueLeft, valueRight);
    }

    /**
     * Compara dos valores en una expresión, para determinar si el izquierdo
     * finaliza por el derecho
     * @param  {string} valueLeft  Valor a la izquierda de la comparación
     * @param  {string} valueRight Valor a la derecha de la comparación
     * @return {boolean}           Resultado de la comparación
     * @todo   Documentar los parámetros de estas funciones
     */
    function endsWith(valueLeft, valueRight) {
        return _.endsWith(valueLeft, valueRight);
    }

    var conditions = {
        equal: equal,
        notEqual: notEqual,
        greaterThanOrEqual: greaterThanOrEqual,
        greaterThan: greaterThan,
        lessThan: lessThan,
        lessThanOrEqual: lessThanOrEqual,
        empty: empty,
        notEmpty: notEmpty,
        contains: contains,
        notContains: notContains,
        startsWith: startsWith,
        endsWith: endsWith,
    };

    return conditions;
}]);
