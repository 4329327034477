'use strict';

angular.module('study.import')
    .controller('ImportCreateCSVController', ['ShareCrfSDK', '$log', '$sce', '$window', 'gettext', 'gettextCatalog', function(ShareCrfSDK, $log, $sce, $window, gettext, gettextCatalog) {
        var vm = this;

        vm.options = {
            delimiter: 'COMMA',
            quote: 'DQUOTE',
            multiple_fields: 'SINGLE',
        };

        vm.delimiterOptions = [
            {id: 'COMMA', 'label': gettext('coma')},
            {id: 'SEMICOLON', 'label': gettext('punto y coma')},
        ];

        vm.quoteOptions = [
            {id: 'SQUOTE', 'label': gettext('comillas simples')},
            {id: 'DQUOTE', 'label': gettext('comillas dobles')},
        ];

        vm.multipleFieldOptions = [
            {id: 'SINGLE', 'label': gettext('Columna única')},
            {id: 'SPLIT', 'label': gettext('Columnas separadas')},
        ];

        vm.generating = false;
        vm.messages = {
            error: null,
            info: null,
        };

        vm.submit = function() {
            vm.generating = true;
            vm.messages = {error: null, info: null};

            return ShareCrfSDK.import.generate(vm.options)
                .then(function(downloadUrl) {
                    var linkElement = $window.document.createElement('a');
                    try {
                        linkElement.setAttribute('href', downloadUrl);
                        // linkElement.setAttribute('download', 'volcado.zip');
                        var clickEvent = new MouseEvent('click', {
                            'view': $window,
                            'bubbles': true,
                            'cancelable': false,
                        });
                        linkElement.dispatchEvent(clickEvent);

                        vm.messages.info = gettextCatalog.getString('El modelo CSV se está descargando en su equipo');
                    } catch (error) {
                        $log.error(error, 'auto download template to import data');
                        vm.messages.info = $sce.trustAsHtml(gettextCatalog.getString('Puede descargar el modelo en CSV desde el siguiente enlace:') + ' <a target="_blank" href="' + downloadUrl + '">' + gettextCatalog.getString('Descargar modelos CSV') + '</a>');
                    }
                    vm.generating = false;
                })
                .catch(function(error) {
                    vm.messages.error = gettext('No se ha podido generar el modelo de fichero debido a un error');
                    vm.generating = false;

                    throw error;
                });
        };
    }]);
