angular.module('sharecrf.internal-value').component('internalValueFormatter', {
    bindings: {
        value: '<',
    },
    transclude: true,
    templateUrl: 'modules/internal-value/components/formatter/template.html',
    controller: [
        'UNKNOWN_VALUE',
        'MISSING_VALUE',
        'UnknownService',
        function(UNKNOWN_VALUE, MISSING_VALUE, UnknownService) {
            var vm = this;

            vm.isUndefined = false;
            vm.isMissing = false;
            vm.value = null;
            vm.unknownConfig = {};

            vm.$onInit = function() {
                vm.unknownConfig = UnknownService.getConfig();
            };

            vm.$onChanges = function() {
                vm.isUndefined = vm.value === UNKNOWN_VALUE;
                vm.isMissing = vm.value === MISSING_VALUE;
            };
        },
    ],
});
