'use strict';

angular.module('error')
    .controller('InfoMissingFieldsController', [
        'CrdConfigurationService',
        'RecordDataService',
        'CrfService',
        '$state',
        'FormFieldsService',
        'MissingDataService',
        function(
            CrdConfigurationService,
            RecordDataService,
            CrfService,
            $state,
            FormFieldsService,
            MissingDataService
        ) {
            var vm = this;

            vm.entitySingular = CrdConfigurationService.getLabelSingular();
            vm.hasUnavailableFields = false;
            vm.missingDataFields = [];

            var getStateName = function(field) {
                var state = 'record.edit.data';
                var form = field.getForm();
                var lists = field.getParentLists();
                angular.forEach(lists, function(list) {
                    state = state + '.' + list.getPathFromList().join('.') + '.item';
                });

                return state + '.' + form.getPathFromList().join('.');
            };

            if (CrdConfigurationService.hasMissingData()) {

                var missingReasons = MissingDataService.export();

                for (var uid in missingReasons) {

                    var field = CrfService.getElementByUID(uid);
                    if (!field) {
                        // Si no se ejecuta la integridad de datos, en el record.missing pueden llegar campos que ya no existen en el paciente
                        continue;
                    }
                    var fieldId = field.getId();
                    var listIndices = RecordDataService.getListIndicesByUID(uid);
                    var stateParams = CrfService.getNamedListIndices(listIndices);

                    if (!CrfService.isElementAvailableToCurrentProfile(fieldId)) {
                        vm.hasUnavailableFields = true;
                        continue;
                    }

                    vm.missingDataFields.push({
                        uid: uid,
                        reason: missingReasons[uid],
                        label: RecordDataService.getElementFullLabeledPathByUID(uid),
                        stateHref: $state.href(getStateName(field), stateParams),
                        hidden: !FormFieldsService.isElementVisible(fieldId, listIndices),
                    });
                }
            }
        },
    ]);
