'use strict';

angular.module('error')
.controller('SimpleMessageController', ['ErrorMessages', function(ErrorMessages) {
    /**
     * Bindings
     *
     * {string} vm.level - tipo de mensaje 'error', 'warning', 'info'
     * {string} vm.text - texto del mensaje
     * {string} vm.code - código de error que puede transformarse en un mensaje legible
     */
    var vm = this;
    vm.$onInit = function() {
        if (vm.code) {
            vm.text = ErrorMessages.get(vm.code)
        }
    }
}]);
