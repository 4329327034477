'use strict';

angular.module('app.entity.common')
    .controller('EntityModalSignatureNeededController', ['$scope', 'gettext', function($scope, gettext) {
        var vm = this;

        vm.password = null;

        if ($scope.ngDialogData && $scope.ngDialogData.message) {
            vm.message = $scope.ngDialogData.message;
        } else {
            vm.message = gettext('Por motivos de seguridad, para completar esta acción debe introducir su contraseña');
        }

        vm.cancel = function() {
            return $scope.dismissThisDialog();
        };

        vm.submit = function() {
            if (vm.password) {
                $scope.closeThisDialog(vm.password);
            }
        };
    }]);
