/* eslint-disable angular/foreach */
/* eslint-disable angular/document-service */
/* eslint-disable angular/definedundefined */
angular.module('app.entity.menu').controller('EntityMenuController', [
    '$scope',
    '$timeout',
    '$transitions',
    '$state',
    '$stateParams',
    'EntityMenu',
    'CrfService',
    'RecordDataService',
    'RecordRulesService',
    'FormFieldsService',
    'Event',
    'RecordValidationService',
    'ProgressStatus',
    'ListItemsService',
    function($scope, $timeout, $transitions, $state, $stateParams, EntityMenu, CrfService, RecordDataService, RecordRulesService, FormFieldsService, Event, RecordValidationService, ProgressStatus, ListItemsService) {
        var vm = this;

        // Progress status bar
        vm.progressStatus = ProgressStatus.get();

        // Flag to manage menu visibility
        vm.asideMenuVisible = false;
        vm.toggleVisibility = function() {
            vm.asideMenuVisible = !vm.asideMenuVisible;
        };

        // Servicios auxiliares, que también usan las plantillas HTML
        vm.recordData = RecordDataService;
        vm.crf = RecordDataService.getCRF();

        // Menu items
        vm.menuItems = EntityMenu.get();
        $scope.$watch(function() {
            return EntityMenu.get();
        }, function(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                vm.menuItems = newValue;
            }
        });

        vm.getDataListLength = function(listId, stateParams) {
            var listIndices = vm.crf.getListIndices(stateParams);

            return vm.recordData.getListLength(listId, listIndices);
        };

        vm.getDataList = function(listId, stateParams) {
            var listIndices = vm.crf.getListIndices(stateParams);

            return vm.recordData.getList(listId, listIndices);
        };

        // Toggle expand flag
        vm.toggleExpand = function(element, $event) {
            element.expanded = !element.expanded;

            // Evita que el click en el span "desplegar" redirija al estado del enlace que lo contiene
            $event.stopPropagation();
            $event.preventDefault();
        };

        // Tasks scheduler

        var formTasks = {};
        var formTasksTimeout = null;
        function queueFormTask(event, formId, stateParams, payload) {
            if (!Object.keys(formTasks).length) {
                executeFormTasksInNextTick();
            }

            var taskKey = buildTaskKey(event, formId, stateParams);
            // console.info('QUEUE', taskKey);
            if (!formTasks[taskKey]) {
                var task = {
                    event: event,
                    formId: formId,
                    stateParams: stateParams,
                    payload: payload,
                };

                formTasks[taskKey] = task;
            }
        }
        function executeFormTasksInNextTick() {
            formTasksTimeout = $timeout(function() {
                for (var taskKey in formTasks) {
                    // console.info('EXEC', taskKey);
                    var task = formTasks[taskKey];
                    EntityMenu.sectionOn(task.event, task.formId, task.stateParams, task.payload);
                }

                formTasksTimeout = null;
                formTasks = {};
            });
        }
        function buildTaskKey(event, formId, stateParams) {
            var key = event + '|' + formId;
            key += '|' + Object.keys(stateParams).map(function(listId) {
                return '' + listId + '-' + stateParams[listId];
            }).join(',');

            return key;
        }

        // GARU-7951
        // Comprobar si el elemento de lista acaba de ser creado
        // para indicarlo en la plantilla con el caracter *
        vm.isCreatedElement = function(listId, itemUid) {
            var listIndices = CrfService.getListIndices($stateParams);
            var listUId = RecordDataService.getElementUID(listId, listIndices) || [];

            var listItemUid = listUId.concat(itemUid).join('.');

            var contains = ListItemsService.containsElement(listItemUid);

            return contains;
        };

        // Events

        // Lanzamos el evento de cambio de estado, para el caso de que entremos por URL directamente en una sección
        // En este caso, la resolución del estado finaliza antes de que se cargue este controlador, y el
        // $transitions.onSuccess que hay después no llega a tiempo
        var currentState = $state.current;
        var currentParams = $stateParams;
        var currentElementId = currentState.data && currentState.data.elementId;
        currentElementId && queueFormTask('RouteEnter', currentElementId, currentParams);

        // Update menu flags after changing the flag 'dirty' of a form
        $scope.$on('EntityFormDirtyFlagUpdated', function(event, params) {
            var menuEvent = 'EntityFormDirtyFlagUpdated';
            var formId = params.formId;
            var stateParams = params.stateParams || {};
            var dirtyValue = !!params.dirty;

            if (dirtyValue) {
                queueFormTask(menuEvent, formId, stateParams, []);
            }
        });

        // Update menu flags after updating notes
        $scope.$on('EntityNotesUpdated', function(event) {
            // Trigger plugin actions
            // console.info('EntityNotesUpdated', arguments);
            EntityMenu.on('EntityNotesUpdated');
        });

        var eventListeners = [
            // Update menu flags after redirecting to a state
            $transitions.onSuccess({}, function(transition) {
                // Hide menu
                vm.asideMenuVisible = false;

                var fromState = transition.$from();
                var fromParams = transition.params('from');
                var fromElementId = fromState.data && fromState.data.elementId;

                var toState = transition.$to();
                var toParams = transition.params();
                var toElementId = toState.data && toState.data.elementId;

                fromElementId && queueFormTask('RouteExit', fromElementId, fromParams, transition);
                toElementId && queueFormTask('RouteEnter', toElementId, toParams, transition);

                // Trigger plugin actions
                // EntityMenu.on('StateChangeSuccess', transition.$to);

                // GARU-1830 Scroll to top
                // GARU-2033 FIX para firefox >> https://stackoverflow.com/questions/8149155/animate-scrolltop-not-working-in-firefox/8149216#8149216
                document.getElementsByTagName('body')[0].scrollTop = 0;
                document.getElementsByTagName('html')[0].scrollTop = 0;
            }),
            // Actualización manual del valor de un campo
            Event.on('updatedRecordFieldValue', function(event, recordData, fieldId, payload) {
                var menuEvent = 'UpdatedRecordFieldValue';
                var formId = payload.formId;
                var stateParams = payload.stateParams;

                queueFormTask(menuEvent, formId, stateParams, payload);
            }),
            // Actualización de un flag de un campo
            FormFieldsService.events.on('elementFlagChanged', function(payload) {
                if (!!payload.oldValue !== !!payload.newValue) {
                    var fieldId = payload.elementId;
                    var field = CrfService.getField(fieldId);
                    if (!field) {
                        return;
                    }

                    var flag = payload.flag;
                    var menuEvent = flag === 'visible'
                        ? 'ElementFlagChangedVisibility'
                        : ['required', 'completed'].indexOf(flag) > -1
                            ? 'ElementFlagChangedCompletion'
                            : null;

                    if (menuEvent) {
                        var formId = field.getForm().getId();
                        var stateParams = CrfService.getNamedListIndices(payload.listIndices || {});
                        // console.info('elementFlagChanged', fieldId, '(', field.getLabel(), ') in', formId, '(', field.getForm().getLabel(), ')', '>', flag, payload.oldValue, '->', payload.newValue);

                        queueFormTask(menuEvent, formId, stateParams, payload);
                    }
                }
            }),
            // Validación de un formulario
            RecordValidationService.events.on('validateForm', function(formId, listIndices, result) {
                var menuEvent = 'FormValidated';
                var stateParams = CrfService.getNamedListIndices(listIndices || {});

                queueFormTask(menuEvent, formId, stateParams, result);
            }),
        ];
        var rulesListeners = [
            // Actualización automática del valor de un campo
            RecordRulesService.addEventListener('onSetValueChanged', function(ruleAction, listIndices) {
                var fieldId = ruleAction.definition.target;
                var field = CrfService.getField(fieldId);
                if (!field) {
                    return;
                }

                var menuEvent = 'SetValueChanged';
                var formId = field.getForm().getId();
                var payload = {
                    ruleAction: ruleAction,
                    listIndices: listIndices || {},
                };
                var stateParams = CrfService.getNamedListIndices(payload.listIndices);

                // console.info('SetValueChanged', fieldId, '(', field.getLabel(), ') in', formId, '(', field.getForm().getLabel(), ')');
                queueFormTask(menuEvent, formId, stateParams, payload);
            }),
        ];

        $scope.$on('$destroy', function() {
            EntityMenu.clear();

            eventListeners.forEach(function(unregisterListener) {
                unregisterListener();
            });
            eventListeners = null;

            rulesListeners.forEach(function(listenerId) {
                RecordRulesService.removeEventListener(listenerId);
            });
            rulesListeners = null;

            formTasksTimeout && $timeout.cancel(formTasksTimeout);
            formTasksTimeout = null;
        });
    },
]);
