'use strict';

angular.module('study.import')
.component('importLoadCSVFinished', {
    templateUrl: 'modules/import/components/load-csv/finished.html',
    controller: 'ImportLoadCSVFinishedController',
    bindings: {
        importId: '@',
        sources: '=',
        importMode: '@',
    },
});
