(function() {
    'use strict';

    angular.module('ecrd')
        .controller('FieldSelectorController', FieldSelectorController);

    FieldSelectorController.$inject = ['$window', 'CoreCRF', 'gettext'];

    function FieldSelectorController($window, CoreCRF, gettext) {
        var vm = this;
        var _ = $window._;

        // Datos para incluir en el selector de formularios
        vm.formsData = [];

        // Opción de formulario seleccionada
        vm.selectedForm = {
            id: null,
        };

        // Datos para incluir en el selector de campos
        vm.fieldsData = [];

        // Opción de campo seleccionada
        vm.selectedField = {
            id: null,
        };

        // Instancia del CRD
        var _crf = null;

        // Estructura interna concampos indexados por ID del formulario que los contiene
        var _fieldsByForm = {};

        // Identificadores únicos de los <select> para asociar a sus respectivos <label>
        vm.formSelectorId = _.uniqueId('form');
        vm.fieldSelectorId = _.uniqueId('field');

        // Información sobre las listas que incluyen al campo: ID, nombre, valor, opciones de índice disponibles...
        // esta última controla el valor "current" cuando el campo está en el ámbito de esa lista
        vm.listsInfo = {};

        var _listIndexOptions = {
            first: gettext('Primero'),
            previous: gettext('Anterior'),
            current: gettext('Actual'),
            next: gettext('Siguiente'),
            last: gettext('Último'),
            index: gettext('Especificar índice'),
        };

        vm.$onInit = function() {
            _crf = CoreCRF;

            // Lista de campos seleccionables por formulario
            setFormsData();

            if (vm.selected) {
                var formId = getFormByCurrentField().getId();
                vm.selectedForm = _.find(vm.formsData, {id: formId});
                vm.selectedField = _.find(vm.fieldsData, {id: vm.selected});
            }

            prepareListsInfo();
        };

        function getFormByCurrentField() {
            return vm.selected ? _crf.getField(vm.selected).getForm() : null;
        }

        function getElementInfo(element) {
            var fullLabel = element.getFullLabel();

            return {
                id: element.getId(),
                name: element.getName(),
                label: element.getLabel(),
                path: _.initial(fullLabel).join(' > '),
                fullPath: fullLabel.join(' > '),
            };
        }

        function setFormsData() {
            var eligibleForms = [];

            if (angular.isDefined(vm.availableFields)) {
                // Si se han definido los campos posibles se obtiene la lista de formularios a partir de los mismos
                angular.forEach(vm.availableFields, function(field) {
                    var form = field.getForm();
                    var formId = form.getId();
                    if (!(formId in _fieldsByForm)) {
                        eligibleForms.push(form);
                        _fieldsByForm[formId] = [];
                    }
                    _fieldsByForm[formId].push(field);
                });
            } else {
                // En caso contrario todos los formularios son seleccionables
                angular.forEach(_crf.getForms(), function(form) {
                    eligibleForms.push(form);
                    _fieldsByForm[form.getId()] = form.getFields();
                });
            }

            vm.formsData = [{
                id: null,
                label: gettext('Todos'),
            }].concat(eligibleForms.map(getElementInfo));

            reloadFields();
        }

        function getAllFields() {
            if (angular.isDefined(vm.availableFields)) {
                return vm.availableFields;
            }
            return _crf.getFields();
        }

        function reloadFields(formId) {
            var fieldsPool;

            if (angular.isDefined(formId)) {
                fieldsPool = formId ? _fieldsByForm[formId] : getAllFields();
            } else {
                var form = vm.selectedForm.id ? _crf.getForm(vm.selectedForm.id) : getFormByCurrentField();

                fieldsPool = form !== null ? _fieldsByForm[form.getId()] : getAllFields();
            }
            vm.fieldsData = fieldsPool.map(getElementInfo);

            if (vm.selectedField.id && !_.find(vm.fieldsData, {id: vm.selectedField.id})) {
                vm.selectedField = {
                    id: null,
                };
                vm.onChange({
                    field: null,
                });
            }
        }

        /**
         * Al seleccionar un formulario de la lista se actualizan la lista de campos y las listas del contexto
         *
         * @param {object} formData Datos del formulario: id, name...
         */
        vm.selectForm = function(formData) {
            prepareListsInfo();
            reloadFields(formData.id);
        };

        vm.selectField = function() {
            vm.onChange({
                field: vm.selectedField,
            });

            prepareListsInfo();
        };

        vm.$onChanges = function(changes) {
            if (angular.isDefined(changes.availableFields) && !changes.availableFields.isFirstChange()) {
                setFormsData();
            }
        };

        function prepareListsInfo() {
            vm.listsInfo = {};

            if (angular.isUndefined(vm.context)) {
                return;
            }

            var targetLists = [];

            if (vm.selectedForm.id) {
                targetLists = _crf.getForm(vm.selectedForm.id).getParentLists();
            } else if (vm.selectedField.id) {
                targetLists = _crf.getField(vm.selectedField.id).getParentLists();
            }

            var contextList = _crf.getList(vm.context);
            var contextLists = contextList ? contextList.getParentLists().concat(contextList) : [];

            if (!vm.listIndices) {
                vm.listIndices = {};
            }

            angular.forEach(targetLists, function(list) {
                var listId = list.getId();
                var selectedOption;

                var listInContext = _.find(contextLists, list);

                var indexOptions = {};
                if (listInContext) {
                    indexOptions = _.pick(_listIndexOptions, 'first', 'previous', 'current', 'next', 'last', 'index');
                } else {
                    indexOptions = _.pick(_listIndexOptions, 'first', 'last', 'index');
                }

                if (angular.isDefined(vm.listIndices[listId])) {
                    if (angular.isDefined(indexOptions[vm.listIndices[listId]])) {
                        selectedOption = vm.listIndices[listId];
                    } else {
                        selectedOption = 'index';
                    }
                } else {
                    selectedOption = listInContext ? 'current' : 'first';
                    vm.listIndices[listId] = selectedOption;
                }

                vm.listsInfo[listId] = {
                    label: list.getLabel(),
                    selected: selectedOption,
                    options: indexOptions,
                };
            });
        }

        vm.changeIndexType = function(listId) {
            if (vm.listsInfo[listId].selected === 'index') {
                vm.listIndices[listId] = 1;
            } else {
                vm.listIndices[listId] = vm.listsInfo[listId].selected;
            }

            vm.onChange({
                id: vm.selectedField.id,
                listIndices: vm.listIndices,
            });
        };
    }
})();
