angular.module('ecrd').factory('ListItemsService', [
    '$window',
    'Event',

    function ListItemsService($window, Event) {
        var createdItems = [];
        var currentRouteItems = [];

        // Limpiamos los elementos de lista creados al salir de la pantalla de edición de un registro
        Event.on('destroyRecordEditController', function() {
            createdItems = [];
        });

        return {
            getCreatedListItems: function ListItemsServiceGetCreatedListItems() {
                return createdItems;
            },
            addListItem: function ListItemsServiceAddListItem(itemUid) {
                var uid = Array.isArray(itemUid) ? itemUid.join('.') : itemUid;
                if (createdItems.indexOf(uid) === -1) {
                    createdItems.push(uid);
                }

                return createdItems;
            },
            removeElementIdsFromPayload: function ListItemsServiceRemoveElementIdsFromPayload(data) {
                var cleanData = $window.ShareCrfLibUtils.cleanElementIdsByUids(createdItems, data);

                // GARU-7931 Delete the property __list_counters from the payload as it is not needed
                delete cleanData.__list_counters;

                // GARU-7947 Remove from the deleted list the items that were created in the current session
                if (Array.isArray(cleanData.__deleted_items)) {
                    for (var index = cleanData.__deleted_items.length - 1; index >= 0; index--) {
                        var uidParts = [];

                        if (Array.isArray(cleanData.__deleted_items[index])) {
                            uidParts = cleanData.__deleted_items[index];
                        }

                        else if (angular.isObject(cleanData.__deleted_items[index])
                            && cleanData.__deleted_items[index].uid) {
                            uidParts = cleanData.__deleted_items[index].uid;
                        }

                        var itemUid = uidParts.join('.');

                        if (createdItems.indexOf(itemUid) !== -1) {
                            cleanData.__deleted_items.splice(index, 1);
                        }
                    }
                }

                return cleanData;
            },
            clear: function ListItemsServiceClear() {
                createdItems = [];
            },
            containsElement: function ListItemsServiceContainsElement(itemUid) {
                return createdItems.indexOf(itemUid) !== -1;
            },
            // Gestión de elementos de lista creados en la ruta actual | GARU-7930
            saveCurrentRouteItems: function ListItemsServiceSaveCurrentRouteItems(item) {
                currentRouteItems.push(item);
            },
            getCurrentRouteItems: function ListItemsServiceGetCurrentRouteItems() {
                return currentRouteItems;
            },
            clearCurrentRouteItems: function ListItemsServiceClearCurrentRouteItems() {
                currentRouteItems = [];
            },
        };
    },
]);
