angular.module('study.dashboard').factory('DashboardUtils', [
    'numberFilter',
    function(numberFilter) {
        var formatValue = {
            decimals: function(value, count) {
                if (angular.isUndefined(count)) {
                    count = 2;
                }

                return numberFilter(value || 0, count);
            },
            percentage: function(value, decimals) {
                return formatValue.decimals(value, decimals) + '%';
            },
        };

        return {
            format: formatValue,
            tooltip: function(config) {
                var html = '<table class="c3-tooltip"><tbody>';
                if (config.header) {
                    html += '<tr><th colspan="2">' + config.header + '</th></tr>';
                }
                if (angular.isArray(config.rows)) {
                    angular.forEach(config.rows, function(row, index) {
                        html += '<tr class="c3-tooltip-name--' + (row.key || index) + '"><td class="name">';
                        if (row.color) {
                            html += '<span style="background-color:' + row.color + '"></span>';
                        }
                        html += row.label + '</td>';
                        var rowValue = row.format ? formatValue[row.format](row.value) : row.value;
                        html += '<td class="value">' + rowValue + '</td>';
                        html += '</tr>';
                    });
                }
                html += '</tbody></table>';

                return html;
            },
        };
    },
]);