angular.module('scrf.proxy', ['event']).factory('Proxy', [
    '$exceptionHandler',
    'Event',
    'Modal',
    function($exceptionHandler, Event, Modal) {
        return {
            /**
             * Create proxy object
             *
             * @param  {Object}   original   Instance to be proxied
             * @param  {String[]} definition List of proxied properties
             * @param  {String}   [eventName]  Event triggered when a property is updated ('updateProperty' by default)
             * @param  {mixed}    [additionalPayload] Optional fixed data payload to send with the event trigger
             *
             * @return {Object}              Proxy
             *
             * @example <caption>JS code</caption>
             * var templateData = Proxy.create(recordData, [
             *     '10',
             *     '20',
             *     {
             *         name: '30',
             *         get: function() {
             *             return getFieldValue(30);
             *         },
             *         set: function(value) {
             *             doSomething(value);
             *         },
             *     },
             * ]);
             * templateData[30] = 'Test'; // This will call "doSomething('Test')"
             *
             * @example <caption>HTML code</caption>
             * {{ $ctrl.templateData[30] }} <!-- This will call "getFieldValue(30)" -->
             */
            create: function(original, definition, eventName, additionalPayload) {
                var data = {};

                eventName = eventName || 'updateProperty';

                angular.forEach(definition, function(property) {
                    var getter, setter, propertyName;

                    if (property && property.name && property.get && property.set) {
                        propertyName = '' + property.name;
                        getter = property.get;
                        setter = property.set;
                    } else {
                        propertyName = '' + property;
                        getter = function() {
                            return original[propertyName];
                        };
                        setter = function(value) {
                            original[propertyName] = value;
                        };
                    }

                    Object.defineProperty(data, propertyName, {
                        configurable: false,
                        enumerable: true,
                        get: function() {
                            return getter.apply(original);
                        },
                        set: function(value) {
                            var params = Object.assign({}, additionalPayload || {}, {
                                instance: original,
                                property: propertyName,
                                beforeValue: getter.apply(original),
                                afterValue: value,
                            });
                            Event.broadcast(eventName + 'Start', params)
                                .then(function(event) {
                                    if (event.defaultPrevented) {
                                        throw new Error('event-prevented');
                                    }

                                    return setter.apply(original, [value]);
                                })
                                .then(function() {
                                    Event.trigger(eventName, original, propertyName, params);
                                })
                                .catch(function(error) {
                                    if (!((error && error.message === 'event-prevented') || error === Modal.DISMISSED)) {
                                        $exceptionHandler(error, 'record data proxy setter');
                                    }
                                });
                        },
                    });
                });

                return Object.freeze(data);
            },
        };
    },
]);

