/* eslint-disable angular/foreach */
angular.module('ecrd').controller('EntityDataController', [
    '$scope',
    '$timeout',
    'Event',
    'isEntityChangedOnRouteLoading',
    'CrfService',
    'RecordDataService',
    'FormFieldsService',
    'RecordRulesService',
    'EcrdMenuScrollManager',
    '$state',
    'ListItemsService',
    '$window',
    function ($scope, $timeout, Event, isEntityChangedOnRouteLoading, CrfService, RecordDataService, FormFieldsService, RecordRulesService, EcrdMenuScrollManager, $state, ListItemsService, $window) {
        var vm = this;

        // Enlace con el controlador global para la gestión del botón superior de "Guardar datos"
        vm.bindEntityController = function(entityController) {
            vm.entityController = entityController;
        };
        $timeout(function() {
            vm.entityController.form = $scope.form;

            isEntityChangedOnRouteLoading.forEach(function(info) {
                var stateParams = CrfService.getNamedListIndices(info.listIndices);
                Event.broadcast('EntityFormDirtyFlagUpdated', {
                    formId: info.formId,
                    stateParams: stateParams,
                    dirty: true,
                });
            });

            if (isEntityChangedOnRouteLoading.length) {
                vm.entityController.form.$setDirty();

                // GARU-5309 Se vacía el array con los formularios modificados sin cambiar su referencia
                for (var iteration = isEntityChangedOnRouteLoading.length; iteration > 0; iteration--) {
                    isEntityChangedOnRouteLoading.pop();
                }
            }
        });

        // Actualizar flag de completado al modificar datos
        function updateCompletionFlag(fieldId, listIndices) {
            var field = CrfService.getField(fieldId);
            if (field.isRequired()) {
                var completed = !RecordDataService.isFieldValueEmpty(fieldId, listIndices);
                FormFieldsService.setCompleted(fieldId, completed, listIndices);
            }
        }

        var eventListeners = [
            Event.on('updatedRecordFieldValue', function(event, recordData, fieldId, payload) {
                var listIndices = CrfService.getListIndices(payload.stateParams || {});
                updateCompletionFlag(fieldId, listIndices);
            }),
            Event.on('beforeRecordUpdated', function() {
                var stateParams = $state.params || {};

                var elementName = $state.current.url.slice(1);
                if (elementName) {
                    var listIndices = CrfService.getListIndices(stateParams || {});
                    var element = CrfService.getElementByName(elementName);

                    var lists = element.getParentLists();
                    lists.forEach(function(list) {
                        var listElements = RecordDataService.getList(list.getId(), listIndices);

                        var currentLength = listElements.length;
                        var currentItemIndex = parseInt(stateParams[list.getName()], 10);

                        ListItemsService.saveCurrentRouteItems({
                            listName: list.getName(),
                            listId: list.getId(),
                            rightItemIndex: currentLength - currentItemIndex,
                        });
                    });
                }
            }),
            Event.on('afterRecordUpdated', function(event, hasConcurrencyError) {
                var stateParams = $state.params || {};
                var currentItems = ListItemsService.getCurrentRouteItems();
                var listIndices = CrfService.getListIndices(stateParams || {});

                var newStateParams = angular.copy(stateParams);
                var hasChanged = false;

                currentItems.forEach(function(item) {
                    var listElements = RecordDataService.getList(item.listId, listIndices);

                    var currentLength = listElements.length;
                    newStateParams[item.listName] = currentLength - item.rightItemIndex;

                    if (newStateParams[item.listName] !== Number(stateParams[item.listName])) {
                        hasChanged = true;
                    }
                });

                ListItemsService.clearCurrentRouteItems();

                // GARU-7952 Recarga de la pagina tras error de concurrencia de datos
                if (hasChanged || hasConcurrencyError) {
                    // GARU-7952 Comprobar si el error de concurrencia ocurre en un formulario de un item de lista,
                    // en caso afirmativo, redirigir a la lista padre.
                    var currentPath = ($state.current.name);
                    var currentPathElements = currentPath.split('.');
                    var currentElementName = currentPathElements.pop();

                    var currentElement = CrfService.getElementByName(currentElementName);
                    var isFormElement = currentElement.isForm();

                    // Helper para buscar el primer elemento padre de tipo lista
                    var getFirstParentList = function(element) {
                        var elementParent = element.getParent();
                        if (!elementParent) {
                            return null;
                        }

                        currentPathElements.pop();
                        if (elementParent.isList()) {
                            return element.getParent();
                        }

                        return getFirstParentList(elementParent);
                    };

                    if (isFormElement) {
                        var parentList = getFirstParentList(currentElement);

                        if (parentList) {
                            var newPath = currentPathElements.join('.');
                            $state.go(newPath, newStateParams);
                        }
                    }

                    // Fuerza la recarga de la pagina para reinicializar todos los servicios
                    $timeout(function() {
                        $window.location.reload();
                    });
                }
            }),
        ];
        var rulesListeners = [
            RecordRulesService.addEventListener('onSetValueChanged', function(ruleAction, listIndices) {
                var fieldId = ruleAction.definition.target;
                updateCompletionFlag(fieldId, listIndices);
            }),
        ];

        // GARU-2381 Scroll independiente en menú necesita recalcular la altura visible máxima al hacer scroll
        EcrdMenuScrollManager.enable();

        // GARU 7381 Forzado de la primera ejecución del evento
        $timeout(function() {
            EcrdMenuScrollManager.update();
        });

        $scope.$on('$destroy', function() {
            return Event.broadcast('destroyRecordEditController').then(function (event) {
                if (event.defaultPrevented) {
                    return;
                }

                EcrdMenuScrollManager.disable();

                eventListeners.forEach(function(unregisterListener) {
                    unregisterListener();
                });
                rulesListeners.forEach(function(listenerId) {
                    RecordRulesService.removeEventListener(listenerId);
                });
            });
        });
    },
]);
