angular.module('study.import')
    .filter('importRecordsIdErrorCode', [
        'gettextCatalog',
        'ShareCrfApiErrorCodes',
        'CrdConfigurationService',
        function (
            gettextCatalog,
            ShareCrfApiErrorCodes,
            CrdConfigurationService
        ) {
            return function (code) {
                var entityLabelLowerCase = CrdConfigurationService.getLabelSingular().toLowerCase();

                switch (code) {
                    case 'repeated-id-input':
                        return gettextCatalog.getString('El ID del {{entityLabelLowercase}} está repetido en el fichero', {
                            entityLabelLowercase: entityLabelLowerCase,
                        });

                    case 'repeated-new-id-input':
                        return gettextCatalog.getString('El nuevo ID está repetido en el fichero');

                    case 'record-not-found':
                        return gettextCatalog.getString('No se ha encontrado el {{entityLabelLowercase}}', {
                            entityLabelLowercase: entityLabelLowerCase,
                        });

                    case 'user-not-allowed':
                        return gettextCatalog.getString('El usuario no tiene permisos para editar el {{entityLabelLowercase}}', {
                            entityLabelLowercase: entityLabelLowerCase,
                        });

                    case 'id-already-in-use':
                        return gettextCatalog.getString('El ID ya existe en el estudio');

                    case 'missing-id-input':
                        return gettextCatalog.getString('Uno de los IDs de la fila está vacío');

                    default: return ShareCrfApiErrorCodes.getHumanReadableMessage(code);
                }
            };
        },
    ]);