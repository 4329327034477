'use strict';

angular.module('study.placeholders')
    .directive('entityListTable', ['$q', '$compile', '$http', 'CrfService', '$templateCache', function($q, $compile, $http, CrfService, $templateCache) {
        var getTemplate = function(listName) {
            var list = CrfService.getElementByName(listName);
            if (list && list.isList()) {
                var templateId = 'generated/html/list-'+ listName + '.info.html';
                return $http.get(templateId, {cache: $templateCache})
                    .then(function(response) {
                        return response.data;
                    });
            } else {
                return $q.resolve('');
            }
        };

        return {
            restrict: 'A',
            replace: true,
            controller: 'PlaceholderEntityListTableController',
            controllerAs: 'placeholder',
            scope: {
                listName: '@entityListTable',
                stateParams: '=entityListTableParams'
            },
            link: function(scope, element){
                return getTemplate(scope.listName)
                    .then(function(template){
                        element.html(template);
                        $compile(element.contents())(scope);
                    });
            }
        };
    }]);
