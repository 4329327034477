angular.module('sharecrf.multiple').component('multipleCheckbox', {
    templateUrl: 'modules/multiple/widgets/checkboxes/template.html',
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        options: '<',
        disabled: '<ngDisabled',
    },
    controller: ['$scope', function($scope) {
        var vm = this;

        vm.model = {};
        vm.invalidOption = false;
        vm.$onInit = function() {
            vm.ngModel.$render = function() {
                vm.formatter(vm.ngModel.$modelValue);
                vm.searchForInvalidOptions();
            };

            $scope.$watch('$ctrl.ngModel.$modelValue', function(newValue, oldValue) {
                if (!vm.isEqual(newValue, oldValue)) {
                    vm.formatter(vm.ngModel.$modelValue);
                    vm.searchForInvalidOptions();
                }
            });
        };

        vm.formatter = function(modelValue) {
            var parsedModel = {};
            angular.forEach(modelValue || [], function(selectedOptionValue) {
                parsedModel[selectedOptionValue] = true;
            });

            vm.model = parsedModel;
        };

        vm.searchForInvalidOptions = function(options) {
            var optionValues = (options || vm.options || []).map(function(option) {
                return option.value;
            });

            var invalidOptionFound = false;
            angular.forEach(vm.ngModel.$modelValue || [], function(selectedOptionValue) {
                invalidOptionFound = invalidOptionFound || optionValues.indexOf(selectedOptionValue) === -1;
            });

            vm.invalidOption = invalidOptionFound;
        };

        vm.cleanInvalidOptions = function() {
            if (vm.disabled) {
                return;
            }

            var optionValues = (vm.options || []).map(function(option) {
                return option.value;
            });

            var modelValue = vm.ngModel.$modelValue || [];
            var newValue = modelValue.filter(function(optionValue) {
                return optionValues.indexOf(optionValue) > -1;
            });

            if (!vm.isEqual(modelValue, newValue)) {
                vm.ngModel.$setViewValue(newValue);
            }
        };

        vm.toggleOption = function(option) {
            if (vm.disabled) {
                return;
            }

            var newValue = (vm.ngModel.$modelValue || []).slice();
            if (vm.model[option.value]) {
                // remove option
                var index = newValue.indexOf(option.value);
                newValue.splice(index, 1);
            }
            else {
                // add option
                newValue.push(option.value);
            }

            vm.ngModel.$setViewValue(newValue);
        };

        vm.$onChanges = function(changes) {
            if (changes.options) {
                vm.searchForInvalidOptions(changes.options.currentValue);
            }
        };

        vm.isEqual = function(valueA, valueB) {
            var arrayValueA = Array.isArray(valueA) ? valueA : [];
            var arrayValueB = Array.isArray(valueB) ? valueB : [];

            return arrayValueA.length === arrayValueB.length
                && arrayValueA.every(function(valueInA) { return arrayValueB.indexOf(valueInA) > -1; })
                && arrayValueB.every(function(valueInB) { return arrayValueA.indexOf(valueInB) > -1; });
        };
    }],
});
