angular.module('export').factory('ExportService', [
    '$interval',
    'ShareCrfSDK',
    'AccountManager',
    'Event',
    function($interval, ShareCrfSDK, AccountManager, Event) {
        function ExportService() {
            this.initied = false;
            this.loadPromise = null;
            this.interval = null;
            this.locked = false;
        }

        function initInterval(self, exportId, intervalTime) {
            var currentIntervalTime = intervalTime || 2000;
            var intervalSteps = {
                0: 2000,      // 0-30s: retry in 2s
                30000: 5000,  // 30-60s: retry in 5s
                60000: 10000, // after 60s: retry in 10s
            };

            self.interval = $interval(function() {
                if (!self.previousIterationDone) {
                    return;
                }

                self.previousIterationDone = false;

                return ShareCrfSDK.exports.data(exportId)
                    .then(function(dump) {
                        if (dump.status === 'finished') {
                            // Se desbloquea la generación de nuevos dumps
                            self.locked = false;
                            // Fin del interval
                            $interval.cancel(self.interval);
                            self.interval = null;
                            delete self.previousIterationDone;
                            // Evento OK
                            Event.trigger('exportFinished', dump);
                        }
                        else if (dump.status === 'error') {
                            // Se desbloquea la generación de nuevos dumps
                            self.locked = false;
                            // Fin del interval
                            $interval.cancel(self.interval);
                            self.interval = null;
                            delete self.previousIterationDone;
                            // Evento Error
                            Event.trigger('exportError', dump);
                        }
                        else if (dump.startedAt) {
                            // Check next step
                            var msFromStart = Date.now() - Date.parse(dump.startedAt);
                            var nextIntervalTime = currentIntervalTime;
                            for (var limit in intervalSteps) {
                                if (msFromStart > parseInt(limit)) {
                                    nextIntervalTime = intervalSteps[limit];
                                }
                            }
                            // If step has changed, current interval is cancelled and a new one is registered with the new interval time
                            if (nextIntervalTime !== currentIntervalTime) {
                                $interval.cancel(self.interval);
                                initInterval(self, exportId, nextIntervalTime);
                            }

                            // Se marca la iteración como completa
                            self.previousIterationDone = true;
                        }
                        else {
                            // Se marca la iteración como completa
                            self.previousIterationDone = true;
                        }
                    });
            }, currentIntervalTime);
        }

        ExportService.prototype.onLogin = function() {
            this.loadPromise = null;
            this.initied = false;

            return this.init();
        };
        ExportService.prototype.onLogout = function() {
            var self = this;

            self.locked = true;
            if (self.interval) {
                $interval.cancel(self.interval);
                self.interval = null;
            }

            self.loadPromise = null;
            self.initied = false;

            return;
        };

        ExportService.prototype.init = function() {
            var self = this;

            if (!self.loadPromise) {
                // Carga inicial
                self.loadPromise = AccountManager.init()
                    .then(function() {
                        self.initied = true;
                    });
            }

            return self.loadPromise;
        };

        ExportService.prototype.create = function(filters) {
            var self = this;

            Event.trigger('exportStarted');

            return ShareCrfSDK.exports.create(filters)
                .then(function(exportData) {
                    Event.trigger('exportCreated', exportData);
                    // Se queda bloqueado hasta que el volcado termine con OK o KO
                    self.locked = true;
                    // Variable temporal de control para no tener varios request pendientes si superan el tiempo del intervalo
                    self.previousIterationDone = true;
                    // Watcher sobre el estado de la última exportación
                    initInterval(self, exportData.id);
                })
                .catch(function(error) {
                    Event.trigger('exportError', error);
                    self.locked = false;
                    throw error;
                });
        };

        return new ExportService();
    }
]);