angular.module('study.dashboard').component('approvalRevisionReportSummary', {
    bindings: {
        hideFooter: '<',
    },
    templateUrl: 'modules/dashboard/components/approval-revision-report-summary/template.html',
    controller: [
        'CrdConfigurationService',
        'gettextCatalog',
        '$q',
        'ShareCrfSDK',
        'ShareCrfApiErrorCodes',
        '$timeout',
        '$window',
        'numberFilter',
        function(
            CrdConfigurationService,
            gettextCatalog,
            $q,
            ShareCrfSDK,
            ShareCrfApiErrorCodes,
            $timeout,
            $window,
            numberFilter
        ) {
            var vm = this;
            var c3 = $window.c3;

            vm.title = '';
            vm.data = {};
            var charts = {};

            vm.loading = false;
            vm.error = false;

            vm.labelPlural = CrdConfigurationService.getLabelPlural();

            vm.hasStates = CrdConfigurationService.hasStates();
            vm.hasRevision = CrdConfigurationService.hasRevisionStates();

            if (vm.hasStates && vm.hasRevision) {
                vm.title = gettextCatalog.getString('Resumen de estados de aprobación y revisión');
            }
            else if (vm.hasStates) {
                vm.title = gettextCatalog.getString('Resumen de estados de aprobación');
            }
            else if (vm.hasRevision) {
                vm.title = gettextCatalog.getString('Resumen de estados de revisión');
            }

            vm.approvalStates = vm.hasStates ? CrdConfigurationService.getStates().getStates() : [];
            vm.revisionStates = vm.hasRevision ? CrdConfigurationService.getRevisionStates().getStates() : [];

            vm.$onInit = function() {
                createCharts();
                loadData();
            };

            function loadData() {
                vm.loading = true;
                vm.error = false;

                return $q.resolve()
                    .then(ShareCrfSDK.dashboard.approvalRevisionSummary)
                    .then(function(data) {
                        vm.data = data;

                        angular.forEach(vm.approvalStates, loadApprovalChart);

                        if ('byRevision' in vm.data) {
                            vm.data.byRevision = Object.keys(vm.data.byRevision).reduce(function(acc, stateKey) {
                                var stateData = vm.data.byRevision[stateKey];

                                acc[stateKey] = {
                                    total: 0,
                                    revised: 0,
                                    filled: 0,
                                    notRevised: 0,
                                };

                                angular.forEach(stateData, function(row) {
                                    var value = parseInt(row.count);
                                    var target = row.revised ? 'revised' : row.filled ? 'filled' : 'notRevised';

                                    acc[stateKey].total += value;
                                    acc[stateKey][target] += value;
                                });

                                acc[stateKey].rateRevised = formatDecimals(acc[stateKey].revised / acc[stateKey].total * 100);
                                acc[stateKey].rateFilled = formatDecimals(acc[stateKey].filled / acc[stateKey].total * 100);
                                acc[stateKey].rateNotRevised = formatDecimals(acc[stateKey].notRevised / acc[stateKey].total * 100);

                                acc[stateKey].rateRevisedPlain = plainRate(acc[stateKey].revised, acc[stateKey].total);
                                acc[stateKey].rateFilledPlain = plainRate(acc[stateKey].filled, acc[stateKey].total);
                                acc[stateKey].rateNotRevisedPlain = plainRate(acc[stateKey].notRevised, acc[stateKey].total);

                                return acc;
                            }, {});
                        }
                    })
                    .catch(function(error) {
                        vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                        throw error;
                    })
                    .finally(function() {
                        vm.loading = false;
                    });
            }

            function createCharts() {
                $timeout(function() {
                    angular.forEach(vm.approvalStates, createApprovalChart);
                });
            }

            function createApprovalChart(state) {
                var stateId = state.getId();
                var color = state.getColor();
                var stateName = state.getName();

                charts[stateId] = c3.generate({
                    bindto: '#approval-' + stateId + '-summary',
                    data: {
                        columns: [],
                        type : 'pie',
                        names: {
                            'with-state': gettextCatalog.getString('Con estado {{state}}', {state: stateName}),
                            'without-state': gettextCatalog.getString('Sin estado {{state}}', {state: stateName}),
                        },
                        colors: {
                            'with-state': color,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    pie: {
                        label: {
                            show: false,
                        },
                    },
                    size: {
                        height: 180,
                    },
                });
            }

            function loadApprovalChart(state) {
                var stateId = state.getId();

                charts[stateId].load({
                    columns: [
                        ['with-state', vm.data.byApproval[stateId]],
                        ['without-state', vm.data.total - vm.data.byApproval[stateId]],
                    ],
                });
            }

            function plainRate(subtotal, total) {
                return total > 0 ? Math.round(subtotal / total * 100) : 0;
            }

            function formatDecimals(value) {
                return numberFilter(value || 0, 2);
            }
        },
    ],
});
