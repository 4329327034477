angular.module('app.entity.field').component('gridRowActions', {
    templateUrl: 'modules/ecrd/components/field/grid-row-actions/content.html',
    controller: 'GridRowActionsController',
    bindings: {
        gridId: '@',
        rowIndex: '@',
        open: '<',
        selectedAction: '<',
        disableNotes: '@',
        onToggleMenu: '&',
        onChangeValue: '&',
        onResetValue: '&',
        onSelectAction: '&',
    },
});
