
angular.module('study.import').factory('ImportMessageCodes', ['gettext', function(gettext) {
    return {
        getMessage: function(error) {
            var mapping = {
                // Parser CSV
                /// Import CSV
                'invalid-number-of-columns': gettext('El nº de columnas de la línea es incorrecto'),
                //
                // Permisos
                //
                'record-action-update-not-allowed': gettext('No tiene permisos para modificar este registro'),
                'security-error-update': gettext('Error de permisos al modificar este registro'),
                //
                // Errores de datos
                //
                // en un update, el RECORD.id es obligatorio
                'empty-record-id': gettext('El identificador del registro es obligatorio'),
                // en create o update, no se pueden repetir ids de elementos de lista
                // no se pueden repetir record id en root.csv
                'row-id-repeated': gettext('El identificador de la fila está repetido'),
                // RECORD.id está repetido en la BD
                'record-id-duplicated': gettext('El identificador del registro está repetido, ya existe'),
                // hay un cambio de datos (update) pero no se ha especificado la razón de cambio
                'reason-of-change-not-specified': gettext('No ha especificado la razón de cambio'),
                // Centro no identificado (no viene SITE.code y no se puede deducir del usuario)
                'site-not-identified': gettext('No se ha especificado el código de centro'),
                // La variable de la cabecera no existe en el CRF actual
                'field-not-exists': gettext('El campo no existe en el CRD'),
                // el valor de la variable es incorrecto (fecha mal escrita, etc.)
                'invalid-fields': gettext('El valor del campo no es válido'),
                // el formato de la fecha no es válido
                'invalid-date-value': gettext('El formato de la fecha no es válido'),
                // no se ha puesto RECORD.number o RECORD.id, hace falta uno de los dos
                'record-not-identified': gettext('El identificador del registro es obligatorio'),
                // el ID del registro a actualizar no existe
                'record-not-found': gettext('El registro indicado no existe'),
                // el código de centro no existe
                'site-code-not-found': gettext('No hay ningún centro registrado con el código indicado'),
                // ocurrió un error interno en el servidor al crear el registro en la BD
                'create-record-error': gettext('Error al crear el registro'),
                // error general al interpretar la línea de CSV en la importación
                'unexpected-csv-process-error': gettext('Error inesperado al procesar CSV'),
                // el usuario no pertenece al centro SITE.code
                'not-allowed-site': gettext('No tiene permisos para crear registros en este centro'),
                // el usuario pertenece a más de un centro, es obligatorio poner el SITE.code
                'undefined-site': gettext('Debe especificar el centro al que pertenece el registro'),
                // el usuario no pertenece a ningún sitio, no puede crear pacientes
                'user-without-sites': gettext('Su usuario no pertenece a ningún centro'),
                // la suscripción no permite crear más registros
                'max-records-reached': gettext('Se ha alcanzado el nº máximo de registros'),
                //
                // Cabeceras CSV
                //
                // cuando viene LIST.XXX donde XXX no es lista inexistente
                'invalid-list-name': gettext('La cabecera de nº de item no es válida'),
                // una misma cabecera LIST.XXX viene dos veces
                'list-name-duplicated': gettext('La cabecera de lista está repetida'),
                // una misma variable viene dos veces
                'variable-name-duplicated': gettext('El nombre de variable está repetido en cabecera'),
                // viene un SITE.XXX donde XXX no es attr válido = ['code']
                'invalid-site-header': gettext('La cabecera de centro no es válida'),
                // viene un RECORD.XXX donde XXX no es attr válido  = ['created_at','updated_at','id','number']
                'invalid-record-header': gettext('La cabecera de registro no es válida'),
                // el campo no existe en el CRF
                'invalid-field-name': gettext('Campo no encontrado en el CRF'),
                // el valor de LIST.XXX no es un número entero o está vacío
                'invalid-list-item-number': gettext('Debe ser un número entero válido, no vacío'),
                // cabecera en CSV que no se puede reconocer
                'unknown-header': gettext('Cabecera no reconocida'),
                // falta una cabecera LIST.XXX que es requerida (nuestra propia lista o una lista padre)
                'missing-list-header': gettext('Falta alguna cabecera de nº de item de lista'),
                // Se define la columna de valor desconocido en un campo que no lo permite
                'invalid-field-unknown-value': gettext('El campo no permite definir valores desconocidos'),
                //
                // Éxito
                //
                'record-processed': gettext('Registro procesado correctamente'),
                'new-record-created': gettext('Registro creado con éxito'),
                'updated-record': gettext('Registro actualizado con éxito'),
            };

            return mapping[error.message] || gettext('Error en la importación');
        },
    };
}]);
