angular.module('ecrd')
    .factory('RecordService', ['$injector', 'CoreRecord', 'CoreRecordData', 'CoreRecordMetadata', 'CoreRecordValidation', 'CoreFormFields', 'CoreFieldsCompletion', 'RecordsManagerService', 'Event', 'RecordClipboardService', 'ListItemsService',
        function RecordService($injector, CoreRecord, CoreRecordData, CoreRecordMetadata, CoreRecordValidation, CoreFormFields, CoreFieldsCompletion, RecordsManagerService, Event, RecordClipboardService, ListItemsService) {
            var serviceData = {};
            var publicAPI = {};

            // servicios presentes según configuración

            var changesService = null;
            if ($injector.has('RecordChangesService')) {
                changesService = $injector.get('RecordChangesService');
            }

            var randomizationService = null;
            if ($injector.has('RecordRandomizationService')) {
                randomizationService = $injector.get('RecordRandomizationService');
            }

            // 1. Custom functions
            /**
             * Carga la información completa de un registro, asignado los datos a los servicios correspondientes
             *
             * @param {Number}  recordId      ID del registro
             * @param {object}  params        Parámetros adicionales tipo oData para pasar a la API (expand, etc.)
             * @param {*}       globalLoading ???
             *
             * @return {Object}               Contenido del paciente en BDD
             *
             * @async
             */
            publicAPI.loadFromAPI = function(recordId, params, globalLoading) {
                params = params || {};
                params.id = recordId;

                return RecordsManagerService.getRecord(params, globalLoading)
                    .then(function(recordDB) {
                        // Inicializamos y cargamos todos los servicios para asignarles los nuevos datos cargados
                        publicAPI.loadRecord(recordDB);

                        return recordDB;
                    });
            };

            /**
             * Resetea la información del registro cargado, tanto en este servicio como en todos los dependientes
             */
            publicAPI.resetAll = function() {
                for (var property in serviceData) {
                    delete serviceData[property];
                }

                CoreRecord.reset();
                CoreRecordData.reset();
                CoreRecordMetadata.reset();
                CoreRecordValidation.reset();
                CoreFieldsCompletion.reset();
                CoreFormFields.reset();
                // servicios presentes según configuración
                if (changesService) {
                    changesService.reset();
                }
                if (randomizationService) {
                    randomizationService.reset();
                }

                // GARU-5304 Se limpian los posibles restos de un portapapeles anterior
                RecordClipboardService.clear();
                // GARU-7865 Limpiamos los elementos creados en el servicio de items de lista
                ListItemsService.clear();
            };

            /**
             * Vacía la información que hubiese en los servicios del core, con el objetivo de preparar un registro
             * nuevo vacío. Además de limpiar la información, inicializa los servicios como formfields o validation
             *
             * Útil por ejemplo para ejecutarla al entrar en la pantalla de creación de un nuevo paciente
             */
            publicAPI.cleanRecord = function() {
                publicAPI.resetAll();
                CoreFormFields.load();
            };

            /**
             * Carga la información completa de un registro/paciente, tal y como viene de BD, en el Core
             *
             * Útil por ejemplo para la vuelta de una operación contra la API
             *
             * @param {object} recordDB  Datos base del registro/paciente, contiene claves como uid, data, metadata, ...
             * @param {object} flatten   TRUE si los datos vienen deflattened de la API, para indicar al core que debe
             *                           hacer la operación contraria al cargarlos
             *
             * @return {boolean} TRUE si se carga con éxito
             */
            publicAPI.loadRecord = function(recordDB) {
                serviceData.scopes = recordDB.scopes || [];

                var result = !!CoreRecord.load(recordDB, true);

                if (changesService) {
                    // GARU-4352 El servicio de cambios se recarga antes que la validación, ya que se comprueban los
                    // cambios válidos
                    result = result && changesService.load();
                }

                if (randomizationService) {
                    // GARU-4036 La carga de la aleatorización es incondicional ya que no depende de los datos
                    result = randomizationService.load(recordDB.randomization) && result;
                }

                Event.trigger('recordLoaded');

                return result;
            };

            publicAPI.getScopes = function() {
                return serviceData.scopes || [];
            };

            publicAPI.hasScope = function(scope) {
                return publicAPI.getScopes().indexOf(scope) > -1;
            };

            // 2. Inherited public
            Object.getOwnPropertyNames(Object.getPrototypeOf(CoreRecord)).filter(function(key) {
                if (angular.isFunction(CoreRecord[key]) && key !== 'constructor' && key.indexOf('_') !== 0) {
                    if (angular.isDefined(publicAPI[key])) {
                        throw new Error('Function ' + key + ' is present in Core object');
                    }
                    publicAPI[key] = CoreRecord[key].bind(CoreRecord);
                }
            });

            return publicAPI;
        }]);
