'use strict';

angular.module('study.placeholders')
    .controller('PlaceholderEntityFieldMachineValueController', ['$scope', 'CrfService', 'RecordDataService', function($scope, CrfService, RecordDataService) {
        var vm = this;

        var stateParams = $scope.stateParams || {};
        var fieldName = $scope.fieldName;
        var field = CrfService.getElementByName(fieldName);
        var listIndices = CrfService.getListIndices(stateParams);

        vm.print = function() {
            return field ? RecordDataService.getFieldValue(field.getId(), listIndices) : '';
        };
    }]);
