'use strict';

angular.module('error').factory('ErrorMessages', [
    '$log',
    'gettextCatalog',
    'gettext',
    'ShareCrfApiErrorCodes',
    function(
        $log,
        gettextCatalog,
        gettext,
        ShareCrfApiErrorCodes
    ) {
        var _errorCodes = {
            'authentication-required': gettext('Para acceder a la página solicitada debe iniciar sesión en ShareCRF'),
            'permission-required': gettext('No cuenta con los permisos suficientes para acceder a la página solicitada'),
            'successful-logout': gettext('Se ha cerrado la sesión. Identifíquese para acceder de nuevo a la aplicación'),
            'record-created': gettext('Se ha creado un nuevo registro'),
            'record-updated': gettext('Se han actualizado los datos del registro'),
            'record-deleted': gettext('Se ha eliminado el registro'),
            'form-errors': gettext('El formulario contiene errores'),
            'download-error': gettext('Se ha producido un error al descargar el fichero'),
            'bad-signature': gettext('La firma no es válida'),
        };

        return {
            /**
             * Obtiene el mensaje legible para el error que se le pasa.
             * Puede ser una instancia de Error (p.ej. API) o un código en formato de string directamente
             *
             * @param  {Error|string} error Error o código del mensaje
             *
             * @return {string}             Mensaje de texto legible
             */
            get: function(error) {
                var message;
                if (error instanceof Error) {
                    if (error.message === 'api-error') {
                        message = ShareCrfApiErrorCodes.getHumanReadableMessage(error);
                        message = gettextCatalog.getString(message, error.info);

                        if (error.info && error.info.fields) {
                            message += ':\n';
                            error.info.fields.forEach(function(field) {
                                message += '\n · ' + field.label;
                            });
                        }
                        if (error.info && error.info.forms) {
                            message += ':\n';
                            error.info.forms.forEach(function(form) {
                                var labelParts = Array.isArray(form.label) ? form.label : [form.label];
                                message += '\n · ' + labelParts.join(' > ');

                                if (angular.isArray(form.states) && form.states.length > 0) {
                                    if (form.states.length === 11) {
                                        message += ' · ' + gettextCatalog.getString('Estado activado: {{state}}', {
                                            state: form.states[0],
                                        });
                                    } else {
                                        message += ' · ' + gettextCatalog.getString('Estados activados: {{states}}', {
                                            states: form.states.join(', '),
                                        });
                                    }
                                }
                            });
                        }

                        if (error.info && angular.isArray(error.info.states) && error.info.states.length > 0) {
                            if (error.info.states.length === 1) {
                                message += '\n\n' + gettextCatalog.getString('Estado activado: {{state}}', {
                                    state: error.info.states[0],
                                });
                            } else {
                                message += '\n\n' + gettextCatalog.getString('Estados activados: {{states}}', {
                                    states: error.info.states.join(', '),
                                });
                            }
                        }

                        // GARU-4103
                        // Para los errores producidos al guardar los datos/metadatos del paciente debidos a que
                        // se encuentre bloqueado en BDD, se añade un texto extra bajo el mensaje de error devuelto
                        // en la respuesta de la API
                        var auxMessageCodes = [
                            'record-update-data-locked-forms',
                            'record-update-data-locked',
                            'record-update-metadata-locked-forms',
                            'record-update-metadata-locked',
                            // GARU-4110 Lo mismo con estados de aprobación configurables
                            'record-update-data-states',
                            'record-update-data-forms-states',
                        ];

                        if (auxMessageCodes.indexOf(error.code) > -1) {
                            message += '\n\n' + gettextCatalog.getString('Debe recargar la información del paciente antes de poder modificar sus datos.');
                        }
                    } else {
                        // Error nativo Javascript, puede ser controlado o no
                        // TODO mostrar al usuario un mensaje de error genérico (es un error de javascript)
                        if (error.code) {
                            // Si tiene una propiedad code, es que lo hemos modificado nosotros para emparejarlo
                            // con un error conocido
                            message = _errorCodes[error.code];
                        }
                    }
                } else if (typeof error === 'string') {
                    if (typeof _errorCodes[error] !== 'undefined') {
                        message = _errorCodes[error];
                    } else {
                        $log.warn('Unknown error code:' + error);
                        message = error;
                    }
                }

                if (!message) {
                    $log.warn('Error code not recognized by ErrorMessages', error);
                    message = gettext('Se ha producido un error inesperado');
                }

                return message;
            },
        };
    }]);
