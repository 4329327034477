'use strict';

angular.module('app.entity.field')
.component('fieldActionsDetail', {
    templateUrl: 'modules/ecrd/components/field/field-actions-detail/content.html',
    controller: 'FieldActionsDetailController',
    bindings: {
        fieldId: '@',
        selected: '<',
        entityVersion: '<',
        onChange: '&',
    },
});
