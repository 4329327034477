'use strict';

angular.module('study.placeholders')
    .controller('PlaceholderEntitySectionLinkController', ['$scope', 'CoreFormFields', 'CoreRecordData', 'CrfService', '$stateParams', function($scope, CoreFormFields, CoreRecordData, CrfService, $stateParams) {
        var vm = this;

        var stateParams = $scope.stateParams || {};
        var sectionName = $scope.sectionName;
        var section = CrfService.getElementByName(sectionName);

        if (!section) {
            vm.error = true;
        }
        else {
            var parsedStateParams = {};

            for (var listId in stateParams) {
                var paramValue = stateParams[listId];
                var targetListId = listId;

                if (typeof paramValue === 'string') {
                    if (paramValue.indexOf('@') > -1) {
                        var parts = paramValue.split('@');
                        paramValue = parts[0];
                        targetListId = parts[1];
                    }

                    switch (paramValue) {
                        case 'current':
                            parsedStateParams[listId] = typeof $stateParams[targetListId] !== 'undefined' ? parseInt($stateParams[targetListId]) : undefined;
                            break;

                        case 'previous':
                            parsedStateParams[listId] = typeof $stateParams[targetListId] !== 'undefined' ? parseInt($stateParams[targetListId]) - 1 : undefined;
                            break;

                        case 'next':
                            parsedStateParams[listId] = typeof $stateParams[targetListId] !== 'undefined' ? parseInt($stateParams[targetListId]) + 1 : undefined;
                            break;
                    }
                } else {
                    parsedStateParams[targetListId] = paramValue;
                }
            }

            var parents = section.getParents();
            var listIndices = CrfService.getListIndices(parsedStateParams);

            // Do not use root and fields to build the target state
            var usefulParents = parents.filter(function(item) {
                return !item.isRoot() && !item.isField();
            });

            var visible = true;
            var exists = true;
            var routeId = ['record', 'edit', 'data'];
            for (var parentIndex in usefulParents) {
                var element = usefulParents[parentIndex];

                // Check if element exists (only for list items)
                if (element.isList()) {
                    exists &= CoreRecordData.listItemExists(element.getId(), listIndices[element.getId()], listIndices);
                }
                // Check if element is visible
                visible = exists && visible && CoreFormFields.isElementVisible(element.getId(), listIndices);

                // Build route path
                routeId.push(element.getName());
                if (element.isList()) {
                    routeId.push('item');
                }
            }
            routeId.push(sectionName);

            vm.state = routeId.join('.');
            vm.params = parsedStateParams;

            // TODO - This should be a function. If this element is in the same form that contains a field which triggers a
            //  rule to show/hide the section linked here, this flag should change on the fly.
            vm.isVisible = visible;
            vm.error = !exists;
        }
    }]);
