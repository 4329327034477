'use strict';

angular.module('study.import')
.controller('importLoadCSVMainController', ['gettext', function(gettext) {
    var vm = this;

    /**
     * Parámetros de router
     *
     * vm.routeParamMode {string} Indica el modo de importación, 'create' o 'update'
     */

    vm.title = gettext('Importar CSV');
    vm.subtitle = '';
    vm.$onInit = function() {
        if (vm.routeParamMode === 'create') {
            /// en Importar pacientes, cuando estamos creando pacientes nuevos
            vm.subtitle = gettext('nuevos registros');
        } else if (vm.routeParamMode === 'update') {
            /// en Importar pacientes, cuando estamos actualizando pacientes existentes
            vm.subtitle = gettext('actualizar registros existentes');
        }
    };
}]);
