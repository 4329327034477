'use strict';

angular.module('study.placeholders')
    .directive('entityFieldMachineValue', function(){
        return {
            restrict: 'A',
            replace: false,
            template: '{{ placeholder.print() }}',
            controller: 'PlaceholderEntityFieldMachineValueController',
            controllerAs: 'placeholder',
            scope: {
                fieldName: '@entityFieldMachineValue',
                stateParams: '=entityFieldMachineValueParams'
            }
        };
    });
