'use strict';

angular.module('route')
    .factory('RouteMessages', ['gettext', function(gettext) {
        return {
            getHumanReadableMessage: function(code) {
                var message;

                switch (code) {
                    case 'authentication-required':
                        message = gettext('Para acceder a la página solicitada debe iniciar sesión en ShareCRF');
                        break;

                    case 'successful-logout':
                        message = gettext('Se ha cerrado la sesión. Identifíquese para acceder de nuevo a la aplicación.');
                        break;

                    case 'session-expired':
                        message = gettext('Su sesión ha caducado porque ha transcurrido demasiado tiempo sin actividad.\nPor favor, identifíquese de nuevo para acceder a la aplicación.');
                        break;

                    case 'sign-action-max-attemps-reached':
                        message = gettext('Se ha cerrado la sesión automáticamente al alcanzar el número máximo de firmas incorrectas consecutivas. Identifíquese para acceder de nuevo a la aplicación.');
                        break;

                    default:
                        message = code;
                }

                return message;
            },
        };
    }]);
