/*! inetsys-angular-utils - v0.0.4 - 2016-12-23 */
'use strict';

// Source: src/index.js
angular.module('inetsys.utils', [
]);


// Source: src/confirm-state-exit.js
// usage: confirmStateExit($scope, 'form.$dirty' [, tpl])

angular.module('inetsys.utils')
.provider('confirmStateExitConfig', function() {
  this.template = 'src/confirm-state-exit/dirty-modal.tpl.html';

  this.$get = function() {
    return this;
  };
})
.factory('confirmStateExit', ['$rootScope', '$uibModal', '$state', 'confirmStateExitConfig', '$log', function($rootScope, $uibModal, $state, confirmStateExitConfig, $log) {
  var counter = 0;

  return function confirm_state_exit($scope, cond_expr, tpl, open_cb) {
    var _counter = ++counter;

    $log.debug('(confirmStateExit ', _counter, ') init');

    tpl = tpl || confirmStateExitConfig.template;

    var opened = false;

    var cse = {
      confirmed: false,
      is_dirty: function(event, toState, toParams, fromState, fromParams) {
        if ('function' === typeof cond_expr) {
          $log.debug('(confirmStateExit ', _counter, ') cond_expr()');
          return cond_expr(cse, event, toState, toParams, fromState, fromParams);
        }

        return $scope.$eval(cond_expr);
      },
      prevent: function prevent(event, toState, toParams, fromState, fromParams) {
        event.preventDefault();
        $rootScope.$emit('$stateChangePrevented', event, toState, toParams, fromState, fromParams);
      },
      go: function(toState, toParams) {
        cse.confirmed = true;
        $state.go(toState, toParams);
      },
      open: function(ok, leave) {
        opened = true;
        var modalInstance = $uibModal.open({
          templateUrl: tpl,
          windowClass: 'zindex-9999',
          backdrop: 'static',
          keyboard: false,
          controller: ['$scope', function($scope_modal) {
            open_cb && open_cb($scope_modal);

            $scope_modal.ok = function() {
              opened = false;
              ok && ok(modalInstance);
              modalInstance.close();
            };

            $scope_modal.leave = function() {
              opened = false;
              leave && leave(modalInstance);
              modalInstance.close();
            };
          }]
        });
      }
    };

    // if dirty, show a warning
    var cancel_dirty_leave = $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
      // avoid double open when $locationProvider.when is in use
      $log.debug('(confirmStateExit ', _counter, ') opened', opened);
      if (opened) {
        cse.prevent(event, toState, toParams, fromState, fromParams);
        return;
      }

      var is_dirty = cse.is_dirty(event, toState, toParams, fromState, fromParams);

      $log.debug('(confirmStateExit ', _counter, ') is_dirty?', is_dirty, ' cse.confirmed?', cse.confirmed);

      if (is_dirty && !cse.confirmed) {
        cse.prevent(event, toState, toParams, fromState, fromParams);
        cse.open(null, function() {
          cse.go(toState, toParams);
        });
      }
    });

    $scope.$on('$destroy', function() {
      cancel_dirty_leave();
      $log.debug('(confirmStateExit ', _counter, ') removed');
    });

    return cse;
  };
}]);


// Source: src/data-source.js
// Helpers to work with sources
// sources can be used for: selects & filters
function setDataSource(app) {
  app.dataSource = function dataSource(
    source_name, values,
    filter_name, all_filters_label) {
    this
      .value(source_name, values)
      .run(['$rootScope', function($rootScope) {
        $rootScope[source_name] = values;
        $rootScope[source_name + '_filters'] = [{id: null, label: all_filters_label}].concat(values);
      }])
      .filter(filter_name, ['sourceGetLabel', function(sourceGetLabel) {
        return function(x) {
          return sourceGetLabel(values, x);
        };
      }]);

    return this;
  };

  app.factory('sourceGetLabel', function() {
    return function source_get_label(values, id) {
      if (Array.isArray(id)) {
        return id.map(function(v) {
          return source_get_label(values, v);
        }).join(', ');
      }

      if ('object' === typeof id) {
        return source_get_label(values, id.key || id.id);
      }

      var i;
      for (i = 0; i < values.length; ++i) {
        if (values[i].id === id) {
          return values[i].label;
        }
      }

      return '??';
    };
  });

  return app;
}


// Source: src/formalizer.js
//
// Get formalizer metadata from API
// and override messages with defaults in spanish
//

angular.module('inetsys.utils')
.service('UtilsFormalizer', ['$http', '$templateCache', '$interpolate', function($http, $templateCache, $interpolate) {
  // override messages in spanish
  function set_messages(fields) {
    var default_messages = {
      'required': 'Campo obligatorio',
      'min': 'Valor mínimo: {{element.attrs[\'min\']}}',
      'max': 'Valor máximo: {{element.attrs[\'max\']}}',
      'minlength': 'Al menos debe tener {{element.attrs[\'ng-maxlength\']}} caracteres',
      'maxlength': 'Máximos caracteres excedidos: {{element.attrs[\'ng-maxlength\']}}',
      'number': 'Número no válido',
      'email': 'Email no válido',
      'url': 'URL inválida',
      'blacklist': 'El valor esta en una lista negra',
      //'equal-to':
      'only-alpha': 'Sólo letras',
      'only-iso': 'Sólo \'A-Z, a-z, 0-9\' son caracteres válidos',
      'one-upper': 'Al menos una mayúscula',
      'one-lower': 'Al menos una minúscula',
      'one-number': 'Al menos un número',
      'one-alpha': 'Al menos una letra',
      'alphanumeric': 'Sólo letras y números',
      //'server-validation':
      'length': 'Debe tener exactamente {{element.attrs[\'ng-length\']}} caracteres',
      'decimals': 'Máximo número de decimales excedido: {{element.attrs[\'ng-decimals\']}}',
      'no-decimals': 'No puede tener decimales',
      'date': 'Campo fecha no válido',
      'required_list': 'Al menos uno debe ser marcado'
    };

    var i;
    var j;
    for (i in fields) {
      fields[i].messages = fields[i].messages || {};
      for (j in default_messages) {
        if (fields[i].messages[j] === undefined) {
          fields[i].messages[j] = $interpolate(default_messages[j])({element: fields[i]});
        }
      }
    }

    return fields;
  }
  return {
    set_messages: set_messages,
    get: function(entity) {
      return $http({
        method: 'GET',
        url: '/api/' + entity + '/formalizer',
        cache: $templateCache // just once
      }).then(function(data) {
        set_messages(data.data);
        return data;
      });
    }
  };
}])
.run(['$rootScope', function($rootScope) {
  // API - formalizer
  // spanish postal code
  $rootScope.$postal_code_regex = /^(0[1-9]|[1-4][0-9]|5[0-2])[0-9]{3}$/;
}]);


// Source: src/loading.js
// hook ui-router and httpProvider supporting loading screens
// without any verbose code

angular.module('inetsys.utils')
.factory('chainLoading', ['$rootScope', function($rootScope) {
  return function chainLoading(promise) {
    if (!$rootScope.loading || $rootScope.loading.$$state.status === 1) {
      $rootScope.loading = promise;
    } else {
      $rootScope.loading = $rootScope.loading.then(function() { return promise; });
    }

    return $rootScope.loading;
  };
}])
.factory('chainLoadingQ', ['$rootScope', '$q', function($rootScope, $q) {
  return function chainLoading() {
    var defer = $q.defer();
    if (!$rootScope.loading || $rootScope.loading.$$state.status === 1) {
      $rootScope.loading = defer.promise;
    } else {
      $rootScope.loading = $rootScope.loading.then(function() { return defer.promise; });
    }
    return defer;
  };
}])
// state change -> loading!
.run(['$rootScope', 'chainLoadingQ', '$log', function($rootScope, chainLoadingQ, $log) {
  var defer = null;

  $rootScope.$on('$stateChangeStart', function(event, toState/*, toParams, fromState, fromParams*/) {
    $log.debug('(Loading) $stateChangeStart', toState.name);
    if (!defer) {
      defer = chainLoadingQ();
    }
  });

  $rootScope.$on('$stateChangePrevented', function(event, toState/*, toParams, fromState, fromParams*/) {
    $log.debug('(Loading) $stateChangePrevented', toState.name);
    if (defer) {
      defer.resolve();
      defer = null;
    }
  });
  $rootScope.$on('$stateChangeSuccess', function(event, toState/*, toParams, fromState, fromParams*/) {
    $log.debug('(Loading) $stateChangeSuccess', toState.name);
    if (defer) {
      defer.resolve();
      defer = null;
    }
  });

  $rootScope.$on('$stateChangeError', function(/*event, tostate, toparams*/) {
    $log.error('(Loading) $stateChangeError', arguments);
    if (defer) {
      defer.resolve();
      defer = null;
    }
  });

  $rootScope.$on('$stateNotFound', function(/*event, unfoundState, fromState, fromParams*/) {
    $log.error('(Loading) $stateNotFound', arguments);
    if (defer) {
      defer.resolve();
      defer = null;
    }
  });
}])
.factory('httpLoadingInterceptor', ['$q', '$rootScope', '$log', 'chainLoadingQ', function($q, $rootScope, $log, chainLoadingQ) {
  var requests = 0;
  var defer = null;

  return {
    request: function(config) {
      if (config.noLoading) {
        return config;
      }

      requests++;

      if (requests === 1) {
        defer = chainLoadingQ();
      }

      //$log.log('(httpLoadingInterceptor) request', requests, config.url);

      // Show loader
      $rootScope.$broadcast('$loading');
      return config;
    },
    response: function(response) {
      if (response.config && response.config.noLoading) {
        return response;
      }

      //$log.log('(httpLoadingInterceptor) response', requests, response.config ? response.config.url : '?');

      if ((--requests) === 0) {
        // Hide loader
        $rootScope.$broadcast('$loaded');
        defer.resolve();
      }

      return response;
    },
    responseError: function(response) {
      if (response.config && response.config.noLoading) {
        return $q.reject(response);
      }

      //$log.log('(httpLoadingInterceptor) responseError', requests, response.config ? response.config.url : '?');

      if ((--requests) === 0) {
        // Hide loader
        $rootScope.$broadcast('$loaded');
        defer.resolve();
      }

      return $q.reject(response);
    }
  };
}])
.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push('httpLoadingInterceptor');
}]);


// Source: src/ng-click-if.js
//
// Prenvent click unless the condition is true
//

angular.module('inetsys.utils')
.directive('ngClickIf', ['$log', function($log) {
  return {
    restrict: 'A',
    link: function($scope, $elem, $attrs) {
      $log.debug('(ngClickIf) attach to', $elem);
      $elem.on('click', function($event) {
        var cond = $scope.$eval($attrs.ngClickIf);
        $log.debug('(ngClickIf) clicked: ', $attrs.ngClickIf, 'is', cond);
        if (!cond) {
          $log.debug('(ngClickIf) prevent!');
          $event.preventDefault();
          $event.stopPropagation();
        }
      });
    }
  };
}]);


// Source: src/ng-open-modal.js
//
// shortcut to open a modal
// usage:
//   ng-open-modal="'/views/xxx.tpl.html'"
//   [before-open="<expression>"]
//   [after-open="<expression>"]
//   [size="lg"]
//

angular.module('inetsys.utils')
.directive('ngOpenModal', ['$uibModal', function($uibModal) {
  return {
    restrict: 'A',
    link: function($scope, $elm, $attrs) {
      $elm.bind('click', function() {
        if ($attrs.beforeOpen) {
          $scope.$eval($attrs.beforeOpen);
        }

        var html = $scope.$eval($attrs.ngOpenModal);
        var modalInstance = $uibModal.open({
          templateUrl: html,
          size: $attrs.size,
          controller: ['$scope', function($scope_modal) {
            $scope_modal.close = function() {
              modalInstance.close();
            };

            if ($attrs.afterOpen) {
              $scope.$eval($attrs.afterOpen);
            }
          }]
        });
      });
    }
  };
}]);


// Source: src/resolve-url-list.js
//
// GET given list from $HTTP if *.json will use $templateCache
//

angular.module('inetsys.utils')
.factory('ResolveUrlList', ['$q', '$http', '$templateCache', function($q, $http, $templateCache) {
  return function(list) {
    var promises = list.map(function fetch_url(file) {
      return $http({
        url : file,
        method: 'GET',
        // cache only for json
        cache: (file.indexOf('.json') === -1 ? null : $templateCache)
      });
    });
    return $q.all(promises);
  };
}]);


// Source: src/rewrite-request.js
//
// this will rewrite any request
// usefull to keep isolated frontend from backend versioning
//

angular.module('inetsys.utils')
.provider('rewriteRequestConfig', function() {
  // Rewrite urls that start with
  this.start_with = {};
  // Add custom header to all request
  this.add_header = {};

  this.$get = function() {
    return this;
  };
})
.factory('rewriteInterceptor', ['rewriteRequestConfig', function(rewriteRequestConfig) {
  return {
    request: function(config) {
      var i, url;

      config.headers = config.headers || {};
      for (i in rewriteRequestConfig.add_header) {
        config.headers[i] = rewriteRequestConfig.add_header[i];
      }

      for (i in rewriteRequestConfig.start_with) {
        url = rewriteRequestConfig.start_with[i];
        if (config.url.indexOf(i) === 0) {
          config.url = url + config.url.substring(i.length);
        }
      }

      return config;
    }
  };
}])
.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push('rewriteInterceptor');
}]);


// Source: src/ui-router-redirect.js
//
// redirect to a new state base on: redirectTo in state definition
// example:
// $stateProvider
// .state("xx", {
//   url: "/xx",
//   redirectTo: "xx.yy"
// });
// redirectTo can be a function.
//

angular.module('inetsys.utils')
.run(['$rootScope', '$state', '$injector', '$log', '$timeout', function($rootScope, $state, $injector, $log, $timeout) {
  $rootScope.$on('$stateChangeSuccess', function(evt, to, params) {
    $log.debug('(redirectTo?)', to.redirectTo);

    if ('string' === typeof to.redirectTo) {
      $timeout(function() {
        $state.go(to.redirectTo, params);
      });
    }

    if ('function' === typeof to.redirectTo || Array.isArray(to.redirectTo)) {
      var state = $injector.invoke(to.redirectTo);
      if (state) {
        $timeout(function() {
          $state.go(state, params);
        });
      }
    }
  });
}]);
