angular.module('ecrd')
    .factory('RecordDataService', ['CoreRecordData', 'CoreCRF', function RecordDataService(CoreRecordData, CoreCRF) {
        var publicAPI = {};
        // 1. Custom functions
        /**
         * Get element state from field unique ID
         *
         * @param {array} fieldPath
         *
         * @return {string|false}
         */
        publicAPI.getFormStateNameFromFieldPath = function(fieldPath) {
            if (!Array.isArray(fieldPath)) {
                fieldPath = fieldPath.split(/[./]/);
            }
            var fieldName = fieldPath.pop();
            var stateParts = [];

            var element = CoreCRF.getElementByName(fieldName);
            var parents = element.getParents();
            parents.forEach(function(container) {
                if (container.isRoot()) {
                    stateParts.push('record');
                    stateParts.push('edit');
                } else {
                    if (container.isList() || container.isForm() || container.isSection()) {
                        // estos son los componentes que forman parte del nombre del estado
                        stateParts.push(container.getName());
                    }
                    if (container.isList()) {
                        stateParts.push('item');
                    }
                }
            });

            return stateParts.length ? stateParts.join('.') : false;
        };
        /**
         * Get element state params from field unique ID
         *
         * @param {array} fieldPath
         *
         * @return {object}
         * @throws ReferenceError if field do not exist
         */
        publicAPI.getFormStateParamsFromFieldPath = function(fieldPath){
            var fieldUId = publicAPI.getElementUIdByNamedPath(fieldPath);
            var listIndices = {};
            var list;
            try {
                listIndices = publicAPI.getListIndicesByUID(fieldUId);
            } catch (err) {
                // 'Item does not exist'
                var error = new ReferenceError('deleted-element');
                list = CoreCRF.getElement(err.context.listId);
                error.data = {
                    stateParams: err.context.indices,
                    deletedElement: err.context.itemId,
                    listName: list ? list.getName() : err.context.listId,
                };

                throw error;
            }

            var stateParams = {};
            for (var listId in listIndices) {
                list = CoreCRF.getElement(listId);
                stateParams[list.getName()] = listIndices[listId] + 1;
            }

            return stateParams;
        };

        publicAPI.sameListIndices = function(listIndices1, listIndices2) {
            var indicesObject1 = angular.isObject(listIndices1) ? listIndices1 : {};
            var indicesObject2 = angular.isObject(listIndices2) ? listIndices2 : {};

            var keys1 = Object.keys(indicesObject1);
            var keys2 = Object.keys(indicesObject2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            return keys1.every(function(key) {
                return indicesObject1[key] === indicesObject2[key];
            });
        };

        // 2. Inherited public
        Object.getOwnPropertyNames(Object.getPrototypeOf(CoreRecordData)).filter(function(key) {
            if (typeof CoreRecordData[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                if (typeof publicAPI[key] != 'undefined') {
                    throw new Error("Function "+key+" is present in Core object");
                }
                publicAPI[key] = CoreRecordData[key].bind(CoreRecordData);
            }
        });

        return publicAPI;
    }]);
