angular.module('app.footer', ['sharecrf-version']).controller('FooterController', [
    '$window',
    'ShareCrfUrl',
    'StudyLanguageService',
    'ShareCrfVersion',
    function($window, ShareCrfUrl, StudyLanguageService, ShareCrfVersion) {
        var vm = this;

        var urlWeb = ShareCrfUrl.getWww();
        var lang = StudyLanguageService.language;
        vm.SHARECRF_VERSION = $window.__SCRF_VERSION || '@dev';

        vm.versionData = ShareCrfVersion.get();

        var footerLinks = {
            front: {
                es: 'es',
                en: '',
                de: '',
                pt: '',
            },
            terms: {
                es: 'es/terminos-uso-servicio',
                pt: 'es/terminos-uso-servicio',
                en: 'terms-use-service',
                de: 'terms-use-service',
            },
            privacy: {
                es: 'es/politica-privacidad',
                pt: 'es/politica-privacidad',
                en: 'privacy-policy',
                de: 'privacy-policy',
            },
            conditions: {
                es: 'es/terminos-condiciones-servicio',
                pt: 'es/terminos-condiciones-servicio',
                en: 'service-terms-conditions',
                de: 'service-terms-conditions',
            },
            cookies: {
                es: 'es/politica-cookies',
                pt: 'es/politica-cookies',
                en: 'cookies-policy',
                de: 'cookies-policy',
            },
        };

        vm.getLink = function(key) {
            return footerLinks[key] ? urlWeb + footerLinks[key][lang.code || 'es'] : '#';
        };

        vm.currentYear = (new Date()).getFullYear();
    },
]);
