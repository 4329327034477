angular.module('app.entity.menu', [
    'app.entity.menu.plugin-completion',
    'app.entity.menu.plugin-expand',
    'app.entity.menu.plugin-list-items',
    'app.entity.menu.plugin-pending-changes',
    'app.entity.menu.plugin-validation',
    'app.entity.menu.plugin-visibility',
    'app.entity.service',
    'inetsys.tools',
    'ui.router',
]);
