'use strict';

angular.module('study.import')
.component('importLoadCSVProcess', {
    templateUrl: 'modules/import/components/load-csv/process.html',
    controller: 'ImportLoadCSVProcessController',
    bindings: {
        importId: '@',
        sources: '=',
        importMode: '@',
    },
});
