'use strict';

angular.module('sharecrf-url', [])
    .service('ShareCrfUrl', ['$window', function($window) {
        function buildUrl(prefix) {
            var hostParts = $window.location.host.split('.');
            hostParts.shift();
            hostParts.unshift(prefix);

            return $window.location.protocol + '//' + hostParts.join('.') + '/';
        }

        return {
            getWww: function() {
                return buildUrl('www');
            },
            getPortal: function() {
                return buildUrl('portal');
            },
            getDomain: function() {
                var hostParts = $window.location.host.split(':');
                hostParts = hostParts[0].split('.');
                hostParts.shift();

                return hostParts.join('.');
            },

            isEntityData: function(url) {
                return /^\/(live|test)\/api\/records\/\d+/.test(url);
            },
        };
    }]);
