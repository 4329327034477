'use strict';

angular.module('app.entity.field').controller('GridRowActionsController', [
    '$exceptionHandler',
    '$injector',
    '$stateParams',
    'RecordDataService',
    'StudyContext',
    'FormFieldsService',
    'CrfService',
    '$rootScope',
    'CrdConfigurationService',
    'ViewMode',
    'ViewModes',
    'Event',
    'Modal',
    function(
        $exceptionHandler,
        $injector,
        $stateParams,
        RecordDataService,
        StudyContext,
        FormFieldsService,
        CrfService,
        $rootScope,
        CrdConfigurationService,
        ViewMode,
        ViewModes,
        Event,
        Modal
    ) {
        var vm = this;

        // Atributos del campo: queremos saber si el campo es modificable
        var listIndices = RecordDataService.getCRF().getListIndices($stateParams);
        var uidPrefix = '';

        // Instancia del grid
        var grid = null;

        // Instancias de los campos que conforman la fila del grid
        var rowFields = [];

        // Suscripción a eventos de visibilidad de queries
        var queriesVisibilityListeners = [];

        var nonEditableRevisionStates = [];
        var revisionModeHandler = null;
        if (CrdConfigurationService.hasRevisionStates()) {
            vm.revisionModeEnabled = ViewMode.get() === ViewModes.REVISION;
            revisionModeHandler = Event.on('viewModeChanged', function(event, mode) {
                vm.revisionModeEnabled = mode === ViewModes.REVISION;
            });
            nonEditableRevisionStates = CrdConfigurationService.getRevisionStates().getStates().filter(function(state) {
                return !state.allowsEdition();
            });
        }

        var missingValue = $injector.has('MISSING_VALUE') ? $injector.get('MISSING_VALUE') : undefined;
        var missingDataService = $injector.has('MissingDataService') ? $injector.get('MissingDataService') : undefined;
        var revisionCountService = $injector.has('RevisionCount') ? $injector.get('RevisionCount') : undefined;

        vm.$onInit = function() {
            grid = CrfService.getField(vm.gridId);
            if (grid) {
                rowFields = (grid.getCells()[vm.rowIndex] || []).filter(function(cell) {
                    return cell;
                });

                uidPrefix = RecordDataService.getElementUID(vm.gridId, listIndices).slice(0, -1).join('.');
                if (uidPrefix) {
                    uidPrefix += '.';
                }
            }

            // Permisos para las acciones: controla la visibilidad
            vm.permissions = {
                resetValue: canResetValue(),
                viewNotes: angular.isUndefined(vm.disableNotes) && StudyContext.canWithQueries('read'),
                viewAudit: angular.isUndefined(vm.disableAudit) && StudyContext.canAccessEntityAudit(),
            };
            if (missingDataService) {
                // Se añaden como propiedades dinámicas para reaccionar a los cambios de valor
                Object.defineProperties(vm.permissions, {
                    setMissingData: {
                        enumerable: true,
                        get: canSetMissingData,
                    },
                    cancelMissingData: {
                        enumerable: true,
                        get: canCancelMissingData,
                    },
                });
            }

            // Capacidad actual: controla el estado de los botones
            vm.allowed = {
                // No hay restricciones para consultar las notas y el audit más allá de ser accesibles al usuario actual
                viewNotes: vm.permissions.viewNotes,
                viewAudit: vm.permissions.viewAudit,
            };
            var tasks = {
                // Se puede eliminar el valor si el campo está editable. Con el getter vm.allowed.resetValue funciona
                // como FormFieldsService.isFieldDisabled, pero sin tener que resolver stateParams
                resetValue: {
                    enumerable: true,
                    get: function() {
                        return rowFields.some(function(cellField) {
                            var fieldId = cellField.getId();

                            return FormFieldsService.isFieldEditable(fieldId, listIndices)
                                && !hasMissingReason(fieldId)
                                && !isDisabledByRevision(fieldId);
                        });
                    },
                },
            };

            if (missingDataService) {
                tasks.setMissingData = {
                    enumerable: true,
                    get: function() {
                        return rowFields.some(function(cellField) {
                            var fieldId = cellField.getId();

                            return FormFieldsService.isFieldEditable(fieldId, listIndices)
                                && RecordDataService.isFieldValueEmpty(fieldId, listIndices);
                        });
                    },
                };
                tasks.cancelMissingData = {
                    enumerable: true,
                    get: function() {
                        return rowFields.some(function(cellField) {
                            var fieldId = cellField.getId();

                            return angular.isDefined(missingValue)
                                && FormFieldsService.isFieldEditable(fieldId, listIndices)
                                && RecordDataService.getFieldValue(fieldId, listIndices) === missingValue;
                        });
                    },
                };
            }
            Object.defineProperties(vm.allowed, tasks);

            // Acción activa sobre este campo
            vm.selectedAction = null;

            queriesVisibilityListeners = [
                'entityFieldNotesShown',
                'entityFieldNotesHidden',
            ].map(function(eventKey) {
                var eventHandler = $rootScope.$on(eventKey, function(event, fieldId) {
                    // Si el campo que muestra las queries pertenece a esta fila, simulamos el clic de las acciones
                    var belongsToRow = rowFields.some(function(field) {
                        return field.getId().toString() === fieldId.toString();
                    });

                    if (belongsToRow) {
                        vm.toggleNotes();
                    }
                });

                return eventHandler;
            });
        };

        vm.$onDestroy = function() {
            angular.forEach(queriesVisibilityListeners, function(listener) {
                listener();
            });

            if (angular.isFunction(revisionModeHandler)) {
                revisionModeHandler();
            }
        };

        vm.$onChanges = function(changes) {
            if (angular.isDefined(changes.gridId)) {
                // Aseguramos que el ID del campo sea numérico, para ayudar a las comparaciones de tipo ===
                vm.gridId = parseInt(vm.gridId, 10);

                if (vm.permissions) {
                    vm.permissions.resetValue = canResetValue();
                }
            }
        };

        /**
         * Abre/cierra la lista de acciones
         * Llama a la función de salida onToggleMenu(string field, bool value)
         */
        vm.toggle = function() {
            vm.open = !vm.open;
            vm.onToggleMenu({ field: vm.fieldId, value: vm.open });
        };

        function hasMissingReason(fieldId) {
            return missingDataService && angular.isDefined(missingDataService.getFieldReason(fieldId, listIndices));
        }

        /**
         * Acción: limpiar el valor de un campo
         * Llama a la función de salida onResetValue(string field)
         */
        vm.resetValue = function() {
            if (vm.allowed.resetValue) {
                angular.forEach(rowFields, function(field) {
                    var fieldId = field.getId();
                    // GARU-5873 Se actúa solamente sobre los campos habilitados
                    // GARU-6230 Y que no tengan el dato perdido
                    // GARU-6606 Y que no estén deshabilitados por estados de revisión
                    if (FormFieldsService.isFieldEditable(fieldId, listIndices) && !hasMissingReason(fieldId) && !isDisabledByRevision(fieldId)) {
                        vm.onResetValue({
                            field: fieldId,
                            previousValue: RecordDataService.getFieldValue(fieldId, listIndices),
                            newValue: RecordDataService.getResetValue(fieldId),
                        });
                    }
                });
            }
        };

        /**
         * Seleccionar una acción
         * @param  {string} action Nombre de la acción
         */
        function selectAction(action) {
            if (vm.selectedAction === action) {
                // Ocultar la acción
                vm.selectedAction = null;
            }
            else {
                // Cambiar la acción activa
                vm.selectedAction = action;
            }

            vm.onSelectAction({
                action: vm.selectedAction,
            });
        }

        /**
         * Acción: mostrar/ocultar lista de discrepancias
         */
        vm.toggleNotes = function() {
            if (vm.allowed.viewNotes) {
                selectAction('queries');
            }
        };

        /**
         * Acción: mostrar/ocultar audit del campo
         */
        vm.toggleAudit = function() {
            if (vm.allowed.viewAudit) {
                selectAction('audit');
            }
        };

        function canResetValue() {
            return rowFields.some(function(field) {
                if (!field || !field.allowsValue() || field.getFormControl() === 'files' || field.getFormControl() === 'dicom') {
                    return false;
                }

                return StudyContext.canUpdateEntity() && CrfService.isElementEditableToCurrentProfile(field.getId());
            });
        }

        function canSetMissingData() {
            return CrdConfigurationService.hasMissingData()
                && StudyContext.canUpdateEntity()
                && rowFields.some(function(field) {
                    var fieldId = field.getId();

                    return field
                        && field.allowsValue()
                        && field.isRequired()
                        && CrfService.isElementEditableToCurrentProfile(fieldId)
                        && RecordDataService.isFieldValueEmpty(fieldId, listIndices);
                });
        }

        function canCancelMissingData() {
            return CrdConfigurationService.hasMissingData()
                && StudyContext.canUpdateEntity()
                && rowFields.some(function(field) {
                    var fieldId = field.getId();

                    return field
                        && field.allowsValue()
                        && field.isRequired()
                        && CrfService.isElementEditableToCurrentProfile(fieldId)
                        && RecordDataService.getFieldValue(fieldId, listIndices) === missingValue;
                });
        }

        if (missingDataService) {
            vm.setMissingData = function() {
                var previousValues = rowFields.reduce(function(acc, field) {
                    var fieldId = field.getId();
                    acc[fieldId] = RecordDataService.getFieldValue(fieldId, listIndices);

                    return acc;
                }, {});

                return Modal.open({
                    templateUrl: 'modules/ecrd/components/field/grid-row-actions/modal-set-missing-data.html',
                    data: {
                        field: grid,
                        rowIndex: vm.rowIndex,
                    },
                    onlyActions: true,
                }).closePromise
                    .then(function(result) {
                        if (result.action === 'update-reason') {
                            return vm.onChangeValue({
                                field: result.fieldId,
                                previousValue: previousValues[result.fieldId],
                                newValue: result.value,
                            });
                        }

                        return vm.onToggleMenu();
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            $exceptionHandler(error, 'modal with missing data confirmation');
                        }
                    });
            };

            vm.cancelMissingData = function() {
                var missableFields = grid.getCells()[vm.rowIndex].reduce(function(acc, cell, index) {
                    if (!cell) {
                        return acc;
                    }
                    var fieldId = cell.getId();

                    if (cell.allowsValue()
                        && cell.isRequired()
                        && CrfService.isElementEditableToCurrentProfile(fieldId)
                        && RecordDataService.getFieldValue(fieldId, listIndices) === missingValue
                    ) {
                        acc.push({
                            id: fieldId,
                            field: cell,
                            label: grid.getColumns()[index],
                        });
                    }

                    return acc;
                }, []);

                return Modal.open({
                    templateUrl: 'modules/ecrd/components/field/grid-row-actions/modal-cancel-missing-data.html',
                    data: {
                        grid: grid,
                        fields: missableFields,
                    },
                }).closePromise
                    .then(function(fieldId) {
                        // Si se entra a la función es porque el servicio de missing data está definido
                        missingDataService.removeFieldReason(fieldId, listIndices);

                        vm.onResetValue({
                            field: fieldId,
                            previousValue: RecordDataService.getFieldValue(fieldId, listIndices),
                            newValue: RecordDataService.getResetValue(fieldId),
                        });
                    })
                    .catch(function(error) {
                        if (error !== Modal.DISMISSED) {
                            $exceptionHandler(error, 'modal with missing data cancellation');
                        }

                        if (vm.open) {
                            vm.toggle();
                        }
                    });
            };
        }

        function isDisabledByRevision(fieldId) {
            var fieldUid = uidPrefix + fieldId;
            return !!revisionCountService && nonEditableRevisionStates.some(function(state) {
                return revisionCountService.isFieldRevised(fieldUid, state.getId());
            });
        }
    },
]);
