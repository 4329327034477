angular.module('study.dashboard')
    .component('queriesResolutionTimeReportSummary', {
        templateUrl: 'modules/dashboard/components/queries-resolution-time-report-summary/template.html',
        bindings: {
            hideFooter: '<',
        },
        controller: [
            'ShareCrfSDK',
            '$q',
            'ShareCrfApiErrorCodes',
            function(ShareCrfSDK, $q, ShareCrfApiErrorCodes) {
                var vm = this;

                vm.loading = false;
                vm.error = null;
                vm.data = {};
                vm.lastWeekDiff = null;

                Object.defineProperty(vm, 'ready', {
                    get: function() {
                        return !vm.error && !vm.loading;
                    },
                });

                vm.$onInit = function() {
                    getData();
                };

                function getData() {
                    vm.loading = true;
                    vm.error = null;

                    return $q.resolve()
                        .then(function() {
                            return ShareCrfSDK.dashboard.queriesResolutionTimeSummary();
                        })
                        .then(function(response) {
                            vm.data = response;
                            vm.lastWeekDiff = vm.data.closing.average.lastWeek - vm.data.closing.average.allTime;
                        })
                        .catch(function (error) {
                            vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                            throw error;
                        }).finally(function () {
                            vm.loading = false;
                        });
                }
            },
        ],
    });