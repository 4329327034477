'use strict';

angular.module('ecrd.core')
    .provider('CoreRecordValidation', function() {
        this.customValidators = [];

        this.$get = ['CoreRecord', function CoreRecordValidation(CoreRecord) {
            var recordValidation = CoreRecord.getValidation();

            for (var i=0,ii=this.customValidators.length;i<ii;i++) {
                recordValidation.addCustomValidator(this.customValidators[i]);
            }

            return recordValidation;
        }];
    });
