'use strict';

angular.module('ecrd')
    .factory('FormFieldsService', ['CoreFormFields', function FormFieldsService(CoreFormFields) {
        var publicAPI = {};
        // 1. Custom functions

        // 2. Inherited public
        Object.getOwnPropertyNames(Object.getPrototypeOf(CoreFormFields)).filter(function(key) {
            if (typeof CoreFormFields[key] == 'function' && key != 'constructor' && key.indexOf('_') !== 0) {
                if (typeof publicAPI[key] != 'undefined') {
                    throw new Error("Function "+key+" is present in Core object");
                }
                publicAPI[key] = CoreFormFields[key].bind(CoreFormFields);
            }
        });

        publicAPI.events = CoreFormFields.events;

        return publicAPI;
    }]);
