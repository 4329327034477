'use strict';

angular.module('ecrd')
    .component('fieldSelector', {
        controller: 'FieldSelectorController',
        templateUrl: 'modules/ecrd/components/field-selector/template.html',
        bindings: {
            // ID del campo seleccionado
            selected: '<',
            // Lista de campos disponibles en el selector: si no se especifican el controlador los va a obtener del
            // servicio de CRF. Útil para filtrar las opciones.
            availableFields: '<',
            // ID de la lista de contexto de selección, se deben resolver índices si no coincide con la lista a la que
            // pertenece el campo
            context: '<',
            // Índices de las listas que contienen el campo seleccionado
            listIndices: '<',
            // Si se activa, al seleccionar el campo se muestra la ruta en la estructura del mismo
            showPath: '<',
            // Al modificar la selección de campo: onChange(id, listIndices)
            onChange: '&',
        },
    });
