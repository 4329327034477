angular.module('user').controller('LogoutButtonController', [
    '$log',
    'Modal',
    'AccountManager',
    'LogoutManager',
    function($log, Modal, AccountManager, LogoutManager) {
        var vm = this;

        vm.logout = function() {
            return Modal
                .open({
                    template: 'modules/user/components/logout-button/modal.html',
                    controller: 'LogoutModalController',
                    controllerAs: 'modal',
                    showClose: false,
                    closeByNavigation: true,
                })
                .closePromise
                .then(function() {
                    // Confirm button pressed
                    return AccountManager.logout()
                        .then(function() {
                            return LogoutManager.onLogout();
                        });
                    // .catch - Tratar los errores de logout aquí
                })
                .catch(function(error) {
                    // Cancel button pressed
                    if (error !== Modal.DISMISSED) {
                        $log.error(error, 'Logout action');
                    }
                });
        };
    },
]);
