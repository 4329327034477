'use strict';

angular.module('ecrd')
    .factory('EcrdService', [
        'Event',
        'AccountManager',
        'CoreCRF',
        'CoreFieldsCompletion',
        '$log',
        function(
            Event,
            AccountManager,
            CoreCRF,
            CoreFieldsCompletion,
            $log
        ) {
            var serviceData = {
                // Por cada perfil se guardan los elementos accesibles
                availabilityByProfile: {},
            };

            function _getCurrentProfileId(actionIdentifier) {
                if (!AccountManager.isLogged()) {
                    return null;
                }

                var loggedUser = AccountManager.getLoggedUser();
                var currentProfile = loggedUser.currentProfile;

                if (actionIdentifier && !currentProfile) {
                    var error = new Error('El usuario actual ID ' + loggedUser.id + ' no tiene perfil activo');
                    $log.error(error, actionIdentifier);

                    return null;
                }

                return currentProfile.id;
            }

            function _initAvailability() {
                // GARU-3883 Se lanza este evento aunque no haya usuario con sesión: hay que comprobarlo antes de seguir
                var profileId = _getCurrentProfileId('init elements availability');
                if (!profileId) {
                    return;
                }

                if (angular.isUndefined(serviceData.availabilityByProfile[profileId])) {
                    serviceData.availabilityByProfile[profileId] = {};

                    var allElements = CoreCRF.getAllElements();
                    angular.forEach(allElements, function(element) {
                        serviceData.availabilityByProfile[profileId][element.getId()] = {
                            access: element.isAvailableToProfile(profileId),
                            edit: element.isEditableToProfile(profileId),
                        };
                    });
                }

                // Y ahora informamos al servicio de cambios que no tenga en cuenta los elementos especificados
                var unavailableElements = _getUnavailableElementsToProfile(profileId);
                CoreFieldsCompletion.excludeElements(unavailableElements);
            }

            function _getElementAvailability(elementId) {
                var profileId = _getCurrentProfileId();
                if (!profileId) {
                    return null;
                }

                var availabilityByProfile = serviceData.availabilityByProfile[profileId];
                if (angular.isUndefined(availabilityByProfile)) {
                    return null;
                }

                var elementAvailability = availabilityByProfile[elementId];
                if (angular.isUndefined(elementAvailability)) {
                    return null;
                }

                return elementAvailability;
            }

            function _getUnavailableElementsToProfile(profileId) {
                var unavailableElements = [];

                angular.forEach(serviceData.availabilityByProfile[profileId], function(config, elementId) {
                    if (!config.access) {
                        unavailableElements.push(parseInt(elementId, 10));
                    }
                });

                return unavailableElements;
            }

            return {
                registerInitEvents: function() {
                    Event.on('accountInitied', _initAvailability);
                    Event.on('login', _initAvailability);
                },
                isElementAvailableToCurrentProfile: function(elementId) {
                    var elementAvailability = _getElementAvailability(elementId);

                    return elementAvailability ? !!elementAvailability.access : false;
                },
                isElementEditableToCurrentProfile: function(elementId) {
                    if (CoreCRF.ecrd.configuration.hasEncryptedFields()) {
                        var field = CoreCRF.getField(elementId);
                        if (field && field.isEncrypted() && !AccountManager.hasPermission('view encrypted fields')) {
                            return false;
                        }
                    }

                    var elementAvailability = _getElementAvailability(elementId);

                    return elementAvailability ? !!elementAvailability.edit : false;
                },
                getUnavailableElementsToCurrentProfile: function() {
                    var profileId = _getCurrentProfileId();
                    if (!profileId) {
                        return [];
                    }

                    return _getUnavailableElementsToProfile(profileId);
                },
            };
        },
    ]);
