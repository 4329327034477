'use strict';

angular.module('app.entity.field')
/**
 * Controlador del componente <field-actions-detail> para cada campo del cuaderno
 *
 * @param  {integer} vm.fieldId   ID del campo en el CRF
 * @param  {string}  vm.selected  Acción seleccionada mediante la rueda de acciones del campo
 *                                'queries', 'audit'
 */
.controller('FieldActionsDetailController', ['$log', '$injector', function($log, $injector) {
    var vm = this;

    vm.$onInit = function() {
        $log.debug('FieldActionsDetailController fieldId', vm.fieldId);
        $log.debug('FieldActionsDetailController selected', vm.selected);
    };

    var notesVisibilityService = $injector.has('EntityNotesVisibilityService') ? $injector.get('EntityNotesVisibilityService') : null;
    var visibleNotes = false;

    vm.$onChanges = function(changes) {
        if (angular.isDefined(changes.selected) && notesVisibilityService) {
            if (changes.selected.previousValue === 'notes') {
                // Ocultar las notas si ya no es la activa
                notesVisibilityService.setFieldNotesVisibility(vm.fieldId, false);
            }

            if (changes.selected.currentValue === 'notes') {
                // Mostrar las notas si es la opción seleccionada
                notesVisibilityService.setFieldNotesVisibility(vm.fieldId, true);
            }
        }
    };

    vm.$onDestroy = function() {
        // Si en el momento de destruir el componente estaban visibles las notas, las ocultamos
        if (vm.selected === 'notes' && notesVisibilityService && notesVisibilityService.areNotesVisible(vm.fieldId)) {
            notesVisibilityService.setFieldNotesVisibility(vm.fieldId, false);
        }
    };

}]);
