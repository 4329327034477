(function() {
    'use strict';

    angular.module('ecrd')
        .directive('sharecrfField', ['RecordValidationService', '$stateParams', function sharecrfField(RecordValidationService, $stateParams) {
            return {
                require: ['ngModel'],
                priority: 0.1, // para que se ejecute después de inetsysNumber (con prioridad 0.5)
                restrict: 'A',
                link: function($scope, $element, $attrs, controllers) {
                    var fieldId = $attrs.sharecrfField;

                    var crf = RecordValidationService.getCRF();
                    var listIndices = crf.getListIndices($stateParams);

                    var ngModelController = controllers[0];

                    var validators = RecordValidationService.field(fieldId).getValidators();
                    angular.forEach(validators, function(callback, key) {
                        ngModelController.$validators[key] = function(modelValue, viewValue) {
                            // Si el campo no tiene valor puede ser que sea inválido: probamos con el dato visible
                            var value = angular.isDefined(modelValue) ? modelValue : viewValue;
                            var isValid = callback(_typedValue(value), listIndices);

                            return isValid;
                        };
                    });

                    /**
                     * La directiva inetsysNumber devuelve valores inválidos si está así definido en el modelo del campo
                     * Por ejemplo, si el valor está fuera del rango de los números se obtiene una cadena representada
                     * como el valor en formato exponencial. El validador del core es bastante estricto y no dará por
                     * bueno ese dato al no ser un número, por lo que en este punto se intenta transformar y así tener
                     * un número con un error de rango, pero no un error de "esto no es un número"
                     *
                     * @param  {mixed} viewValue El valor obtenido del modelo
                     *
                     * @return {mixed}           El valor tipado normalizado
                     */
                    function _typedValue(viewValue) {
                        if (crf.getField(fieldId).getFieldType() !== 'number') {
                            return viewValue;
                        }

                        if (angular.isNumber(viewValue)) {
                            return viewValue;
                        }

                        // Se permiten valores de cadena vacía en el input
                        if (!viewValue) {
                            return null;
                        }

                        // También se permiten valores con notación exponencial que hay que transformar en los números
                        // correspondientes
                        var exponentialRegex = /^(-\s*)?\d+([.,]\d+)?e[+-]?\d+$/i;
                        if (exponentialRegex.test(viewValue)) {
                            return Number(viewValue.replace(',', '.')); // los separadores decimales pueden ser comas...
                        }

                        return viewValue;
                    }
                },
            };
        }]);
})();
