'use strict';

angular.module('sharecrf.cookies')
    .controller('ShareCrfCookiesController', ['$cookies', 'StudyLanguageService', 'ShareCrfUrl', function($cookies, StudyLanguageService, ShareCrfUrl) {
        var vm = this;

        vm.hrefCookiesPolicy = ShareCrfUrl.getWww() +
            ((StudyLanguageService.language.code === 'es') ? 'es/politica-cookies' : 'cookies-policy');

        vm.cookies = {
            accepted: $cookies.get('cb-enabled') === 'accepted',
        };

        vm.accept = function() {
            $cookies.put('cb-enabled', 'accepted');
            vm.cookies.accepted = true;
        };
    }]);
