angular.module('sharecrf.utils').directive('textSelected', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function($scope, $element) {
            $timeout(function() {
                $element[0].focus();
                $element[0].setSelectionRange(0, $element[0].value.length);
            });
        }
    };
}]);
