angular
    .module('app.entity.menu.plugin-expand', ['ui.router'])
    .factory('EntityMenuPluginExpand', [function() {
        return {
            initFormFlags: function(itemMetadata) {
                itemMetadata.active = false;
            },
            initSectionFlags: function(itemMetadata) {
                itemMetadata.expanded = false;
                itemMetadata.activePath = false;
            },
            initListFlags: function(itemMetadata) {
                itemMetadata.expanded = false;
                itemMetadata.active = false;
                itemMetadata.activePath = false;
            },
            initListItemFlags: function(itemMetadata) {
                itemMetadata.expanded = false;
                itemMetadata.activePath = false;
            },

            onRouteExit: function(argumentsList) {
                var itemMetadata = argumentsList[0];

                itemMetadata.active = false;

                return true;
            },
            onRouteEnter: function(argumentsList) {
                var itemMetadata = argumentsList[0];
                itemMetadata.active = true;

                return true;
            },

            propagate: function(itemMetadata) {
                var previousActive = itemMetadata.activePath;
                var newActive = !itemMetadata.children.every(function(child) {
                    return !child.active && !child.activePath;
                });
                itemMetadata.activePath = newActive;

                var previousExpanded = itemMetadata.expanded;
                var newExpanded = itemMetadata.expanded || itemMetadata.children.some(function(child) {
                    return !!child.active || !!child.activePath;
                });
                itemMetadata.expanded = newExpanded;

                return previousActive !== newActive || previousExpanded !== newExpanded;
            },
        };
    }]);
