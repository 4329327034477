/* eslint-disable */
; (function(global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined'
        ? module.exports = factory()
        : typeof define === 'function' && define.amd
            ? define(factory)
            : global.ShareCrfLibUtils = factory();
/* eslint-enable */
}(this, (function() {
    'use strict';

    var ShareCrfLibUtils = {
        /**
         * Clean element IDs by UIDs
         *
         * @param {string[]} uids UIDs
         * @param {object}   data Patient data
         *
         * @returns {object}      Cleaned payload
         */
        cleanElementIdsByUids: function cleanElementIdsByUids(uids, data) {
            // In reverse order (a child element is processed before its parent element)
            // ES6: [...uids].sort(/*...*/)
            var orderedUids = uids.slice().sort(function(itemA, itemB) {
                return itemA < itemB ? 1 : -1;
            });

            // Do not modify the original "data" object
            var cleanData = JSON.parse(JSON.stringify(data));

            // Process each UID
            orderedUids.forEach(function(uid) {
                ShareCrfLibUtils.cleanElementIdsByUid(uid, cleanData);
            });

            // Modified data
            return cleanData;
        },
        /**
         * Clean element ID by UID
         *
         * @param {string} uid UID
         * @param {object} dataReference Data object
         */
        cleanElementIdsByUid: function cleanElementIdsByUid(uid, dataReference) {
            // UID
            // ES6: const [listId, elementId, ...innerUid] = uid.split('.');
            var uidParts = uid.split('.');
            var listId = uidParts[0];
            var elementId = uidParts[1];
            var innerUid = uidParts.slice(2);

            // Get the target element in the first level list
            var elementData = (dataReference[listId] || []).find(function(element) {
                return element.__id == elementId;
            });

            if (!elementData) {
                // If the target element does not exist, nothing to do
                return;
            }

            if (innerUid.length === 0) {
                // If this is the final target element, delete its ID
                delete elementData.__id;
            }
            else {
                // Continue with the inner object
                ShareCrfLibUtils.cleanElementIdsByUid(innerUid.join('.'), elementData);
            }
        },
    };

    Object.freeze(ShareCrfLibUtils);

    return ShareCrfLibUtils;
})));
