'use strict';

angular.module('study.import')
    .config(['$stateProvider', 'RouteProvider', function($stateProvider, RouteProvider) {
        $stateProvider.state(RouteProvider.create('import', {
            url: '/import',
            component: 'importMain',
            permission: 'access study import',
            redirectTo: 'import.create',
        }));
        $stateProvider.state(RouteProvider.create('import.create', {
            url: '/create',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Modelo CSV de importación');
            }],
            component: 'importCreateCSV',
        }));
        $stateProvider.state(RouteProvider.create('import.data', {
            url: '/data/:mode',
            component: 'importLoadCSVMain',
            resolve: {
                routeParamMode: ['$stateParams', function($stateParams) {
                    return $stateParams.mode;
                }],
            },
            redirectTo: 'import.data.start',
        }));
        $stateProvider.state(RouteProvider.create('import.data.start', {
            url: '/start',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Importar datos');
            }],
            component: 'importLoadCSVStart',
            resolve: {
                importMode: ['routeParamMode', function(routeParamMode) {
                    return routeParamMode;
                }],
            },
        }));
        $stateProvider.state(RouteProvider.create('import.data.process', {
            url: '/process',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Importación en proceso');
            }],
            component: 'importLoadCSVProcess',
            params: {
                importId: null,
                sources: {},
            },
            resolve: {
                importId: ['$stateParams', function($stateParams) {
                    return $stateParams.importId;
                }],
                sources: ['$stateParams', function($stateParams) {
                    return $stateParams.sources;
                }],
                importMode: ['routeParamMode', function(routeParamMode) {
                    return routeParamMode;
                }],
            },
        }));
        $stateProvider.state(RouteProvider.create('import.data.finished', {
            url: '/finished',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Importación finalizada');
            }],
            component: 'importLoadCSVFinished',
            params: {
                importId: null,
                sources: {},
            },
            resolve: {
                importId: ['$stateParams', function($stateParams) {
                    return $stateParams.importId;
                }],
                sources: ['$stateParams', function($stateParams) {
                    return $stateParams.sources;
                }],
                importMode: ['routeParamMode', function(routeParamMode) {
                    return routeParamMode;
                }],
            },
        }));
        $stateProvider.state(RouteProvider.create('import.records-id', {
            url: '/records-id',
            pageTitle: ['gettextCatalog', function(gettextCatalog) {
                return gettextCatalog.getString('Modificar ID de registros');
            }],
            component: 'importRecordsId',
            permission: ['import records', 'id records'],
        }));
    }]);
