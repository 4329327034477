angular.module('app.entity.menu.plugin-list-items', [
    'app.entity.service',
]).factory('EntityMenuPluginListItems', [
    'CrfService',
    'RecordDataService',
    function(
        CrfService,
        RecordDataService
    ) {

        function setListItems(itemMetadata, listData) {
            var previousValue = itemMetadata.listItems || 0;
            var newValue = Array.isArray(listData) ? listData.length : 0;

            var changed = previousValue !== newValue;

            itemMetadata.listItems = newValue;

            return changed;
        }

        return {
            initListFlags: function(itemMetadata, elementId, entityData, stateParams) {
                var listIndices = CrfService.getListIndices(stateParams);
                var listData = RecordDataService.getList(elementId, listIndices);

                return setListItems(itemMetadata, listData);
            },
            reloadFlags: function(itemMetadata, elementId, elementType, sectionState, stateParams) {
                var changed = false;
                if (elementType === 'list') {
                    var listIndices = CrfService.getListIndices(stateParams);
                    var listData = RecordDataService.getList(elementId, listIndices);

                    changed = setListItems(itemMetadata, listData);
                }

                return changed;
            },
        };
    },
]);
