angular.module('app.entity.actions').controller('EntityActionsController', [
    '$exceptionHandler',
    'Modal',
    function(
        $exceptionHandler,
        Modal
    ) {
        var vm = this;

        vm.showInvalidHelp = function() {
            return Modal.open({
                templateUrl: 'modules/error/components/help-invalid-record/content.html',
                controller: 'HelpInvalidErrorController',
                controllerAs: 'modal',
            }).closePromise
                .catch($exceptionHandler);
        };
    }]);
