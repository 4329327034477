/* eslint-disable angular/component-limit */
angular.module('study-environment-interceptor', []).factory('StudyEnvironmentInterceptor', [
    '$q',
    '$state',
    function($q, $state) {
        return {
            responseError: function(rejection) {
                var error = rejection.data || {};
                if (error.type === 'AuthError' && error.code == 'study-environment-not-allowed') {
                    var defer = $q.defer();

                    $state.go('error', {
                        type: 'StudyUserError',
                        code: 'environment-access-denied',
                    });

                    return defer.promise;
                }

                return $q.reject(rejection);
            },
        };
    }])
    .config(['$httpProvider', function($httpProvider) {
        $httpProvider.interceptors.push('StudyEnvironmentInterceptor');
    }]);
