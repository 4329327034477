angular.module('app.entity.field')
    .component('fieldDatetime', {
        templateUrl: 'modules/ecrd/components/field/field-datetime/content.html',
        require: {
            ngModel: 'ngModel',
        },
        bindings: {
            fieldId: '@',
            fieldName: '@',
            disabled: '<ngDisabled',
        },
        controller: [
            'CrdConfigurationService',
            'CrfService',
            '$scope',
            function(CrdConfigurationService, CrfService, $scope) {
                var vm = this;
                vm.fieldDate = null;
                vm.fieldTime = null;
                vm.dateFormat = '';
                vm.timeFormat = '';
                vm.dateTimeFormat = '';
                vm.accuracy = null;

                vm.ngModel = null;

                function formatValue() {
                    var date = vm.fieldDate || '';
                    var time = vm.fieldTime || '';

                    if (!date && !time) {
                        return '';
                    }

                    return date + ' ' + time;
                }

                vm.modelValue = function() {
                    return vm.ngModel.$modelValue;
                };

                vm.isValid = function() {
                    return vm.ngModel.$valid;
                };

                vm.$onInit = function() {
                    var field = CrfService.getField(vm.fieldId);
                    vm.accuracy = field.getConstraint('accuracy');
                    vm.dateFormat = CrdConfigurationService.getDateFormat();

                    if (vm.accuracy === 'seconds') {
                        vm.timeFormat = 'HH:mm:ss';
                        vm.dateTimeFormat = 'datetimeinputseconds';

                    } else {
                        vm.timeFormat = 'HH:mm';
                        vm.dateTimeFormat = 'datetimeinputminutes';
                    }

                    vm.ngModel.$render = function() {
                        var value = vm.ngModel.$viewValue;
                        if (value) {
                            var date = value.split(' ');
                            vm.fieldDate = date[0];
                            vm.fieldTime = date[1];

                        } else {
                            vm.fieldDate = null;
                            vm.fieldTime = null;
                        }

                        vm.ngModel.$setViewValue(value);
                    };

                    $scope.$watch('$ctrl.fieldDate', function(newValue, oldValue) {
                        if (newValue !== oldValue) {
                            var datetime = formatValue();
                            vm.ngModel.$setViewValue(datetime);
                        }
                    });

                    $scope.$watch('$ctrl.fieldTime', function(newValue, oldValue) {
                        if (newValue !== oldValue) {
                            var datetime = formatValue();
                            vm.ngModel.$setViewValue(datetime);
                        }
                    });
                };
            },
        ],
});