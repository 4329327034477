'use strict';

angular.module('app.entity.menu.plugin-visibility', [
    'ui.router',
])
    .factory('EntityMenuPluginVisibility', ['FormFieldsService', 'CrfService', function(FormFieldsService, CrfService) {

        function initVisibleFlag(itemMetadata, elementId, entityData, stateParams) {
            var listIndices = CrfService.getListIndices(stateParams);
            var sectionFlags = FormFieldsService.getElementFlags(elementId, listIndices);

            var isAvailable = CrfService.isElementAvailableToCurrentProfile(elementId);

            Object.defineProperty(itemMetadata, 'visible', {
                enumerable: true,
                get: function() {
                    return isAvailable && sectionFlags.visible;
                },
            });

            return true;
        }

        return {
            initFormFlags: initVisibleFlag,
            initSectionFlags: initVisibleFlag,
            initListFlags: initVisibleFlag,
        };
    }]);
