'use strict';

angular.module('app.entity.certcopy').controller('RecordCertcopyController', [
    '$exceptionHandler',
    '$rootScope',
    'RecordService',
    'AccountManager',
    'Event',
    'ShareCrfSDK',
    'ShareCrfApiErrorCodes',
    function(
        $exceptionHandler,
        $rootScope,
        RecordService,
        AccountManager,
        Event,
        ShareCrfSDK,
        ShareCrfApiErrorCodes
    ) {
        var vm = this;

        var recordId = RecordService.getId();

        vm.error = null;
        // Lista de copias certificadas
        vm.recordCertcopies = [];

        // Configuración de la tabla
        vm.table = {
            orderBy: 'createdAt',
            orderReverse: true,
            limit: 25,
            currentPage: 0,
            count: 0,
            loading: false,
        };

        // Permisos generales, a.k.a routes
        vm.permissions = {
            generate: AccountManager.hasPermission('generate record certcopy'),
            listAll: AccountManager.hasPermission('read records all certcopies'),
            listMyProfile: AccountManager.hasPermission('read records my profile certcopies'),
        };

        vm.ready = false;
        vm.$onInit = function() {
            vm.ready = true;
        };

        // Generación PDF en tiempo real
        vm.generatingCertcopy = false;
        vm.generatedCertcopyUrl = null;
        vm.generateCertcopy = function() {
            vm.generatingCertcopy = true;
            vm.generatedPdfUrl = null;
            vm.error = null;

            return Event.broadcast('generateRecordCertcopyStart', [recordId])
                .then(function(event) {
                    if (event.defaultPrevented) {
                        return;
                    }

                    return ShareCrfSDK.ecrd.generateRecordCertcopy(recordId)
                        .then(function(url) {
                            vm.generatedCertcopyUrl = url;
                            $rootScope.$broadcast('RecordCertcopyGenerate');
                        })
                        .catch(function(error) {
                            vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);

                            throw error;
                        });
                })
                .then(function() {
                    vm.generatingCertcopy = false;
                    vm.loadRecordCertcopies();
                })
                .catch(function(error) {
                    vm.generatingCertcopy = false;

                    return $exceptionHandler(error, 'generate record certified copy');
                });
        };

        vm.loadRecordCertcopies = function() {
            vm.table.loading = true;

            var params = {
                order: 'createdAt ' + (vm.table.orderReverse ? 'desc' : 'asc'),
                limit: vm.table.limit,
                offset: vm.table.limit * vm.table.currentPage, // vm.currentPage is zero-based
            };

            return ShareCrfSDK.ecrd.getRecordCertcopyList(recordId, params)
                .then(function(result) {
                    vm.recordCertcopies = result.data;
                    vm.table.count = result.total;
                })
                .finally(function() {
                    vm.table.loading = false;
                });
        };

        vm.downloadUrl = function(id) {
            var params = {
                recordId: recordId,
                recordCertcopyId: id,
                relative: true,
            };

            return ShareCrfSDK.ecrd.getRecordCertcopyDownloadUrl(params);
        };
    },
]);
