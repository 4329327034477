'use strict';

angular.module('export')
    .controller('ExportFormController', [
        '$log',
        '$filter',
        'Event',
        'UserSites',
        'CrdConfigurationService',
        'ErrorMessages',
        'gettextCatalog',
        'AccountManager',
        'ExportService',
        'StudyInfo',
        function($log, $filter, Event, UserSites, CrdConfigurationService, ErrorMessages, gettextCatalog, AccountManager, ExportService, StudyInfo) {
            var vm = this;

            vm.ready = false;
            vm.error = null;
            vm.message = false;
            vm.generating = false; // indica si hay algún volcado generándose
            vm.availableSites = [];
            vm.disableDateFormat = false;
            vm.showStoredToggle = false; // si es muestra el selector para incluir pacientes archivados

            vm.exportCompatibility = false; // si se muestra el selector de compatibilidad con exportación

            vm.configuration = CrdConfigurationService;

            var defaultDateFormat = CrdConfigurationService.getDateFormat();
            if (defaultDateFormat.indexOf('MMM') > -1) {
                // Si tiene el mes en formato locale, se indica por defecto la versión en el idioma configurado
                defaultDateFormat += ' - ' + CrdConfigurationService.getLanguage();
            }

            var now = new Date();
            var testDateString = now.toISOString();

            vm.dateFormatOptions = [
                dateFormatOption('DD-MM-YYYY'),
                dateFormatOption('DD/MM/YYYY'),
                dateFormatOption('MM-DD-YYYY'),
                dateFormatOption('MM/DD/YYYY'),
                dateFormatOption('DD-MMM-YYYY', 'en'),
                dateFormatOption('DD-MMM-YYYY', 'es'),
                dateFormatOption('DD-MMM-YYYY', 'de'),
                dateFormatOption('DD-MMM-YYYY', 'pt'),
                dateFormatOption('MMM-DD-YYYY', 'en'),
                dateFormatOption('MMM-DD-YYYY', 'es'),
                dateFormatOption('MMM-DD-YYYY', 'de'),
                dateFormatOption('MMM-DD-YYYY', 'pt'),
                dateFormatOption('YYYY-MM-DD'),
                dateFormatOption('YYYY/MM/DD'),
            ];

            function dateFormatOption(format, locale) {
                var value = format;
                var identifier = format;

                if (locale) {
                    value += ' - ' + locale;

                    if (locale === 'en') {
                        identifier = gettextCatalog.getString('{{format}} (ENG)', { format: format }, 'Formato de exportación de fechas');
                    }
                    else if (locale === 'es') {
                        identifier = gettextCatalog.getString('{{format}} (ESP)', { format: format }, 'Formato de exportación de fechas');
                    }
                    else if (locale === 'de') {
                        identifier = gettextCatalog.getString('{{format}} (ALE)', { format: format }, 'Formato de exportación de fechas');
                    }
                    else if (locale === 'pt') {
                        identifier = gettextCatalog.getString('{{format}} (POR)', { format: format }, 'Formato de exportación de fechas');
                    }
                }

                var localeFormat = locale ? format.replace('MMM', '[' + translateMonth(now.getMonth(), locale) + ']') : format;
                var label = identifier + ' (' + $filter('dateFormat')(testDateString, localeFormat) + ')';

                return { value: value, label: label };
            }

            function translateMonth(index, language) {
                // En la aplicación solamente hay un locale en memoria, y para no tener que sobrecargar con librerías
                // adicionales se usa una traducción directa para el campo del mes, el único dependiente del idioma.
                return {
                    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    es: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
                    de: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                    pt: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
                }[language][index] || index;
            }

            vm.filters = {
                format: 'csv',
                delimiter: 'COMMA',
                quote: 'DQUOTE',
                mode: 'SEPARATED',

                valueLabels: false,
                fullHeaderLabel: false,
                multipleFields: 'SINGLE',

                statusMetadata: 'NO',
                medicalCoding: false,
                queries: false,
                auditlog: false,
                auditlogDeleted: false,
                stored: false,

                dateFormat: defaultDateFormat,

                sites: [],
            };

            vm.profileScope = null;

            vm.$onInit = function() {
                UserSites.get()
                    .then(function(sites) {
                        vm.availableSites = sites;
                        vm.ready = true;

                        return AccountManager.init()
                            .then(function() {
                                var user = AccountManager.getLoggedUser();
                                vm.profileScope = user.currentProfile.scope;
                                vm.showStoredToggle = AccountManager.hasPermission('read stored records');
                            });
                    })
                    .catch(function(error) {
                        $log.error(error, 'loading sites to build filter in section "export"');
                        vm.error = error;
                    });

                vm.exportCompatibility = StudyInfo.getInfo().exportCompatibility;
            };

            Event.on('exportError', function(error) {
                vm.error = gettextCatalog.getString('Ocurrió un error al generar el volcado de datos. Por favor, inténtelo más tarde.');
                vm.generating = false;
                $log.error(error, 'create export');
            });
            Event.on('exportFinished', function() {
                vm.generating = false;
            });

            vm.exportData = function() {
                if (!vm.ready) return;

                vm.error = null;
                vm.message = null;
                vm.generating = true;

                var payload = angular.copy(vm.filters);
                if (vm.filters.dateFormat.indexOf('MMM') > -1) {
                    var dateTimeReResult = /^(.+) - (es|en|de|pt)$/.exec(vm.filters.dateFormat);
                    if (Array.isArray(dateTimeReResult)) {
                        payload.dateFormat = dateTimeReResult[1];
                        payload.dateLocale = dateTimeReResult[2];
                    }
                }

                return ExportService.create(payload)
                    .then(function(dump) { // eslint-disable-line no-unused-vars
                        vm.error = null;
                    })
                    .catch(function(error) {
                        $log.error(error, 'create export');
                        vm.error = ErrorMessages.get(error);
                    });
            };

            vm.onChangeFormat = function() {
                if (vm.filters.format !== 'csv') {
                    vm.filters.valueLabels = false;
                }

                vm.disableDateFormat = ['spss', 'sas'].indexOf(vm.filters.format) > -1;

                if (vm.filters.format === 'spss') {
                    vm.filters.dateFormat = 'YYYY/MM/DD';
                }
                else if (vm.filters.format === 'sas') {
                    vm.filters.dateFormat = 'YYYY-MM-DD';
                }
            };
        },
    ]);
