'use strict';

angular.module('table-settings')
    .controller('TableItemsSelectorController', ['TableItems', function(TableItems) {
        var vm = this;

        vm.$onInit = function() {
            vm.limitOptions = TableItems.getDefaultOptions();
        };

        vm.setDefaultOption = function() {
            TableItems.setSelectedOption(vm.id, vm.limit);
        };
    }]);
