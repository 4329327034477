'use strict';

angular.module('ecrd.sites')
    .factory('SitesService', ['$q', '$log', 'ShareCrfSDK', function($q, $log, ShareCrfSDK) {
        var _sitesCache = null;

        return {
            list: function(reset) {
                var defer = $q.defer();

                if (!_sitesCache || reset) {
                    ShareCrfSDK.users.getAvailableSites()
                        .then(function(sites) {
                            _sitesCache = sites;
                            defer.resolve(_sitesCache);
                        })
                        .catch(function(error) {
                            $log.error(error, 'error getting user available sites');
                            _sitesCache = null;
                            defer.resolve([]);
                        });
                }
                else {
                    defer.resolve(_sitesCache);
                }

                return defer.promise;
            },
        };
    }]);
