// eslint-disable-next-line angular/component-limit
angular.module('security-redirect-autoreload-session', []).factory('SecurityRedirectReloadSessionInterceptor', [
    '$injector',
    '$exceptionHandler',
    function($injector, $exceptionHandler) {
        function isSuccessfulShareCrfApiCall(response) {
            var config = response.config || {};
            var excludedRoutes = [
                {
                    api: 'portal',
                    method: 'post',
                    uri: '/api/user/session',
                },
                {
                    api: 'portal',
                    method: 'post',
                    uri: '/api/user/logout',
                },
            ];

            return !!config.shareCrfApi && excludedRoutes.every(function(route) {
                return route.api !== config.shareCrfApi
                    || route.method !== config.method.toLowerCase()
                    || config.url.substring(config.url.length - route.uri.length) !== route.uri;
            });
        }

        // Only one execution at a time
        var running = false;

        return {
            response: function(response) {
                if (!running && isSuccessfulShareCrfApiCall(response)) {
                    // Service "SecurityRedirect" must be loaded using "$injector" to avoid cyclic dependencies error
                    var SecurityRedirect = $injector.get('SecurityRedirect');

                    if (SecurityRedirect.isInAutoReloadSessionTime()) {
                        // Run
                        running = true;

                        // Do not return this promise
                        SecurityRedirect.reloadSession()
                            .catch($exceptionHandler)
                            .finally(function() {
                                running = false;
                            });
                    }
                }

                return response;
            },
        };
    }])
    // Auto reload session
    .config(['$httpProvider', function($httpProvider) {
        $httpProvider.interceptors.push('SecurityRedirectReloadSessionInterceptor');
    }]);
