angular.module('app.entity.menu.plugin-validation', [
    'app.entity.service',
]).factory('EntityMenuPluginValidation', [
    'CrfService',
    'RecordValidationService',
    'FormFieldsService',
    'RecordDataService',
    function(
        CrfService,
        RecordValidationService,
        FormFieldsService,
        RecordDataService
    ) {
        function validateVisibleFields(elementId, listIndices) {
            var visibleFields = FormFieldsService.getVisibleFields(elementId, listIndices);

            return !visibleFields.length || visibleFields.every(function(fieldUid) {
                var valid = RecordValidationService.isElementValid(fieldUid);
                // Los campos de un elemento de lista no se validan al crearlo, y su validación es "undefined". Se toman
                // como válidos.
                // @see sharecrf-core/packages/Core2/src/Record/Validation.js@_getValidationStateBoilerplate
                if (typeof valid === 'undefined') {
                    valid = true;
                }

                return valid;
            });
        }
        function setFormValidation(metadataItem, elementId, listIndices) {
            var previousValidation = metadataItem.validity ? metadataItem.validity.__valid : true;
            var newValidation = validateVisibleFields(elementId, listIndices);

            var changed = previousValidation !== newValidation;

            if (!metadataItem.validity) {
                metadataItem.validity = {};
            }
            metadataItem.validity.__valid = newValidation;

            return changed;
        }

        function update(itemMetadata) {
            if (!('validity' in itemMetadata)) {
                itemMetadata.validity = {};
            }

            var currentValue = itemMetadata.validity.__valid;
            var newValue = itemMetadata.children.every(function(child) {
                return angular.isUndefined(child.validity) || !!child.validity.__valid;
            });

            itemMetadata.validity.__valid = newValue;

            return currentValue !== newValue;
        }

        function getListItemValidation(list, listIndices, itemId) {
            listIndices = listIndices || {};
            var listId = list.getId();
            if (angular.isUndefined(listIndices[listId])) {
                listIndices[listId] = RecordDataService.getListItemIndex(listId, itemId, listIndices);
            }

            return {
                __valid: list.getDirectForms().every(function(form) {
                    return validateVisibleFields(form.getId(), listIndices);
                }),
            };
        }

        function getListValidation(list, listIndices) {
            var listId = list.getId();
            var listData = RecordDataService.getList(listId, listIndices);
            if (!angular.isArray(listData) || listData.length === 0) {
                return {
                    __valid: true,
                };
            }

            listIndices = listIndices || {};

            return {
                __valid: listData.every(function(itemData, index) {
                    var itemIndices = angular.copy(listIndices);
                    itemIndices[listId] = index + 1;

                    return getListItemValidation(list, itemIndices).__valid;
                }),
            };
        }

        function getSectionValidation(section, listIndices) {
            return {
                __valid: section.getChildren().every(function(child) {
                    if (child.isForm()) {
                        return validateVisibleFields(child.getId(), listIndices);
                    }

                    if (child.isSection()) {
                        return getSectionValidation(child, listIndices).__valid;
                    }

                    return getListValidation(child, listIndices).__valid;
                }),
            };
        }

        return {
            initFormFlags: function(itemMetadata, elementId, entityData, stateParams) {
                var listIndices = CrfService.getListIndices(stateParams);

                setFormValidation(itemMetadata, elementId, listIndices);

                return true;
            },
            initSectionFlags: function(itemMetadata, elementId, entityData, stateParams) {
                var section = 'element' in itemMetadata ? itemMetadata.element : CrfService.getSection(elementId);
                var listIndices = CrfService.getListIndices(stateParams);

                itemMetadata.validity = getSectionValidation(section, listIndices);
            },
            initListFlags: function(itemMetadata, elementId, entityData, stateParams) {
                var list = 'element' in itemMetadata ? itemMetadata.element : CrfService.getList(elementId);
                var listIndices = CrfService.getListIndices(stateParams);

                itemMetadata.validity = getListValidation(list, listIndices);
            },
            initListItemFlags: function(menuItem, elementId, stateParams) {
                var list = 'element' in menuItem ? menuItem.element : CrfService.getList(elementId);
                var listIndices = CrfService.getListIndices(stateParams);

                menuItem.validity = getListItemValidation(list, listIndices, menuItem.id);
            },
            reloadFlags: function(itemMetadata, elementId, elementType, sectionState, stateParams) {
                var listIndices = CrfService.getListIndices(stateParams);

                if (itemMetadata.type === 'form') {
                    itemMetadata.validity.__valid = validateVisibleFields(elementId, listIndices);
                } else if (itemMetadata.type === 'section') {
                    itemMetadata.validity = getSectionValidation(itemMetadata.element, listIndices);
                } else if (itemMetadata.type === 'list') {
                    itemMetadata.validity = getListValidation(itemMetadata.element, listIndices);
                } else if (itemMetadata.type === 'listItem') {
                    itemMetadata.validity = getListItemValidation(itemMetadata.element, listIndices, itemMetadata.id);
                }
            },
            onElementFlagChangedVisibility: function(argumentsList) {
                var itemMetadata = argumentsList[0];
                var elementId = argumentsList[1];
                var stateParams = argumentsList[2];
                var listIndices = CrfService.getListIndices(stateParams);

                return setFormValidation(itemMetadata, elementId, listIndices);
            },
            onFormValidated: function(argumentsList) {
                var itemMetadata = argumentsList[0];
                var elementId = argumentsList[1];
                var stateParams = argumentsList[2];
                var listIndices = CrfService.getListIndices(stateParams);

                return setFormValidation(itemMetadata, elementId, listIndices);
            },

            propagate: function(itemMetadata) {
                return update(itemMetadata);
            },
        };
    }]);
