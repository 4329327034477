angular.module('study.dashboard').controller('DashboardController', [
    'AccountManager',
    'CrdConfigurationService',
    'gettextCatalog',
    function(AccountManager, CrdConfigurationService, gettextCatalog) {
        var vm = this;

        vm.permissions = {};
        vm.showEmptyMessage = false;
        vm.atLeatsOneReportIsAccessible = false;

        vm.entityLabel = CrdConfigurationService.getLabelPlural();

        vm.approvalRevisionTitle = '';
        if (CrdConfigurationService.hasStates()) {
            if (CrdConfigurationService.hasRevisionStates()) {
                vm.approvalRevisionTitle = gettextCatalog.getString('Estados de aprobación y revisión');
            }
            else {
                vm.approvalRevisionTitle = gettextCatalog.getString('Estados de aprobación');
            }
        }
        else if (CrdConfigurationService.hasRevisionStates()) {
            vm.approvalRevisionTitle = gettextCatalog.getString('Estados de revisión');
        }

        AccountManager.init().then(function () {
            vm.permissions.inclusionRate = AccountManager.hasPermission('read inclusion rate report');
            vm.permissions.queriesStatus = AccountManager.hasPermission('read queries status report');
            vm.permissions.queriesResolutionTime = AccountManager.hasPermission('read queries resolution time report');
            vm.permissions.queriesDistribution = AccountManager.hasPermission('read queries distribution report');
            vm.permissions.recordStatus = AccountManager.hasPermission('read records status report');
            vm.permissions.missingData = AccountManager.hasPermission('read missing data report');
            vm.permissions.approvalRevision = AccountManager.hasPermission('read approval revision report');

            vm.atLeatsOneReportIsAccessible = Object.keys(vm.permissions).some(function(key) {
                return vm.permissions[key];
            });

            vm.showEmptyMessage = !vm.atLeatsOneReportIsAccessible;
        });
    },
]);
