angular.module('user')
    .controller('LogoutModalController', ['$scope', function($scope) {
        var vm = this;

        vm.confirmAction = function(){
            $scope.closeThisDialog();
        };
        vm.cancelAction = function(){
            $scope.dismissThisDialog();
        };
    }]);
