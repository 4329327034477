angular.module('study.menu').controller('StudyMainMenuController', [
    '$timeout',
    'AccountManager',
    'Multiprofile',
    'Event',
    '$window',
    function($timeout, AccountManager, Multiprofile, Event, $window) {
        var vm = this;

        vm.ready = false;

        // Permission management

        vm.hasPermission = AccountManager.hasPermission;
        vm.hasAtLeastOnePermission = AccountManager.hasAtLeastOnePermission;

        vm.hasAccessToEnvironment = function() {
            var loggedUser = AccountManager.getLoggedUser();
            var selectedProfile = Multiprofile.getProfile();

            return selectedProfile && Array.isArray(loggedUser.profiles) && !!loggedUser.profiles.find(function(profile) {
                return profile.id === selectedProfile.id;
            });
        };

        // Floating menu

        vm.isVisible = false;
        vm.toggle = function() {
            vm.isVisible = !vm.isVisible;

            if (vm.isVisible) {
                bindOnClickToClose();
            } else {
                unbindOnClickToClose();
            }
        };

        // Help menu

        vm.isHelpVisible = false;
        vm.toggleHelp = function() {
            vm.isHelpVisible = !vm.isHelpVisible;
        };

        vm.guidesLinks = {
            en: 'https://help.sharecrf.com/ecrf-user-guide',
        };

        // User info

        vm.infoVisible = false;
        vm.selectedProfile = null;
        vm.toggleUserInfo = function() {
            vm.infoVisible = !vm.infoVisible;

            if (vm.infoVisible) {
                bindOnClickToClose();
            } else {
                unbindOnClickToClose();
            }
        };
        vm.closeFloatingMenu = function() {
            vm.infoVisible = false;
            vm.isVisible = false;

            unbindOnClickToClose();
        };

        // Multiprofile

        vm.selectedProfile = {};
        vm.selectProfile = function() {
            Multiprofile.setProfile(vm.selectedProfile.id);

            var path = $window.location.pathname || '';
            var environment = path.slice(1).split('/')[0];

            location.href = '/' + environment;
        };

        vm.hasAccessToStudy = function(profile) {
            var hasAccess = false;

            for (var index in profile.permissions) {
                var permission = profile.permissions[index];
                if (permission.indexOf('access study') === 0 || permission === 'access randomization' || permission === 'access dashboard') {
                    hasAccess = true;
                    break;
                }
            }

            return hasAccess;
        };

        // Body on-click binding

        vm.binded = false;
        function onclickListener(event) {
            var userInfoElement = angular.element(document.getElementById('user-info'));
            var clickedElement = angular.element(event.target);
            var menuTriggerElement = angular.element(document.getElementById('trigger-main-menu'));

            if (userInfoElement && userInfoElement[0]) {
                if (!isDescendant(userInfoElement[0], clickedElement[0]) && (!menuTriggerElement[0] || menuTriggerElement[0] !== clickedElement[0])) {
                    vm.closeFloatingMenu();
                }
            }
        }
        function isDescendant(parent, child) {
            var node = child.parentNode;
            while (node != null) {
                if (node == parent) {
                    return true;
                }
                node = node.parentNode;
            }

            return false;
        }
        function bindOnClickToClose() {
            $timeout(function() {
                if (!vm.binded) {
                    document.body.addEventListener('click', onclickListener);
                    vm.binded = true;
                }
            });
        }
        function unbindOnClickToClose() {
            $timeout(function() {
                if (vm.binded) {
                    document.body.removeEventListener('click', onclickListener, false);
                    vm.binded = false;
                }
            });
        }

        // Template data

        function reloadUserData(ctrl) {
            ctrl.loggedUser = AccountManager.getLoggedUser();
            ctrl.selectedProfile = Multiprofile.getProfile();
            ctrl.ready = true;

            // Nombre del usuario
            ctrl.userName = ctrl.loggedUser && ctrl.loggedUser.id
                ? ctrl.loggedUser.name + ' ' + ctrl.loggedUser.lastname
                : '';

            // Perfil actual
            ctrl.userProfile = ctrl.selectedProfile && ctrl.loggedUser && ctrl.loggedUser.profiles
                ? (ctrl.loggedUser.profiles.find(function(profile) {
                    return profile.id === ctrl.selectedProfile.id;
                }) || {}).name
                : '';
        }

        // Init

        AccountManager.init()
            .then(function() {
                reloadUserData(vm);
            });

        Event.on('login', function() {
            reloadUserData(vm);
        });
        Event.on('logout', function() {
            reloadUserData(vm);
        });
    },
]);
