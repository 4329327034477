/* eslint-disable angular/di-unused */
angular.module('app.entity.menu.plugin-completion', [
    'app.entity.service',
]).factory('EntityMenuPluginCompletion', [
    'CrfService',
    'FieldsCompletionService',
    'RecordDataService',
    function(
        CrfService,
        FieldsCompletionService,
        RecordDataService
    ) {
        function hasChanged(previousValue, newValue) {
            previousValue = previousValue || {};
            newValue = newValue || {};

            return ['completed', 'pending', 'required'].some(function(key) {
                return previousValue[key] !== newValue[key];
            });
        }
        function initFormCompletion(itemMetadata, listIndices) {
            itemMetadata.completion = FieldsCompletionService.getFormCompletion(itemMetadata.element, listIndices);

            return true;
        }

        function reloadCompletion(itemMetadata, listIndices) {
            var previousValue = itemMetadata.completion;
            var newValue = itemMetadata.type === 'listItem'
                ? FieldsCompletionService.reloadListItem(itemMetadata.element, listIndices, itemMetadata.id)
                : FieldsCompletionService.reload(itemMetadata.element, listIndices);

            var changed = hasChanged(previousValue, newValue);

            itemMetadata.completion = newValue;

            return changed;
        }

        function update(itemMetadata) {
            var currentValue = itemMetadata.completion;
            var newValue = itemMetadata.children.reduce(function(acc, child) {
                var childCompletion = child.completion || {};

                return {
                    completed: acc.completed + (childCompletion.completed || 0),
                    pending: acc.pending + (childCompletion.pending || 0),
                    required: acc.required + (childCompletion.required || 0),
                };
            }, {
                completed: 0,
                pending: 0,
                required: 0,
            });

            itemMetadata.completion = newValue;

            return hasChanged(currentValue, newValue);
        }

        return {
            initFormFlags: function(itemMetadata, elementId, entityData, stateParams) {
                var listIndices = CrfService.getListIndices(stateParams);

                return initFormCompletion(itemMetadata, listIndices);
            },
            initSectionFlags: function(itemMetadata, elementId, entityData, stateParams) {
                if (angular.isUndefined(itemMetadata)) {
                    itemMetadata = {};
                }

                var listIndices = CrfService.getListIndices(stateParams);

                itemMetadata.completion = FieldsCompletionService.getSectionCompletion(
                    itemMetadata.element,
                    listIndices
                );
            },
            initListFlags: function(itemMetadata, elementId, entityData, stateParams) {
                if (angular.isUndefined(itemMetadata)) {
                    itemMetadata = {};
                }

                var listIndices = CrfService.getListIndices(stateParams);

                itemMetadata.completion = FieldsCompletionService.getListCompletion(itemMetadata.element, listIndices);
            },
            initListItemFlags: function(itemMetadata, elementId, stateParams) {
                if (angular.isUndefined(itemMetadata)) {
                    itemMetadata = {};
                }

                var listIndices = CrfService.getListIndices(stateParams);
                if (angular.isUndefined(listIndices[elementId])) {
                    var itemId = itemMetadata.id;
                    listIndices[elementId] = RecordDataService.getListItemIndex(elementId, itemId, listIndices);
                }

                itemMetadata.completion = FieldsCompletionService.getListItemCompletion(
                    itemMetadata.element,
                    listIndices,
                    itemMetadata.id
                );
            },

            // Actualización de un flag de un campo (required o completed)
            onElementFlagChangedCompletion: function(argumentsList) {
                var itemMetadata = argumentsList[0];
                // var elementId = argumentsList[1];
                // var stateParams = argumentsList[2];
                var listIndices = argumentsList[3].listIndices;

                return reloadCompletion(itemMetadata, listIndices);
            },

            // Eliminar un elemento de lista
            onListItemCreated: function(argumentsList) {
                var itemMetadata = argumentsList[0];
                // var elementId = argumentsList[1];
                var stateParams = argumentsList[2];
                var listIndices = CrfService.getListIndices(stateParams);

                return reloadCompletion(itemMetadata, listIndices);
            },
            // Eliminar un elemento de lista
            onListItemDeleted: function(argumentsList) {
                var itemMetadata = argumentsList[0];
                // var elementId = argumentsList[1];
                var stateParams = argumentsList[2];

                var listIndices = CrfService.getListIndices(stateParams);

                return reloadCompletion(itemMetadata, listIndices);
            },

            // Propagación en el padre
            propagate: function(itemMetadata) {
                return update(itemMetadata);
            },
        };
    },
]);
