angular.module('scrf.notifications').filter('notificationStatus', ['gettextCatalog', function(gettextCatalog) {
    return function(statusCode) {
        if (statusCode === 'pending') {
            return gettextCatalog.getString('Pendiente');
        }

        if (statusCode === 'sending') {
            return gettextCatalog.getString('Enviando');
        }

        if (statusCode === 'dryrun') {
            return gettextCatalog.getString('No enviado');
        }

        if (statusCode === 'sent') {
            return gettextCatalog.getString('Enviado');
        }

        if (statusCode === 'error') {
            return gettextCatalog.getString('Error');
        }

        return statusCode;
    };
}]);
