'use strict';

angular.module('ecrd').controller('RecordVersionMismatchController', [
    '$scope',
    'RecordDataService',
    'CrfService',
    'gettextCatalog',
    function($scope, RecordDataService, CrfService, gettextCatalog) {
        var vm = this;

        vm.changes = [];
        if (angular.isArray($scope.ngDialogData.changes)) {
            vm.changes = $scope.ngDialogData.changes.map(function(change) {
                var changeData = {};

                changeData.element = RecordDataService.getElementFullLabeledPathByUID(change.uid);
                if (angular.isArray(changeData.element)) {
                    changeData.element = changeData.element.join(' → ');
                }

                // Cambio en los datos
                if (change.action == 'modify-data' || change.action == 'add-data') {
                    changeData.action = gettextCatalog.getString('Dato modificado');
                    var field = CrfService.getElementByUID(change.uid);
                    changeData.field = field;

                    if (field !== null && field.isField()) {
                        // Se formatea directamente en la plantilla
                        if (field.getFormControl() === 'files') {
                            changeData.before = change.before;
                            changeData.after = change.after;
                        }
                        // Se muestra el valor del campo formateado desde el core
                        else {
                            changeData.before = field.getReadableValue(change.before);
                            changeData.after = field.getReadableValue(change.after);
                        }
                    }
                }
                // Nuevo elemento de lista
                else if (change.action == 'add-item') {
                    changeData.action = gettextCatalog.getString('Elemento de lista añadido');

                    var listId = RecordDataService.getElementId(change.uid);
                    var listIndices = RecordDataService.getListIndicesByUID(change.uid);
                    // change.after lleva el __id del ítem añadido
                    var listInternalIndex = change.after;
                    var position = RecordDataService.getListItemIndex(listId, listInternalIndex, listIndices);
                    changeData.after = position;
                }
                // Elemento de lista eliminado
                else if (change.action == 'delete-item') {
                    changeData.action = gettextCatalog.getString('Elemento de lista eliminado');
                }

                return changeData;
            });
        }
    },
]);
