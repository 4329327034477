angular.module('app.entity.certcopy')
    .filter('certcopyType', ['gettextCatalog', 'CrdConfigurationService', function(gettextCatalog, CrdConfigurationService) {
        return function(item) {
            if (item.trigger === 'manual') {
                return gettextCatalog.getString('Manual');
            } else if (item.trigger === 'automatic') {
                var state = CrdConfigurationService.getStates().getState(item.state);
                if (state) {
                    return state.getName();
                }
            }
        };
    }]);
