'use strict';

angular.module('user')
    .controller('UserLoginController', ['$q', 'AccountManager', 'ShareCrfUrl', 'ShareCrfApiErrorCodes', function($q, AccountManager, ShareCrfUrl, ShareCrfApiErrorCodes) {
        var vm = this;

        vm.$onInit = function() {
            vm.data = {};

            vm.running = false;
            vm.submitted = false;

            vm.urlPortal = ShareCrfUrl.getPortal();

            vm.submit = function(form) {
                vm.submitted = true;
                vm.message = null;
                vm.warning = null;
                vm.error = null;

                var promise = $q.resolve();

                if (form.$valid) {
                    vm.running = true;

                    promise = AccountManager.login(vm.data.email, vm.data.password)
                        .then(function() {
                            vm.running = false;

                            vm.onLogin()
                                .then(function() {
                                    vm.running = false;
                                })
                                .catch(function() {
                                    vm.running = false;
                                });
                        })
                        .catch(function(error) {
                            vm.running = false;
                            vm.error = ShareCrfApiErrorCodes.getHumanReadableMessage(error);
                        });
                }

                return promise;
            };
        };
    }]);
