'use strict';

angular.module('ecrd.core')
    .provider('CoreRecordRules', function() {
        var provider = this;

        provider.definitions = [];
        provider.indexedRules = {};

        provider.configRuleExportedDefinitions = function(exportedRulesDefinitions) {
            provider.definitions = exportedRulesDefinitions;
        };

        provider.setIndexedRules = function(filterName, indexedByFilter) {
            provider.indexedRules[filterName] = indexedByFilter;
        };

        provider.$get = ['$window', 'CoreRecord', function($window, CoreRecord) {
            var rules = CoreRecord.getRules();
            rules.setRules(provider.definitions);

            $window._.each(provider.indexedRules, function(ruleIds, filterName) {
                rules.setIndexedRules(filterName, ruleIds);
            });

            return rules;
        }];
    });
