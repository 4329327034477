angular.module('security-redirect').controller('ExpirationMessageController', [
    '$scope',
    '$interval',
    'SecurityRedirect',
    function($scope, $interval, SecurityRedirect) {
        var vm = this;

        vm.remaining = Math.max(0, $scope.ngDialogData.remaining);
        vm.interval;

        vm.dismiss = function() {
            if (vm.interval) {
                $interval.cancel(vm.interval);
            }

            $scope.dismissThisDialog();
        };

        vm.close = function(auto) {
            if (vm.interval) {
                $interval.cancel(vm.interval);
            }

            var mustRenewSession = !auto;
            $scope.closeThisDialog(mustRenewSession);
        };

        if (!vm.remaining) {
            vm.dismiss();
        }
        else {
            vm.interval = $interval(function() {
                vm.remaining -= 1;

                SecurityRedirect.loadSessionSettings();

                // Si se ha renovado la sesión en otra pestaña, forzar el cierre del modal sin hacer la renovación desde
                // aquí
                if (vm.remaining < SecurityRedirect.getRemainingTime('s') - 1) {
                    vm.close(true);
                }
                // Si el contador llega a 0, hacer logout y redirigir
                else if (vm.remaining <= 0) {
                    vm.dismiss();
                }
            }, 1000);
        }
    },
]);
